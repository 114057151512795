<template>
  <div class="new-or-continue">
    <section class="main row">
      <div class="left-section col-6 col-12-medium">
        <!-- Form for creating a new URL Application -->
        <form
          id="new-loan-form"
          v-on:submit.prevent="startNewApplication"
          method="post"
        >
          <!-- Moon Loader -->
          <moon-loader
            v-bind:loading="isLoading"
            :color="loaderColor"
            :size="loaderSize"
            v-bind:loaderMessage="loaderMessage"
          ></moon-loader>

          <h1>Let's get started.</h1>
          <!-- Required fields instruction -->
          <p class="instruction">
            Required fields are followed by
            <strong> <abbr class="required" title="required">*</abbr> </strong>.
          </p>

          <br />

          <!-- First name -->
          <p>
            <label for="first-name">
              <span>First Name:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <input
              id="first-name"
              type="text"
              placeholder="First Name"
              v-model="newApplication.firstName"
              v-on:input="validateNewFirstName"
              required
            />
            <!-- Icon will be inserted below after validating the above input -->
            <validation-icon
              v-bind:isValid="validations.newApplication.firstName"
            ></validation-icon>
          </p>

          <!-- Last name -->
          <p>
            <label for="last-name">
              <span>Last Name:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <input
              id="last-name"
              type="text"
              placeholder="Last Name"
              v-model="newApplication.lastName"
              v-on:input="validateNewLastName"
              required
            />
            <!-- An icon will be inserted below after validating the above input -->
            <validation-icon
              v-bind:isValid="validations.newApplication.lastName"
            ></validation-icon>
          </p>

          <!-- email address -->
          <p>
            <label for="primary-email">
              <span>Email Address:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <input
              id="primary-email"
              type="email"
              placeholder="dan@gmail.com"
              v-model="newApplication.primaryEmail"
              v-on:change="validateNewEmail"
              required
            />
            <!-- An icon will be inserted below after validating the above input -->
            <validation-icon
              v-bind:isValid="validations.newApplication.primaryEmail"
            ></validation-icon>
          </p>

          <!-- Date of Birth -->
          <p>
            <label for="date-of-birth">
              <span>Date of Birth:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <date-pick
              id="date-of-birth"
              class="date-pick"
              v-mask="'##/##/####'"
              v-bind:inputAttributes="{
                placeholder: 'MM/DD/YYYY',
                required: 'required',
              }"
              v-bind:isDateDisabled="isFutureDate"
              v-bind:displayFormat="'MM/DD/YYYY'"
              v-model="newApplication.dateOfBirth"
              v-on:input="validateNewDateOfBirth"
            ></date-pick>
            <!-- An icon will be inserted below after the validating above input -->
            <validation-icon
              v-bind:isValid="validations.newApplication.dateOfBirth"
            ></validation-icon>
          </p>

          <!-- last four digits of Social Security Number -->
          <p>
            <label class="ssn-label" for="social-security-number">
              <span>Social Security Number:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
              <br />
              <span class="ssn-description">(last 4 digits)</span>
            </label>
            <input
              id="social-security-number"
              type="text"
              maxlength="4"
              minlength="4"
              placeholder="9999"
              v-model="newApplication.lastFourDigitsOfSsn"
              v-on:change="validateNewLastFourDigitsOfSsn"
              required
            />
            <!-- An icon will be inserted below after the validating above input -->
            <validation-icon
              v-bind:isValid="validations.newApplication.lastFourDigitsOfSsn"
            ></validation-icon>
          </p>

          <!-- Phone number -->
          <p>
            <label for="phone-number">
              <span>Phone Number:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <input
              id="phone-number"
              type="tel"
              maxlength="15"
              minlength="15"
              v-mask="'+1(###)###-####'"
              placeholder="+1(123)456-7890"
              v-model="phoneNumberModel"
              v-on:change="validateContinuePhoneNumber"
              required
            />
            <!-- An icon will be inserted below after the validating above input -->
            <validation-icon
              v-bind:isValid="validations.continueApplication.phoneNumber"
            ></validation-icon>
          </p>

          <p>
            <!-- same as above check box for WhatsApp Number-->
            <span class="same-as-above">
              <label></label>
              <input type="checkbox" id="same-as-above" v-model="sameAsAbove" />
              <label for="same-as-above">Same as above</label>
            </span>
            <!-- WhatsApp number -->
            <label for="whatsapp-number">
              <span>WhatsApp Number:</span>
            </label>
            <input
              id="whatsapp-number"
              type="tel"
              maxlength="15"
              minlength="15"
              v-mask="'+1(###)###-####'"
              placeholder="+1(713)123-4567"
              v-model="whatsAppNumberModel"
              v-on:change="validateWhatsAppNumber"
              v-bind:disabled="sameAsAbove ? 'disabled' : null"
            />
            <validation-icon
              v-bind:isValid="validations.newApplication.whatsAppNumber"
            ></validation-icon>
          </p>

          <span class="empty-label"></span>
          <button type="submit" class="primary">
            Start New
            <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
          </button>
        </form>
      </div>

      <div class="right-section col-6 col-12-medium">
        <!-- Form for continueing an old URL Application -->
        <form
          id="new-loan-form col-6 col-12-medium"
          v-on:submit.prevent="continuePreviousApplication"
          method="post"
        >
          <h1>Continue where you left.</h1>

          <transition name="shake">
            <p class="error" v-show="continueApplication.applicationNotFound">
              Application not found. Please check your details and try again.
            </p>
          </transition>

          <!-- Required fields instruction -->
          <p class="instruction">
            Required fields are followed by
            <strong> <abbr class="required" title="required">*</abbr> </strong>.
          </p>

          <br />

          <!-- Last name -->
          <p>
            <label for="last-name">
              <span>Last Name:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <input
              id="last-name"
              type="text"
              placeholder="Last Name"
              v-model="continueApplication.lastName"
              v-on:input="validateContinueLastName"
              required
            />
            <!-- An icon will be inserted below after validating the above input -->
            <validation-icon
              v-bind:isValid="validations.continueApplication.lastName"
            ></validation-icon>
          </p>

          <!-- email address -->
          <p>
            <label for="primary-email">
              <span>Email Address:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <input
              id="primary-email"
              type="email"
              placeholder="dan@gmail.com"
              v-model="continueApplication.primaryEmail"
              v-on:change="validateContinueEmail"
              required
            />
            <!-- An icon will be inserted below after validating the above input -->
            <validation-icon
              v-bind:isValid="validations.continueApplication.primaryEmail"
            ></validation-icon>
          </p>

          <!-- Phone number -->
          <p>
            <label for="phone-number">
              <span>Phone Number:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <input
              id="phone-number"
              type="tel"
              maxlength="15"
              minlength="15"
              v-mask="'+1(###)###-####'"
              placeholder="+1(123)456-7890"
              v-model="continueApplication.phoneNumber"
              v-on:change="validateNewPhoneNumber"
              required
            />
            <!-- An icon will be inserted below after the validating above input -->
            <validation-icon
              v-bind:isValid="validations.newApplication.phoneNumber"
            ></validation-icon>
          </p>

          <!-- Date of Birth -->
          <p>
            <label for="date-of-birth">
              <span>Date of Birth:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <date-pick
              id="date-of-birth"
              class="date-pick"
              v-mask="'##/##/####'"
              v-bind:inputAttributes="{
                placeholder: 'MM/DD/YYYY',
                required: 'required',
              }"
              v-bind:isDateDisabled="isFutureDate"
              v-bind:displayFormat="'MM/DD/YYYY'"
              v-model="continueApplication.dateOfBirth"
              v-on:input="validateContinueDateOfBirth"
            ></date-pick>
            <!-- An icon will be inserted below after the validating above input -->
            <validation-icon
              v-bind:isValid="validations.continueApplication.dateOfBirth"
            ></validation-icon>
          </p>

          <!-- last four digits of Social Security Number -->
          <p>
            <label class="ssn-label" for="social-security-number">
              <span>Social Security Number:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
              <br />
              <span class="ssn-description">(last 4 digits)</span>
            </label>
            <input
              id="social-security-number"
              type="text"
              maxlength="4"
              minlength="4"
              placeholder="9999"
              v-model="continueApplication.lastFourDigitsOfSsn"
              v-on:change="validateContinueLastFourDigitsOfSsn"
              required
            />
            <!-- An icon will be inserted below after the validating above input -->
            <validation-icon
              v-bind:isValid="
                validations.continueApplication.lastFourDigitsOfSsn
              "
            ></validation-icon>
          </p>

          <span class="empty-label"></span>
          <button type="submit" class="primary">
            Continue
            <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
          </button>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";
import MoonLoader from "@/components/elements/MoonLoader.vue";
import validationsMixin from "@/mixins/validationsMixin.js";
import inputMasksMixin from "@/mixins/inputMasksMixin.js";
import urlaMixin from "@/mixins/urlaMixin.js";

export default {
  // Component Name
  name: "NewOrContinue",
  // mixins used in this component
  mixins: [validationsMixin, urlaMixin, inputMasksMixin],
  // components used
  components: {
    "moon-loader": MoonLoader,
    "date-pick": DatePick,
  },
  // data used in component
  data() {
    return {
      minDateOfBirth: null,
      maxDateOfBirth: null,
      loaderSize: "100px",
      loaderColor: "#2ebfac",
      loaderMessage: "Loading",
      isLoading: false,
      newApplication: {
        // primary information required to create a customer profile
        firstName: "",
        lastName: "",
        primaryEmail: "",
        dateOfBirth: "",
        lastFourDigitsOfSsn: "",
        phoneNumber: "",
        whatsAppNumber: "",
        isNewLoanApplication: true,
        socialSecurityNumber: null,
      },
      continueApplication: {
        applicationNotFound: null,
        lastName: "",
        primaryEmail: "",
        phoneNumber: "",
        dateOfBirth: "",
        lastFourDigitsOfSsn: "",
        isNewLoanApplication: false,
      },
      validations: {
        newApplication: {
          firstName: null,
          lastName: null,
          primaryEmail: null,
          dateOfBirth: null,
          lastFourDigitsOfSsn: null,
          phoneNumber: null,
          whatsAppNumber: null,
        },
        continueApplication: {
          lastName: null,
          primaryEmail: null,
          phoneNumber: null,
          dateOfBirth: null,
          lastFourDigitsOfSsn: null,
        },
      },
      sameAsAbove: true,
      // each field should contain an array of errors
      errors: {
        newApplication: {
          firstName: [],
          lastName: [],
          primaryEmail: [],
          dateOfBirth: [],
          lastFourDigitsOfSsn: [],
          phoneNumber: [],
          whatsAppNumber: [],
        },
        continueApplication: {
          lastName: [],
          primaryEmail: [],
          dateOfBirth: [],
          lastFourDigitsOfSsn: [],
        },
      },
      hasErrors: {
        newApplication: false,
        continueApplication: false,
      },
    };
  },

  computed: {
    // getter and setter for phoneNumber value
    phoneNumberModel: {
      get() {
        // returns the value of newApplication.phoneNumber
        return this.newApplication.phoneNumber;
      },
      set(value) {
        // set the phoneNumber value
        this.newApplication.phoneNumber = value;
        // set the whatsAppNumber also, if "sameAsAbove" is checked
        if (this.sameAsAbove) this.newApplication.whatsAppNumber = value;
      },
    },

    // getter and setter for whatsAppNumber value
    whatsAppNumberModel: {
      get() {
        // returns the value of newApplication.whatsAppNumber
        return this.newApplication.whatsAppNumber;
      },
      set(value) {
        // set the whatsAppNumber value
        this.newApplication.whatsAppNumber = value;
        // set the whatsAppNumber also, if "sameAsAbove" is checked
        if (this.sameAsAbove) this.newApplication.phoneNumber = value;
      },
    },
  },

  methods: {
    init: function() {
      // set default values
      this.newApplication.isNewLoanApplication = true;
      this.continueApplication.isNewLoanApplication = false;
      // min and max for date of birth
      this.setDateOfBirthRange();
    },

    getFormattedDate: function(date) {
      // extract day, month and year from date
      const dd = String(date.getDate()).padStart(2, "0");
      const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = date.getFullYear();
      // concat string to yyyy-mm-dd format
      const formattedDate = yyyy + "-" + mm + "-" + dd;
      // return formatted date
      return formattedDate;
    },

    setDateOfBirthRange: function() {
      // min DoB
      var min = new Date();
      min.setFullYear(min.getFullYear() - 100);
      this.minDateOfBirth = min;

      // max DoB
      var max = new Date();
      max.setFullYear(max.getFullYear() - 18);
      this.maxDateOfBirth = max;
    },

    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },

    validateNewFirstName: function(event) {
      // validate first name from validationsMixin
      this.errors.newApplication.firstName = this.checkFirstName(event);

      // set validation-icon
      this.validations.newApplication.firstName =
        this.errors.newApplication.firstName.length === 0 ? 1 : 0;
    },

    validateNewLastName: function(event) {
      // validate last name from validationsMixin
      this.errors.newApplication.lastName = this.checkLastName(event);

      // set validation-icon
      this.validations.newApplication.lastName =
        this.errors.newApplication.lastName.length === 0 ? 1 : 0;
    },

    validateNewEmail: function(event) {
      // validate email from validationsMixin
      this.errors.newApplication.primaryEmail = this.checkEmail(event);

      // set validation-icon
      this.validations.newApplication.primaryEmail =
        this.errors.newApplication.primaryEmail.length === 0 ? 1 : 0;
    },

    validateNewDateOfBirth: function(inputValue) {
      // validate date from validationsMixin
      this.errors.newApplication.dateOfBirth = this.checkDateRangeFromValue(
        inputValue,
        this.getFormattedDate(this.minDateOfBirth),
        this.getFormattedDate(this.maxDateOfBirth)
      );

      // set validation-icon
      this.validations.newApplication.dateOfBirth =
        this.errors.newApplication.dateOfBirth.length === 0 ? 1 : 0;
    },

    validateNewLastFourDigitsOfSsn: function(event) {
      // validate email from validationsMixin
      this.errors.newApplication.lastFourDigitsOfSsn = this.checkLastFourDigitsOfSsn(
        event
      );

      // set validation-icon
      this.validations.newApplication.lastFourDigitsOfSsn =
        this.errors.newApplication.lastFourDigitsOfSsn.length === 0 ? 1 : 0;
    },

    validateNewPhoneNumber: function(event) {
      // validate phone number from validationsMixin
      this.errors.newApplication.phoneNumber = this.checkPhoneNumber(event);

      // set validation-icon
      this.validations.newApplication.phoneNumber =
        this.errors.newApplication.phoneNumber.length === 0 ? 1 : 0;
    },

    validateWhatsAppNumber: function(event) {
      // validate phone number from validationsMixin
      this.errors.newApplication.whatsAppNumber = this.checkPhoneNumber(event);

      // set validation-icon
      this.validations.newApplication.whatsAppNumber =
        this.errors.newApplication.whatsAppNumber.length === 0 ? 1 : 0;
    },

    validateContinueLastName: function(event) {
      // validate last name from validationsMixin
      this.errors.continueApplication.lastName = this.checkLastName(event);

      // set validation-icon
      this.validations.continueApplication.lastName =
        this.errors.continueApplication.lastName.length === 0 ? 1 : 0;
    },

    validateContinuePhoneNumber: function(event) {
      // validate phone number from validationsMixin
      this.errors.continueApplication.phoneNumber = this.checkPhoneNumber(
        event
      );

      // set validation-icon
      this.validations.continueApplication.phoneNumber =
        this.errors.continueApplication.phoneNumber.length === 0 ? 1 : 0;
    },

    validateContinueEmail: function(event) {
      // validate email from validationsMixin
      this.errors.continueApplication.primaryEmail = this.checkEmail(event);

      // set validation-icon
      this.validations.continueApplication.primaryEmail =
        this.errors.continueApplication.primaryEmail.length === 0 ? 1 : 0;
    },

    validateContinueDateOfBirth: function(inputValue) {
      // validate date from validationsMixin
      this.errors.continueApplication.dateOfBirth = this.checkDateRangeFromValue(
        inputValue,
        this.getFormattedDate(this.minDateOfBirth),
        this.getFormattedDate(this.maxDateOfBirth)
      );

      // set validation-icon
      this.validations.continueApplication.dateOfBirth =
        this.errors.continueApplication.dateOfBirth.length === 0 ? 1 : 0;
    },

    validateContinueLastFourDigitsOfSsn: function(event) {
      // validate email from validationsMixin
      this.errors.continueApplication.lastFourDigitsOfSsn = this.checkLastFourDigitsOfSsn(
        event
      );

      // set validation-icon
      this.validations.continueApplication.lastFourDigitsOfSsn =
        this.errors.continueApplication.lastFourDigitsOfSsn.length === 0
          ? 1
          : 0;
    },

    validateNewApplicationForm: function() {
      // loop through all fields in errors
      for (var field in this.errors.newApplication) {
        // return false if any field has errors
        if (this.errors.newApplication[field].length !== 0) {
          this.hasErrors.newApplication = true;
          return false;
        }
      }

      // return true if all fields are validated
      return true;
    },

    validateContinueApplicationForm: function() {
      // loop through all fields in errors
      for (var field in this.errors.continueApplication) {
        // return false if any field has errors
        if (this.errors.continueApplication[field].length !== 0) {
          this.hasErrors.continueApplication = true;
          return false;
        }
      }
      // return true if all fields are validated
      return true;
    },

    async startNewApplication() {
      if (!this.validateNewApplicationForm()) {
        return;
      }
      // set moon loader
      this.isLoading = true;
      let createCustomerResponse = await this.callCustomersAPIAndCreateCustomer(
        this.newApplication
      ).catch(() => {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      });
      if (
        createCustomerResponse.status !== 201 ||
        createCustomerResponse.data === undefined
      ) {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      }
      let getUrlasForCustomerResponse = await this.callUrlasAPIAndGetAllCustomerUrlas(
        createCustomerResponse.data.customerId
      ).catch(() => {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      });
      if (
        !(
          getUrlasForCustomerResponse.status === 404 ||
          getUrlasForCustomerResponse.status === 200
        )
      ) {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      }
      if (
        getUrlasForCustomerResponse.status === 200 &&
        this.findPendingUrlas(getUrlasForCustomerResponse.data.results).length >
          0
      ) {
        this.isLoading = false;
        this.redirectToPendingApplication(
          getUrlasForCustomerResponse.data.results[0].customerId
        );
        return;
      }
      this.createNewUrla(createCustomerResponse.data.customerId);
    },

    createNewUrla: async function(responseCustomerId) {
      let createUrlaResponse = await this.callUrlasAPIAndCreateUrla(
        responseCustomerId
      ).catch(() => {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      });
      this.isLoading = false;
      if (
        !(
          createUrlaResponse.status === 201 || createUrlaResponse.status === 200
        )
      ) {
        this.redirectToErrorPage();
        return;
      }
      this.redirectToUrla(
        createUrlaResponse.data.customerId,
        createUrlaResponse.data.loanId,
        createUrlaResponse.data
      );
    },

    findPendingUrlas: function(urlas) {
      if (null == urlas) {
        return [];
      }
      return urlas.filter(
        (loan) => loan.loanStatus === "PARTIAL" || loan.loanStatus === "STARTED"
      );
    },

    async continuePreviousApplication() {
      if (!this.validateContinueApplicationForm()) {
        return;
      }
      // remove error
      this.continueApplication.applicationNotFound = false;
      // set moon loader
      this.isLoading = true;
      let response = await this.callCustomersAPIAndSearchCustomer(
        this.continueApplication
      ).catch(() => {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      });
      this.isLoading = false;
      if (response.status === 404) {
        this.continueApplication.applicationNotFound = true;
        return;
      }
      if (
        response.status !== 200 ||
        response.data.results === undefined ||
        response.data.results.length != 1
      ) {
        this.redirectToErrorPage();
        return;
      }
      let getUrlasForCustomerResponse = await this.callUrlasAPIAndGetAllCustomerUrlas(
        response.data.results[0].mortgageCustomer.customerId
      ).catch(() => {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      });
      if (getUrlasForCustomerResponse.status !== 200) {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      }
      let findPendingUrlas = this.findPendingUrlas(
        getUrlasForCustomerResponse.data.results
      );
      if (findPendingUrlas.length == 0) {
        this.createNewUrla(
          getUrlasForCustomerResponse.data.results[0].customerId
        );
      }
      if (findPendingUrlas.length > 1) {
        this.redirectToPendingApplication(
          getUrlasForCustomerResponse.data.results[0].customerId
        );
      }
      if (findPendingUrlas.length == 1) {
        let pendingUrla = findPendingUrlas[0];
        this.isLoading = false;
        let getCompleteUrlaResponse = await this.callUrlasAPIAndGetUrlaById(
          getUrlasForCustomerResponse.data.results[0].customerId,
          getUrlasForCustomerResponse.data.results[0].loanId
        ).catch(() => {
          this.isLoading = false;
          this.redirectToErrorPage();
          return;
        });
        this.redirectToUrla(
          pendingUrla.customerId,
          pendingUrla.loanId,
          getCompleteUrlaResponse.data
        );
      }
    },

    redirectToPendingApplication: function(customerId) {
      this.$store.commit("setAuthentication", true);
      setTimeout(() => {
        this.$store.commit("setAuthentication", false);
      }, 1800000);
      this.$router.push({
        name: "pending-applications",
        query: { customerId: customerId },
      });
    },
  },

  mounted: function() {
    // call the init function on mounting the component
    this.init();
  },
};
</script>

<style>
/* font styles for form elements */
.new-or-continue form p,
.new-or-continue form legend,
.new-or-continue form label {
  font: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  margin: 0 0 2% 0;
  color: #464646;
}

.new-or-continue form {
  width: 510px;
  margin: 5% auto;
  min-width: 300px;
  padding: 2rem;
}

/* border styles for forms*/
.new-or-continue form {
  background: white;
  -moz-border-radius: 15px; /*for mozilla support*/
  -webkit-border-radius: 15px; /*for chrome support*/
  border-radius: 15px;
}

/* shadow styles for form */
.new-or-continue form {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}

/* remove borders and shadows for mobile view */
@media all and (max-width: 500px) {
  .new-or-continue form {
    border-radius: 0;
    -moz-border-radius: 0; /*for mozilla support*/
    -webkit-border-radius: 0; /*for chrome support*/
    box-shadow: none;
    margin: auto;
    width: auto;
    padding: auto;
  }

  .new-or-continue .left-section,
  .new-or-continue .right-section {
    padding: 0;
  }
}

/* style for Required Asterisk */
.new-or-continue .required,
.new-or-continue .instruction,
.new-or-continue .error {
  text-decoration: none;
  /*color: #bf4d2e;*/
  color: red;
  margin: 0;
  padding: 0;
}

/* style for input and labels */
.new-or-continue input,
.new-or-continue label,
.new-or-continue .empty-label {
  display: inline-block;
  width: 200px;
  box-sizing: border-box;
}

/* hide arrows for date in chrome browser */
.new-or-continue input[type="date"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

/* styles for disabled input[type="tel"] */
.new-or-continue input[type="tel"]:disabled {
  color: #999 !important;
  -webkit-text-fill-color: #999 !important;
}

/* style for Same as above */
.new-or-continue .same-as-above {
  display: block;
}

/* font-size for all children of same-as-above*/
.new-or-continue .same-as-above * {
  font-size: 13px;
}

/* styles for description of SSN */
.ssn-description {
  font-size: 13px;
  position: absolute;
}

/* width for list elements in checkbox */
.new-or-continue input[type="checkbox"] {
  width: auto;
}

/* shake transition for error*/
.shake-enter-active,
.shake-leave-active {
  /*transition: opacity 0.5s;*/
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.shake-enter, .shake-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* styles for button */
.new-or-continue .primary {
  font-size: 14px;
  font-weight: bold;
  margin-top: 5%;
  margin-left: 5%;
}
</style>
