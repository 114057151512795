<template>
  <div>
    <!-- Wrapper -->
    <div class="wrapper">
      <!-- Main -->
      <section class="main">
        <!-- Banner -->
        <section id="banner">
          <slider v-bind:images="images"></slider>
        </section>

        <!-- Blurb -->
        <section>
          <h1>Best possible mortgage experience with us</h1>
          <p>American Smart Lending and its professionals are truly dedicated to delivering the best possible outcomes to your mortgage process. We are the best at suggesting customized loan programs to your specific needs based on the financial profile, needs and goals.</p>
        </section>

        <!-- Features -->
        <section class="features alt">
          <article class="icon fa-diamond">
            <router-link :to="{ name: 'popular-loan-programs'}">
              <h3>our popular loan programs</h3>
            </router-link>
          </article>
          <article class="icon fa-floppy-o">
            <router-link :to="{ name: 'steps-in-mortgage-process'}">
              <h3>steps in the mortgage process</h3>
            </router-link>

            <p>This article describing the steps in the mortgage process is most appropriate light reading for a first time home buyer</p>
          </article>
          <article class="icon fa-laptop">
            <router-link :to="{ name: 'faq'}">
              <h3>FAQ</h3>
            </router-link>
            <p>frequently asked questions while navigating through the mortgage process</p>
          </article>
          <article class="icon fa-paper-plane">
            <router-link :to="{ name: 'dos-and-donts'}">
              <h3>Do and Donts</h3>
            </router-link>
            <p>Here are the important Dos and DONTs for a customer looking for a mortgage for a primary residence, secondary residence, investment home and land loans</p>
          </article>
        </section>
        <!-- Spotlights -->
        <section class="spotlights alt">
          <article>
            <a v-on:click="$router.push({ name: 'docs-for-processing'})" class="image fit">
              <img src="@/assets/images/MortgageApplicationApproved.jpeg" alt />
            </a>
            <h3>Documents needed for loan processing</h3>
            <p>Please click the link below to get the list of documents that we need for the mortgage processing. We would like to gather the complete and comprehensive list of documents required for the loan before we submit the loan application. Submitting a complete list to the underwriter results in lesser number of touch points and a quicker processing time and better results</p>
            <ul class="actions">
              <li>
                <router-link
                  :to="{ name: 'docs-for-processing'}"
                  class="button"
                >Click here for the detailed list</router-link>
              </li>
            </ul>
          </article>
          <article>
            <a v-on:click="$router.push({ name: 'mortgage-definitions'})" class="image fit">
              <img src="@/assets/images/MortgageJargon.jpeg" alt />
            </a>
            <h3>Mortgage Loan Process Glossary</h3>
            <p>We understand that the jargon in the mortgage industry can be confusing and confounding for the first time home buyer as well as someone who has done a transaction a few years ago. We want to help you understand and navigate the process with confidence. Click the link below to learn the definitions of the most frequently used terms</p>
            <ul class="actions">
              <li>
                <router-link :to="{ name: 'mortgage-definitions'}" class="button">Learn More</router-link>
              </li>
            </ul>
          </article>
          <article>
            <a class="image fit">
              <img src="@/assets/images/MortgageProfessor.jpeg" alt />
            </a>
            <h3>Remove the complexity from mortgage</h3>
            <p>We strive hard to make the whole mortgage process simple and easy. We make it easy to understand the quotes, deal with the multiple parties involved in the process and close the transaction on time.</p>
          </article>
          <article>
            <!--a class="image fit">
              <img src="@/assets/images/TSLGLogo.jpeg" alt />
            </a-->
            <h3>How to make Lending Process Smart?</h3>
            <p>Our goal in entering the mortgage industry is to bring the smart into the mortgage process. We strive to make it smart in pricing, processing, people and technology to make things more efficient and cost effective</p>
          </article>
        </section>
      </section>

      <!-- Sidebar -->
      <aside class="sidebar">
        <!-- Blurb -->
        <section>
          <h2>Why choose American Smart Lending</h2>
          <p>We strive to produce the best possible results for you while valuing your time.</p>
          <ul>
            <li>Lowest possible interest rates</li>
            <li>Lowest possible closing cost</li>
            <li>100% on-time closing from our side</li>
            <li>Daily status updates on your loan</li>
            <li>Efficient use of technology</li>
            <li>Smooth execution</li>
          </ul>
        </section>

        <!-- FHEO -->
        <section class="fheo-container">
          <div class="fheo-left">
            <span class="fheo-image">
              <img src="@/assets/images/fheo-logo-80.png" alt />
            </span>
          </div>
          <div class="fheo-right">
            <p>American Smart Lending is an Equal Housing Opportunity Lender.</p>
          </div>
        </section>

        <!-- Featured posts -->
        <section class="featured-posts">
          <h2>Refinancing your primary and investment homes</h2>
          <article>
            <a class="image fit">
              <img src="@/assets/images/RefinanceYourMortgage.jpeg" alt />
            </a>
            <header>
              <span class="date">September 19, 2016</span>
              <h3>
                <a href="#">our edge in refinancing</a>
              </h3>
            </header>
            <p>we are constantly on the watch for lower interest rates for our customers who have purchase primary homes and investment homes through us. We take pride in saving our customers thousands of dollars while helping truly own their home by paying off the mortgage by rolling into lower interest payment programs, lower interest rate programs and shorter duration fixed loan programs.</p>
          </article>
        </section>

        <!-- Posts -->
        <section>
          <h2>Recent Mortgage related News</h2>
          <ul class="posts">
            <li>
              <a
                href="https://www.forbes.com/sites/johnkosar/2018/10/01/get-ready-for-a-big-increase-in-interest-rates/#3805c50d6c71"
              >Interest rates are rising fast</a>
              <span class="date">November 15, 2018</span>
            </li>
            <li>
              <a
                href="https://www.nytimes.com/2018/11/08/us/politics/fed-midterm-elections.html"
              >Fed is poised to raise rates in december 2018</a>
              <span class="date">November 15, 2018</span>
            </li>
          </ul>
          <ul class="actions">
            <li>
              <a href="#" class="button">More Posts</a>
            </li>
          </ul>
        </section>
      </aside>

    </div>
    <!-- home footer -->
    <div class="home-footer">
      <div class="row">
        <div class="quick-links">
          <h4>Quick Links</h4>
          <ul class="quickLinks-icons">
            <li>
              <a href="http://www.consumerfinance.gov/f/201503_cfpb_your-home-loan-toolkit-web.pdf" target="_blank">
                Home Loan Toolkit
              </a>
            </li>
            <li>
              <a href="https://www.nmlsconsumeraccess.org/" target="_blank">
                NMLS Consumer Access
              </a>
            </li>
            <li>
              <a v-on:click="$router.push({ name: 'amortization-calculator' })">
                Amortization Calculator
              </a>
            </li>
          </ul>
        </div>
        <div class="follow-us">
          <h4>Follow Us</h4>
          <ul class="followUs-icons">
            <li class="icon fa-facebook">
              <a href="#">facebook.com/tslg</a>
            </li>
            <li class="icon fa-twitter">
              <a href="#">twitter.com/tslg</a>
            </li>
          </ul>
        </div>
        <div class="contact">
          <h4>Contact Us</h4>
          <ul class="contact-icons">
            <li class="icon fa-home">
              <a href="https://goo.gl/maps/wXxVgidiQs3N17RPA" target="_blank">
                Kadlogic Inc DBA American Smart Lending<br />
                ISAOA ATIMA <br />
                1560 E.Southlake Blvd Ste.100<br />
                Southlake , TX, 76092<br />
              </a>
            </li>
            <li class="icon fa-phone">
              <a href="tel:469-525-6725">Office: +1(469)525-6725</a>
            </li>
            <li class="icon fa-fax">
              <a href="#">Fax: (888)506-9603</a>
            </li>
            <li class="icon fa-envelope">
              <a href="mailto:info@AmericanSmartLending.com"
                >info@AmericanSmartLending.com</a
              >
            </li>

          </ul>
        </div>
      </div>
        
      </div>
  </div>
</template>

<script>
import Slider from "@/components/elements/Slider.vue";

export default {
  name: "Home",
  data() {
    return {
      images: [
        require("@/assets/images/HappyCouple.jpeg"),
        require("@/assets/images/MortgageRateIncreases.jpeg"),
        require("@/assets/images/HappyIndianCouple.jpeg"),
        require("@/assets/images/threePeopleBuyingHome.jpeg")
      ]
    };
  },
  components: {
    slider: Slider
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#banner {
  padding: 0;
}

.image > img {
  cursor: pointer;
}

.disclaimer {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.disclaimer > p {
  background-color: #fefefe;
  margin: 0;
  border: solid #eaeaea 0.05em;
}

.fheo-container {
  display: flex;
  padding: 8%;
}

.fheo-left {
  width: 80px;
}
.fheo-right {
  flex: 1;
  text-align: center;
}

.fheo-right > p {
  padding: 5%;
}

.home-footer div {
  justify-content: space-evenly;
}
</style>
