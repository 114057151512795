<template>
  <div id="app">
    <AppHeader></AppHeader>
    <AppContent></AppContent>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import AppHeader from "./components/layout/AppHeader.vue";
import AppFooter from "./components/layout/AppFooter.vue";
import AppContent from "./components/layout/AppContent.vue";

export default {
  name: "app",
  components: {
    AppHeader: AppHeader,
    AppFooter: AppFooter,
    AppContent: AppContent
  }
};
</script>

<style>
@import "./assets/css/main.css";
</style>