<template>
  <!-- removed Wrapper -->
  <div class="wrapper">
    <!-- Main -->
    <section class="main">
      <!-- Content -->
      <section>
        <!-- display errors if present in form -->
        <p class="error">
          <!-- hide when form has no errors -->
          <span
            v-bind:style="{visibility: hasErrors ? 'visible':'hidden'}"
          >Please check errors present in the form</span>
        </p>
        <h2>Get a Quote</h2>
        <!-- Form -->
        <form id="quote" v-on:submit.prevent="requestQuote" method="post" class="row">
          <!-- a loader element will be displayed when requesting quote -->
          <loader v-if="isLoading" loaderMessage="Requesting Quote"></loader>
          <!-- left section -->
          <section class="left-section col-6 col-12-medium">
            <!-- Required fields instruction -->
            <p class="instruction">
              Required fields are followed by
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>.
            </p>
            <br>
            <!-- Personal information -->
            <!-- First name -->
            <p>
              <label for="first-name">
                <span>First Name:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
              <input
                id="first-name"
                type="text"
                placeholder="First Name"
                v-model="mortgageCustomer.firstName"
                v-on:input="validateFirstName"
                required
              >
              <!-- Icon will be inserted below after validating the above input -->
              <validation-icon v-bind:isValid="validations.firstName"></validation-icon>
            </p>
            <!-- Last name -->
            <p>
              <label for="last-name">
                <span>Last Name:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
              <input
                id="last-name"
                type="text"
                placeholder="Last Name"
                v-model="mortgageCustomer.lastName"
                v-on:input="validateLastName"
                required
              >
              <!-- An icon will be inserted below after validating the above input -->
              <validation-icon v-bind:isValid="validations.lastName"></validation-icon>
            </p>

            <!-- email address -->
            <p>
              <label for="primary-email">
                <span>Email Address:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
              <input
                id="primary-email"
                type="email"
                placeholder="dan@gmail.com"
                v-model="mortgageCustomer.primaryEmail"
                v-on:change="validateEmail"
                required
              >
              <!-- An icon will be inserted below after validating the above input -->
              <validation-icon v-bind:isValid="validations.primaryEmail"></validation-icon>
            </p>
            <!-- Phone number -->
            <p>
              <label for="phone-number">
                <span>Phone Number:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
              <input
                id="phone-number"
                type="tel"
                maxlength="15"
                minlength="15"
                v-mask="'+1(###)###-####'"
                placeholder="+1(713)123-4567"
                v-model="phoneNumberModel"
                v-on:change="validatePhoneNumber"
                required
              >
              <!-- An icon will be inserted below after the validating above input -->
              <validation-icon v-bind:isValid="validations.phoneNumber"></validation-icon>
            </p>

            <p>
              <!-- same as above check box for WhatsApp Number-->
              <span class="same-as-above">
                <label></label>
                <input type="checkbox" id="same-as-above" v-model="sameAsAbove">
                <label for="same-as-above">Same as above</label>
              </span>
              <!-- WhatsApp number -->
              <label for="whatsapp-number">
                <span>WhatsApp Number:</span>
              </label>
              <input
                id="whatsapp-number"
                type="tel"
                maxlength="15"
                minlength="15"
                v-mask="'+1(###)###-####'"
                placeholder="+1(713)123-4567"
                v-model="whatsAppNumberModel"
                v-on:change="validateWhatsAppNumber"
                v-bind:disabled="sameAsAbove ? 'disabled': null"
              >
              <validation-icon v-bind:isValid="validations.whatsAppNumber"></validation-icon>
            </p>
            <!-- Mortgage information -->
            <!-- Purchase/Refinance? -->
            <fieldset id="purchase-or-refinance">
              <ul>
                <li>
                  <label></label>
                </li>
                <li class="medium-width">
                  <input
                    type="radio"
                    id="purchase"
                    value="purchase"
                    v-model="quoteRequest.purchaseOrRefinance"
                    v-on:change="validatePurchaseOrRefinance"
                  >
                  <label for="purchase">Purchase</label>
                  <!-- An icon will be inserted below after validating the above input -->
                  <validation-icon v-bind:isValid="validations.purchaseOrRefinance"></validation-icon>
                </li>
                <li>
                  <label></label>
                </li>
                <li class="medium-width">
                  <input
                    type="radio"
                    id="refinance"
                    value="refinance"
                    v-model="quoteRequest.purchaseOrRefinance"
                  >
                  <label for="refinance">Refinance</label>
                </li>
              </ul>
            </fieldset>

            <!-- Purchase price -->
            <p>
              <label for="purchase-price">
                <span>Purchase price/Appraisal Value:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
              <input
                id="purchase-price"
                type="text"
                placeholder="$ 350,000"
                v-model.lazy="quoteRequest.purchasePrice"
                v-money="quoteRequest.purchasePrice !== null ? currencyFormat : null"
                v-on:blur="validatePurchasePrice"
                required
              >
              <!-- An icon will be inserted below after validating the above input -->
              <validation-icon v-bind:isValid="validations.purchasePrice"></validation-icon>
            </p>
            <!-- Down payment -->
            <p>
              <label for="down-payment">
                <span>Down payment:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
            </p>
            <fieldset class="down-payment-radio">
              <ul>
                <li>
                  <input
                    type="radio"
                    id="five-percent"
                    value="5%"
                    v-model="quoteRequest.downPaymentPercent"
                    v-on:change="calculateDownpayment"
                  >
                  <label for="five-percent">5%</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="ten-percent"
                    value="10%"
                    v-model="quoteRequest.downPaymentPercent"
                    v-on:change="calculateDownpayment"
                  >
                  <label for="ten-percent">10%</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="twenty-percent"
                    value="20%"
                    v-model="quoteRequest.downPaymentPercent"
                    v-on:change="calculateDownpayment"
                  >
                  <label for="twenty-percent">20%</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="twenty-five-percent"
                    value="25%"
                    v-model="quoteRequest.downPaymentPercent"
                    v-on:change="calculateDownpayment"
                  >
                  <label for="twenty-five-percent">25%</label>
                </li>
              </ul>
            </fieldset>
            <p>
              <label></label>
              <input
                id="down-payment"
                type="text"
                placeholder="$ 70,000"
                v-model.lazy="quoteRequest.downPayment"
                v-money="quoteRequest.downPayment !== null ? currencyFormat : null"
                v-on:blur="validateDownPayment"
                required
              >
              <!-- An icon will be inserted below after validating the above input -->
              <validation-icon v-bind:isValid="validations.downPayment"></validation-icon>
            </p>
          </section>
          <!-- right section -->
          <section class="col-6 col-12-medium">
            <!-- Mortgage Broker -->
            <p>
              <label for="mortgage-broker">
                <span>Choose a Mortgage Broker:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>

              <select id="mortgage-broker" v-model="quoteRequest.mortgageBroker">
                <option value="K. C. Kadaru">K. C. Kadaru</option>
                <option value="Doug Tuckett">Doug Tuckett</option>
              </select>
            </p>
            <!-- Estimated credit score -->
            <fieldset>
              <legend>
                <span>Estimated Credit Score:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </legend>
              <ul>
                <li>
                  <input
                    type="radio"
                    id="excellent"
                    value="740+"
                    v-model="quoteRequest.estimatedCreditScore"
                  >
                  <label for="excellent">740+</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="good"
                    value="700 - 739"
                    v-model="quoteRequest.estimatedCreditScore"
                  >
                  <label for="good">700 - 739</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="average"
                    value="680 - 699"
                    v-model="quoteRequest.estimatedCreditScore"
                  >
                  <label for="average">680 - 699</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="below-average"
                    value="680 and below"
                    v-model="quoteRequest.estimatedCreditScore"
                  >
                  <label for="below-average">below 680</label>
                </li>
              </ul>
            </fieldset>

            <!-- Loan program -->
            <fieldset>
              <legend>
                <span>Loan Program:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
                <validation-icon v-bind:isValid="validations.loanProgram"></validation-icon>
              </legend>
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="fixed30"
                    value="30 year fixed"
                    v-model="quoteRequest.loanProgram"
                    v-on:change="validateLoanProgram"
                  >
                  <label for="fixed30">30 year fixed</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="fixed20"
                    value="20 year fixed"
                    v-model="quoteRequest.loanProgram"
                    v-on:change="validateLoanProgram"
                  >
                  <label for="fixed20">20 year fixed</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="fixed15"
                    value="15 year fixed"
                    v-model="quoteRequest.loanProgram"
                    v-on:change="validateLoanProgram"
                  >
                  <label for="fixed15">15 year fixed</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="arm7"
                    value="7 year ARM"
                    v-model="quoteRequest.loanProgram"
                    v-on:change="validateLoanProgram"
                  >
                  <label for="arm7">7 year ARM</label>
                </li>
              </ul>
            </fieldset>

            <!-- Closing date -->
            <fieldset>
              <legend>
                <span>Closing On/Before:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </legend>
              <ul>
                <li>
                  <input
                    type="radio"
                    id="days15"
                    value="15 days"
                    v-model="quoteRequest.closingDate"
                  >
                  <label for="days15">{{dates.fifteenDays}}</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="days21"
                    value="21 days"
                    v-model="quoteRequest.closingDate"
                  >
                  <label for="days21">{{dates.twentyOneDays}}</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="days30"
                    value="30 days"
                    v-model="quoteRequest.closingDate"
                  >
                  <label for="days30">{{dates.thirtyDays}}</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="days45"
                    value="45 days"
                    v-model="quoteRequest.closingDate"
                  >
                  <label for="days45">{{dates.fourtyFiveDays}}</label>
                </li>
              </ul>
            </fieldset>
            <!-- House type -->
            <fieldset>
              <legend>
                <span>House Type:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </legend>
              <ul>
                <li>
                  <input
                    type="radio"
                    id="condominium"
                    value="condominium"
                    v-model="quoteRequest.houseType"
                  >
                  <label for="condominium">Condominium</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="single-family-residence"
                    value="single family residence"
                    v-model="quoteRequest.houseType"
                  >
                  <label for="single-family-residence">Single family residence</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="multi-family-residence"
                    value="multi family residence"
                    v-model="quoteRequest.houseType"
                  >
                  <label for="multi-family-residence">Multi family residence</label>
                </li>
              </ul>
            </fieldset>
            <!-- Residence type -->
            <fieldset>
              <legend>
                <span>Residence Type:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </legend>
              <ul>
                <li>
                  <input
                    type="radio"
                    id="primary-residence"
                    value="primary residence"
                    v-model="quoteRequest.residenceType"
                  >
                  <label for="primary-residence">Primary residence</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="secondary-home"
                    value="secondary home"
                    v-model="quoteRequest.residenceType"
                  >
                  <label for="secondary-home">Secondary home</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="investment-property"
                    value="investment property"
                    v-model="quoteRequest.residenceType"
                  >
                  <label for="investment-property">Investment property</label>
                </li>
              </ul>
            </fieldset>
            <!-- Submit button -->
            <!-- v-bind:class="validateForm() ? '': 'disabled'" -->
            <button type="submit" class="primary" v-bind:disabled="isLoading">Submit</button>
          </section>
        </form>
      </section>
    </section>
  </div>
</template>

<script>
import validationsMixin from "@/mixins/validationsMixin.js";
import simpleQuoteMixin from "@/mixins/simpleQuoteMixin.js";
import inputMasksMixin from "@/mixins/inputMasksMixin.js";
import Loader from "@/components/elements/Loader.vue";

export default {
  // Component Name
  name: "Quote",
  // mixins used in this component
  mixins: [validationsMixin, simpleQuoteMixin, inputMasksMixin],
  // components used
  components: {
    loader: Loader
  },
  // data used in component
  data() {
    return {
      month: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      dates: {
        // dates to display Closing Date field
        fifteenDays: null,
        twentyOneDays: null,
        thirtyDays: null,
        fourtyFiveDays: null
      },
      currencyFormat: {
        // input masking for currency
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 0
      },
      mortgageCustomer: {
        // primary information required to create a customer profile
        firstName: "",
        lastName: "",
        primaryEmail: "",
        phoneNumber: "",
        whatsAppNumber: ""
      },
      validations: {
        // valid fields should be set to 1
        // invalid fields should be set to 0
        firstName: null,
        lastName: null,
        primaryEmail: null,
        phoneNumber: null,
        whatsAppNumber: null,
        loanProgram: null,
        purchaseOrRefinance: null,
        purchasePrice: null,
        downPayment: null
      },
      quoteRequest: {
        // data structure for quote request
        mortgageBroker: null,
        estimatedCreditScore: "",
        loanProgram: "",
        closingDate: "",
        purchaseOrRefinance: "",
        purchasePrice: null,
        downPaymentPercent: "",
        downPayment: null,
        houseType: "",
        residenceType: ""
      },
      sameAsAbove: null,
      errors: {
        // each field should contain an array of errors
        firstName: [],
        lastName: [],
        primaryEmail: [],
        phoneNumber: [],
        whatsAppNumber: [],
        loanProgram: [],
        purchaseOrRefinance: [],
        purchasePrice: [],
        downPayment: []
      },
      hasErrors: false,
      isLoading: false
    };
  },
  computed: {
    // getter and setter for phoneNumber value
    phoneNumberModel: {
      get() {
        // returns the value of mortgageCustomer.phoneNumber
        return this.mortgageCustomer.phoneNumber;
      },
      set(value) {
        // set the phoneNumber value
        this.mortgageCustomer.phoneNumber = value;
        // set the whatsAppNumber also, if "sameAsAbove" is checked
        if (this.sameAsAbove) this.mortgageCustomer.whatsAppNumber = value;
      }
    },

    // getter and setter for whatsAppNumber value
    whatsAppNumberModel: {
      get() {
        // returns the value of mortgageCustomer.whatsAppNumber
        return this.mortgageCustomer.whatsAppNumber;
      },
      set(value) {
        // set the whatsAppNumber value
        this.mortgageCustomer.whatsAppNumber = value;
        // set the whatsAppNumber also, if "sameAsAbove" is checked
        if (this.sameAsAbove) this.mortgageCustomer.phoneNumber = value;
      }
    }
  },

  // methods used in component
  methods: {
    // returns new date with specified days added to current date
    addDaysToDate: function(daysToAdd) {
      // get current date
      var today = new Date();
      // create new date
      var newDate = new Date();
      // add days to new date
      newDate.setDate(today.getDate() + daysToAdd);
      // return new date
      return newDate;
    },

    // returns a date formatted to string eg: Mar 15, 2019
    formatDate: function(date) {
      var formattedDate =
        // format month from array
        this.month[date.getMonth()] +
        " " +
        date.getDate() +
        ", " +
        date.getFullYear();

      return formattedDate;
    },
    calculateDownpayment: function() {
      // unmask purchase price
      var unmaskedPurchasePrice = this.unmaskCurrency(
        this.quoteRequest.purchasePrice
      );

      // return if purchasePrice is empty
      if (unmaskedPurchasePrice === 0 || unmaskedPurchasePrice === null) return;

      // extract percentage
      var unmaskedDownpaymentPercent = this.unmaskPercentage(
        this.quoteRequest.downPaymentPercent
      );

      // return if percentage is empty
      if (
        unmaskedDownpaymentPercent === 0 ||
        unmaskedDownpaymentPercent === null
      )
        return;

      // calculate downpayment
      var calculatedDownPayment =
        (unmaskedPurchasePrice * unmaskedDownpaymentPercent) / 100;

      // save downpayment
      this.quoteRequest.downPayment = calculatedDownPayment;

      // return calculated downPayment;
      return calculatedDownPayment;
    },

    checkDownPaymentPercentage: function() {
      // unmask purchase price
      var unmaskedPurchasePrice = this.unmaskCurrency(
        this.quoteRequest.purchasePrice
      );

      // return if purchasePrice is empty
      if (unmaskedPurchasePrice === 0 || unmaskedPurchasePrice === null) return;

      // unmask down payment
      var unmaskedDownPayment = this.unmaskCurrency(
        this.quoteRequest.downPayment
      );

      // return if down payment is empty
      if (unmaskedDownPayment === 0 || unmaskedDownPayment === null) return;

      // calculate percentage
      var calculatedPercentage =
        (unmaskedDownPayment / unmaskedPurchasePrice) * 100;

      // check the radio button for calculated percentage
      switch (calculatedPercentage) {
        case 5:
          this.quoteRequest.downPaymentPercent = "5%";
          // downPayment is 5% of purchasePrice
          break;
        case 10:
          this.quoteRequest.downPaymentPercent = "10%";
          // downPayment is 10% of purchasePrice
          break;
        case 20:
          this.quoteRequest.downPaymentPercent = "20%";
          // downPayment is 20% of purchasePrice
          break;
        case 25:
          this.quoteRequest.downPaymentPercent = "25%";
          // downPayment is 25% of purchasePrice
          break;
        default:
          this.quoteRequest.downPaymentPercent = null;
      }
    },

    setDates: function() {
      // set dates required for "closing date" field
      this.dates.fifteenDays = this.formatDate(this.addDaysToDate(15));
      this.dates.twentyOneDays = this.formatDate(this.addDaysToDate(21));
      this.dates.thirtyDays = this.formatDate(this.addDaysToDate(30));
      this.dates.fourtyFiveDays = this.formatDate(this.addDaysToDate(45));
    },

    init: function() {
      // set default values for radio buttons
      this.quoteRequest.downPaymentPercent = "20%";
      this.quoteRequest.mortgageBroker = "K. C. Kadaru";
      this.quoteRequest.estimatedCreditScore = "740+";
      this.quoteRequest.loanProgram = ["15 year fixed"];
      this.quoteRequest.closingDate = "30 days";
      this.quoteRequest.houseType = "single family residence";
      this.quoteRequest.residenceType = "primary residence";
      this.sameAsAbove = true;

      // set error for radio button
      this.errors.purchaseOrRefinance.push(
        "Purchase/Refinance? cannot be empty"
      );

      // set dates
      this.setDates();
    },

    validateFirstName: function(event) {
      // validate first name from validationsMixin
      this.errors.firstName = this.checkFirstName(event);

      // set validation-icon
      this.validations.firstName = this.errors.firstName.length === 0 ? 1 : 0;
    },

    validateLastName: function(event) {
      // validate last name from validationsMixin
      this.errors.lastName = this.checkLastName(event);

      // set validation-icon
      this.validations.lastName = this.errors.lastName.length === 0 ? 1 : 0;
    },

    validateEmail: function(event) {
      // validate email from validationsMixin
      this.errors.primaryEmail = this.checkEmail(event);

      // set validation-icon
      this.validations.primaryEmail =
        this.errors.primaryEmail.length === 0 ? 1 : 0;
    },

    validatePhoneNumber: function(event) {
      // validate phone number from validationsMixin
      this.errors.phoneNumber = this.checkPhoneNumber(event);

      // set validation-icon
      this.validations.phoneNumber =
        this.errors.phoneNumber.length === 0 ? 1 : 0;
    },

    validateWhatsAppNumber: function(event) {
      // validate phone number from validationsMixin
      this.errors.whatsAppNumber = this.checkPhoneNumber(event);

      // set validation-icon
      this.validations.whatsAppNumber =
        this.errors.whatsAppNumber.length === 0 ? 1 : 0;
    },

    validatePurchaseOrRefinance: function() {
      // clear errors if field is checked
      this.errors.purchaseOrRefinance = [];

      // validate purchaseOrRefinance and push error
      if (
        this.quoteRequest.purchaseOrRefinance === null ||
        this.quoteRequest.purchaseOrRefinance === ""
      )
        this.errors.purchaseOrRefinance.push(
          "Purchase/Refinance? cannot be empty"
        );

      // set validation-icon
      this.validations.purchaseOrRefinance =
        this.errors.purchaseOrRefinance.length === 0 ? 1 : 0;
    },

    validatePurchasePrice: function(event) {
      // validate price from validationsMixin
      this.errors.purchasePrice = this.checkPrice(event);

      // unmask purchase price
      var unmaskedPurchasePrice = this.unmaskCurrency(
        this.quoteRequest.purchasePrice
      );
      // unmask downpayment
      var unmaskedDownPayment = this.unmaskCurrency(
        this.quoteRequest.downPayment
      );

      // check if down payment is higher than purchase price
      if (
        unmaskedDownPayment !== null &&
        unmaskedDownPayment > unmaskedPurchasePrice
      ) {
        // create error message
        let errorMessage = "purchase price cannot be lower than down payment";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        this.errors.purchasePrice.push(errorMessage);
      } else if (
        unmaskedDownPayment !== null &&
        unmaskedDownPayment < unmaskedPurchasePrice
      ) {
        // remove errors present on down payment
        this.errors.downPayment = [];
        // set valid icon for down payment
        this.validations.downPayment =
          this.errors.downPayment.length === 0 ? 1 : 0;
      }

      // set validation-icon for purchase price
      this.validations.purchasePrice =
        this.errors.purchasePrice.length === 0 ? 1 : 0;

      // calculateDownpayment
      this.calculateDownpayment();
    },

    validateDownPayment: function(event) {
      // validate price from validationsMixin
      this.errors.downPayment = this.checkPrice(event);

      // unmask purchase price
      var unmaskedPurchasePrice = this.unmaskCurrency(
        this.quoteRequest.purchasePrice
      );

      // unmask downpayment
      var unmaskedDownPayment = this.unmaskCurrency(
        this.quoteRequest.downPayment
      );

      // check if down payment is higher than purchase price
      if (
        unmaskedPurchasePrice !== null &&
        unmaskedDownPayment > unmaskedPurchasePrice
      ) {
        // create error message
        let errorMessage = "down payment cannot be higher than purchase price";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        this.errors.downPayment.push(errorMessage);
      } else if (
        unmaskedPurchasePrice !== null &&
        unmaskedDownPayment < unmaskedPurchasePrice
      ) {
        // remove errors present on purchase price
        this.errors.purchasePrice = [];
        // set valid icon for purchase price
        this.validations.purchasePrice =
          this.errors.purchasePrice.length === 0 ? 1 : 0;
      }

      // check down payment percentage
      this.checkDownPaymentPercentage();

      // set validation-icon
      this.validations.downPayment =
        this.errors.downPayment.length === 0 ? 1 : 0;
    },

    validateLoanProgram: function() {
      // clear array
      this.errors.loanProgram = [];

      // check if array is empty
      if (this.quoteRequest.loanProgram.length === 0)
        this.errors.loanProgram.push("loan program cannot be empty");

      // set validation-icon
      this.validations.loanProgram =
        this.errors.loanProgram.length === 0 ? 1 : 0;
    },

    // returns true if form is validated
    validateForm: function() {
      // validate PurchaseOrRefinance
      this.validatePurchaseOrRefinance();

      // loop through all fields in errors
      for (var field in this.errors) {
        // return false if any field has errors
        if (this.errors[field].length !== 0) {
          this.hasErrors = true;
          return false;
        }
      }

      // return true if all fields are validated
      return true;
    },

    // make REST call to API to post data
    requestQuote: function() {
      // check form validation
      if (this.validateForm()) {
        // display loader element
        this.isLoading = true;
        // call POST /customers method of simple-quote API
        this.createCustomer(this.mortgageCustomer)
          .then(customerId => {
            /* eslint-disable */
            this.postQuoteRequest(customerId, this.quoteRequest)
              .then(message => {
                // remove loader element
                this.isLoading = false;
                // route to successful request page
                this.$router.push({
                  name: "quote-success",
                  params: {
                    message,
                    mortgageCustomer: this.mortgageCustomer,
                    quoteRequest: this.quoteRequest
                  }
                });
              })
              .catch(err => {
                // remove loader element
                this.isLoading = false;
                // route to error page
                this.$router.push({ name: "error", params: { message: err } });
              });
          })
          .catch(err => {
            // remove loader element
            this.isLoading = false;
            // route to error page
            this.$router.push({ name: "error", params: { message: err } });
          });
      }
    }
  },

  mounted: function() {
    // call the init function on mounting the Quote.vue component
    this.init();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* font styles for form elements */
form p,
form legend,
form label {
  font: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  margin: 0 0 2% 0;
  color: #464646;
}

form {
  margin-left: 0%;
  margin-bottom: 0%;
}

/* border style for left section of form */
.left-section {
  border-right: dashed #2ebfac 1px;
}

/* border style for left section on mobile devices */
@media screen and (max-width: 800px) {
  .left-section {
    border-right: none;
  }
}
@media screen and (max-width: 480px) {
  .left-section {
    border-right: none;
  }
}
@media screen and (max-width: 360px) {
  .left-section {
    border-right: none;
  }
}

/* style for Same as above */
.same-as-above {
  display: block;
}

/* font-size for all children of same-as-above*/
.same-as-above * {
  font-size: 13px;
}

/* style for Required Asterisk */
.required,
.instruction,
.error {
  text-decoration: none;
  color: #bf4d2e;
  margin: 0;
  padding: 0;
}

/* style for input and labels */
input,
label,
select {
  display: inline-block;
  width: 250px;
  box-sizing: border-box;
}

/* width for list elements in radio */
input[type="radio"] {
  width: auto;
}

/* width for list elements in checkbox */
input[type="checkbox"] {
  width: auto;
}

/* style for list elements in radio/select/checkbox */
fieldset ul {
  margin: 0 0 2% 0;
}

fieldset li {
  display: inline;
  list-style-type: none;
  padding: 0;
}

/* styles for purchase-or-refinance */
#purchase-or-refinance ul {
  margin-left: 0;
  padding-left: 0;
}

/* width for down payment percentage radio buttons */
.down-payment-radio li,
.down-payment-radio label {
  width: 125px;
}

/* align submit button to right */
.primary {
  margin: 0 0 0 70%;
}
</style>