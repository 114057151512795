<template>
  <div id="validation-icon">
    <!-- valid icon -->
    <span v-show="isValid === 1" class="fa fa-2x fa-check valid" aria-hidden="true"></span>
    <!-- invalid icon -->
    <span v-show="isValid === 0" class="fa fa-2x fa-times invalid" aria-hidden="true"></span>
  </div>
</template>

<script>
export default {
  // component name
  name: "ValidationIcon",
  // props can be set from parent component
  props: {
    isValid: null
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.valid {
  color: green;
  padding-left: 10px;
}

.invalid {
  color: red;
  padding-left: 10px;
}

#validation-icon {
  display: inline-block;
}
</style>