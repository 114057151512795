<template>
  <div>
    <!-- Wrapper -->
    <div class="wrapper">
      <!-- Main -->

      <section class="main">
        <div style="background-color: #f8f8f8; text-align: center;">
        <h1>Our valued clients!</h1>
            <photo-collage-wrapper
            gapSize="7px"
            @itemClick="itemClickHandler"
            v-bind="collage"> </photo-collage-wrapper>
          </div>

      </section>
    </div>
    <!-- home footer -->
    <quickLinks></quickLinks>
  </div>
</template>

<script>

import PhotoCollageWrapper from "@/components/elements/collagemaker/PhotoCollageWrapper.vue";
import QuickLinks from "@/components/layout/quickLinkFooter.vue";


export default {
  name: "SuccessStories",
  components: {
    PhotoCollageWrapper,
    quickLinks: QuickLinks,
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
      if (this.width < 500) {
        this.collage.layout.length=0;
        for (var i=0; i< this.collage.photos.length; i++) {
          this.collage.layout[i]=1;
        }
    }
  },
  itemClickHandler() {

     /* Place Holder for Redirecting to the Reviews of the Customer */
     this.$router.push({ name: 'view-reviews' });
   },
   },

  data() {
    return {
    window: {
        width: 0,
      },
    images: [],
    collage: {
      gapSize: "2em",
      borderRadius: "0em",
      width: "auto",
      height: ["calc(50vh - 2em)", "calc(50vh - 1em)", "calc(50vh - 2em)", "calc(50vh - 2em)", "calc(50vh - 2em)", "calc(60vh - 1em)"],
      layout: [3, 3, 3, 2, 3, 3, 3, 3, 3, 3, 3],
      //layout: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      photos: [
          {
          source:
              require("@/assets/images/successStories/HappyPhoto34.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto35.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto36.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto37.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto38.jpeg"),
          },
           {
            source:
              require("@/assets/images/successStories/HappyPhoto39.jpeg"),
          },
           {
            source:
              require("@/assets/images/successStories/HappyPhoto40.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto41.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto42.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto43.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto45.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto44.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto2.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto6.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto11.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto7.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto8.jpeg"),
          },
           {
            source:
              require("@/assets/images/successStories/HappyPhoto3.jpeg"),
          },
           {
            source:
              require("@/assets/images/successStories/HappyPhoto13.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto9.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto24.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto14.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto25.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto26.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto27.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto28.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto29.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto30.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto31.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto32.jpeg"),
          },
          {
            source:
              require("@/assets/images/successStories/HappyPhoto33.jpeg"),
          },
      ],
      showNumOfRemainingPhotos: true,

  }
  }
},

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.image > img {
  cursor: pointer;
}
</style>
