<template>
  <div class="vue-photo-thumb" :style="thumbStyle" @mouseenter="updateHoverState(true)" @mouseleave="updateHoverState(false)" ><slot></slot></div>
</template>

<script>
export default {
  props: {
    thumb: String,
  },
  data() {
    return {
      thumbback: {
        opacity: 1,
        opacityHover: 0.9,
        borderwid: 0,
        borderwidHover: 'solid',
      }
      ,
      hoverState: false
    }
  },
  methods: {
    updateHoverState(isHover) {
    this.hoverState = isHover;
  }
  },
  computed: {
    thumbStyle() {
      var modifier = '';
      if (this.hoverState) {
        modifier = 'Hover';
      }
      return {
        'background-image': `url(${this.thumb})`,
        'opacity': this.thumbback['opacity' + modifier],
        'border': this.thumbback['borderwid' + modifier]
      };
    }
  }
}
</script>

<style lang="css" scoped>
.vue-photo-thumb {
  flex: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f8f8f8;
  border-radius: var(--vue-photo-grid-radius);
}
</style>
