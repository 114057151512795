<template>
  <div>
    <div class="tabs">
      <ul>
        <li
          v-for="(tab,index) in tabs"
          v-bind:key="index"
          class="tab"
          :class="{ 'active': tab.isActive }"
        >
          <a @click.prevent="selectTab(tab)">{{ tab.name }}</a>
        </li>
      </ul>
    </div>
    <div class="tabs-details">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  data() {
    return { tabs: [] };
  },
  created() {
    this.tabs = this.$children;
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = tab.name == selectedTab.name;
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  padding-left: 0;
  list-style-type: none;
}

li {
  border-bottom: 2px solid;
}

li.active,
.active a,
.tab .active {
  color: #50cfbe;
}

a {
  border-bottom: none;
}

.tabs * {
  color: #999;
}

.tabs .tab {
  background-color: inherit;
  display: inline;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.1s;
  font-size: 17px;
}

/* Style the tab content */
.tabs-details {
  padding: 6px 12px;
  border-top: none;
}
</style>