<template>
  <div ref="sectionEight" class="urla-section">
    <h2>Disclaimer</h2>

    <!-- Section description -->
    <p class="urla-section-description">
      I attest that the information submitted is true to the best of my knowledge at this point of time.<br>
      I agree to credit check from the lender for evaluating my application and to provide the basic disclosures and pricing estimated.
    </p>

    <!-- Required fields instruction -->
    <p class="instruction">
      Required fields are followed by
      <strong> <abbr class="required" title="required">*</abbr> </strong>.
    </p>

    <!-- Form for section 7 of URL Application -->
    <form
      id="disclaimer-section-form"
      v-on:submit.prevent="submitSectionEightFromDisclaimer"
      method="post"
    >
     
      <div class="row margin-top-bottom">
            <div class="col-4 col-12-xsmall">
                <input
                  name="disclaimer"
                  type="checkbox"
                  id="disclaimer"
                  value="disclaimer"
                  v-model="disclaimerFlag"
                />
                <label
                  class="font-weight-normal"
                  for="disclaimer"
                  style="width: auto;padding-right: 0px"
                  >I Agree to terms and conditions
                </label> 
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </div>
              <div class="col-4 col-12-xsmall align-right"></div>
              <div class="col-4 col-12-xsmall">
                <span class="reffered-by-heading">Signature: </span>
                <strong>
                  <abbr class="required" title="required">* </abbr>
                </strong>
                <input
                  type="text"
                  placeholder="Enter your name"
                  v-model="signature"
                />
              </div>
      </div>
      <!-- clearfix for back and save -->
      <div class="clearfix">
        <!-- back button -->
        <button
          type="button"
          class="primary wizard__back pull-left"
          @click.prevent="goBack()"
        >
          <span>
            <i class="fa fa-arrow-left fa-lg"></i>
            back
          </span>
        </button>
        
        <!-- save & continue button -->
        <button type="submit" 
        :class="{'disabled': disableSubmit()}"
        class="primary wizard__next pull-right">
          <span>Submit</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { bus } from "@/main";
import validationsMixin from "@/mixins/validationsMixin.js";
import inputMasksMixin from "@/mixins/inputMasksMixin.js";
import jsonUtilMixin from "@/mixins/jsonUtilMixin.js";

export default {
  name: "Disclaimer",
  // date received from parent component
  props: ["sourceSection8"],
  // mixins used
  mixins: [validationsMixin, inputMasksMixin, jsonUtilMixin],
  data() {
    return {
      section8LoanOriginatorInformation: {
        currentPage: 7,
        loanApplicationProgress: 800,
        sectionName: "section8LoanOriginatorInformation",
        loanOriginatorName: "KC",
        referredBy: null,
      },
      disclaimerFlag: false,
      signature: '',
      validations: null,
      errors: null,
    };
  },

  methods: {
    goBack: function() {
      // emit event to go back with current page number
      bus.$emit("goBack", this.section8LoanOriginatorInformation.currentPage);
    },
    submitSectionEightFromDisclaimer: function() {
      // validate form
      bus.$emit("postUrla", this.section8LoanOriginatorInformation);
    },
    disableSubmit: function() {
      if(this.disclaimerFlag && this.signature && this.signature.trim()) {
        return false;
      }
      return true;
    }
  },

  mounted: function() {
    // set default values

    // scroll to top of page
    this.$scrollTo(this.$refs.sectionEight, {
      duration: 500,
      easing: "ease",
      offset: -200,
    });
  },

  created: function() {
    // copy data received from urla into current section
    this.section8LoanOriginatorInformation = this.parseSection(
      this.section8LoanOriginatorInformation,
      this.sourceSection8
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .margin-top-bottom {
    margin-top: 1em;
    margin-bottom: 1em;
  }
</style>
