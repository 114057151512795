<template>
  <div id="contact-wrapper">
    <div class="inner">
      <section class="info">
        <h3>About Us</h3>
        <div class="about">
          <p>
            American Smart Lending is a leading mortgage brokerage that is
            built primarily with a focus on providing customers with lowest
            possible mortgage rates with unmatched customer service
          </p>

          <div class="licence">
            <h3>License Information</h3>
            <p>
              <strong class="name">American Smart Lending</strong>
              Nationwide Multistate Licensing System(NMLS) # 1456732
              <router-link :to="{ name: 'LicensingInfo'}"><h4><img class="vue-photo-thumb" :style="thumbStyle" src="@/assets/images/usa_map.jpg" style="width:300px;height:160px;" nopin="nopin" @mouseenter="updateHoverState(true)" @mouseleave="updateHoverState(false)" alt /></h4></router-link>
            </p>
          </div>
          <div class="licence">
            <p>
              <strong class="name">Hours of Operation</strong>
              <br />Mon to Sat 9:00AM - 6:00PM
            </p>
          </div>

          <div class="file-upload">
            <strong class="name">File upload location:</strong>
            <br />
            <a href="https://americansmartlending.com/upload" target="_blank"
              >https://americansmartlending.com/upload</a
            >
          </div>
          <!--ul class="actions">
              <li>
                <a href="#" class="button">Learn More</a>
              </li>
          </ul-->
        </div>

        <div class="team">
          <article>
            <span class="image">
              <img src="@/assets/images/kc.jpeg" alt />
            </span>
            <p>
              <strong class="name">KC Kadaru</strong>
              <span class="title">President and CEO</span>
              <br />
              <span class="title">NMLS #1168294</span>
            </p>
          </article>
          <article>
            <span class="image">
              <img src="@/assets/images/doug-tuckett.jpg" alt />
            </span>
            <p>
              <strong class="name">Doug Tuckett</strong>
              <span class="title">Chief Mortgage Officer</span>
              <br />
              <span class="title">NMLS #304633</span>
            </p>
          </article>
          <article>
            <span class="image">
              <img src="@/assets/images/harry-dunmire.jpg" alt />
            </span>
            <p>
              <strong class="name">Harry Dunmire</strong>
              <span class="title">Vice President</span>
              <br />
              <span class="title">NMLS #258067</span>
            </p>
          </article>
          <article>
            <span class="image">
              <img src="@/assets/images/pradip.jpeg" alt />
            </span>
            <p>
              <strong class="name">Pradip Thakkar</strong>
              <span class="title">Sr. Mortgage Consultant</span>
              <br />
              <span class="title">NMLS #750779</span>
            </p>
          </article>
          <article>
            <span class="image">
              <img src="@/assets/images/ramesh.jpg" alt />
            </span>
            <p>
              <strong class="name">Ramesh Menon</strong>
              <span class="title">Sr. Mortgage Consultant</span>
              <br />
              <span class="title">NMLS #1999524</span>
            </p>
          </article>
          <article>
            <span class="image">
              <img src="@/assets/images/venu.jpeg" alt />
            </span>
            <p>
              <strong class="name">Venu Arety</strong>
              <span class="title">Mortgage Consultant</span>
              <br />
              <span class="title">NMLS #2331228</span>
            </p>
          </article>
          <article>
            <span class="image">
              <img src="@/assets/images/AnjaniSattiraju.jpeg" alt />
            </span>
            <p>
              <strong class="name">Anjani Sattiraju</strong>
              <span class="title"
                >Mortgage Consultant</span>
              <br />
              <span class="title">NMLS #2300014</span>
            </p>
          </article>
          <article>
            <span class="image">
              <img src="@/assets/images/mahesh.jpeg" alt />
            </span>
            <p>
              <strong class="name">MK Jalli</strong>
              <span class="title"
                >Director of Operations and Market Research</span
              >
            </p>
          </article>
          <article>
            <span class="image">
              <img src="@/assets/images/sitara.jpeg" alt />
            </span>
            <p>
              <strong class="name">Sitara Jagannath</strong>
              <span class="title"
                >Director of Funding Operations and Post-Closing</span
              >
            </p>
          </article>
        </div>
      </section>
      <section class="contact">
        <h3>Contact Us</h3>
        <ul class="contact-icons">
          <li class="icon fa-home">
            <a href="https://goo.gl/maps/wXxVgidiQs3N17RPA" target="_blank">
              Kadlogic Inc DBA American Smart Lending<br />
              ISAOA ATIMA <br />
              1560 E.Southlake Blvd Ste.100<br />
              Southlake , TX, 76092<br />
            </a>
          </li>
          <li class="icon fa-phone">
            <a href="tel:469-525-6725">Office: +1(469)525-6725</a>
          </li>
          <li class="icon fa-fax">
            <a href="#">Fax: (888)506-9603</a>
          </li>
          <li class="icon fa-envelope">
            <a href="mailto:info@AmericanSmartLending.com"
              >info@AmericanSmartLending.com</a
            >
          </li>
          <li class="icon fa-facebook">
            <a href="#">facebook.com/tslg</a>
          </li>
          <li class="icon fa-twitter">
            <a href="#">twitter.com/tslg</a>
          </li>
        </ul>
      </section>
    </div>

    <!-- div class="wrapper">
      <section class="main">

        <section>
          <div class="box">
            <h1>Contact Information and Licensing Information</h1>
            <h3>TexSmart Lending</h3>
            <p>
              Nationwide Multistate Licensing System(NMLS) # 1168294
              <br>Email - kc@TexSmartLending.com
              <br>Fax - (888)506-9603
              <br>File upload location:
              <a
                href="https://documentguardian3.myabt.net/filedrop/kc@texsmartrealty.com"
                target="_blank"
              >https://documentguardian3.myabt.net/filedrop/kc@texsmartrealty.com</a>
            </p>
          </div>
        </section>
        <section class="spotlights alt"></section>
      </section>
    </div-->
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      thumbback: {
        opacity: 1,
        opacityHover: 0.7,
      }
      ,
      hoverState: false
    }
  },
  methods: {
    updateHoverState(isHover) {
    this.hoverState = isHover;
  }
  },
  computed: {
    thumbStyle() {
      var modifier = '';
      if (this.hoverState) {
        modifier = 'Hover';
      }
      return {
        'background-image': `url(${this.thumb})`,
        'opacity': this.thumbback['opacity' + modifier],
      };
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vue-photo-thumb {
  flex: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f8f8f8;
}
#contact-wrapper {
  word-wrap: break-word;
}

#contact-wrapper {
  /* background: #fff; */
  overflow-x: hidden;
  padding-bottom: 0.5em;
}

#contact-wrapper > .inner {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 auto;
  max-width: 84em;
  width: calc(100% - 4em);
}

#contact-wrapper .info {
  padding: 2.5em 3.25em 2.5em 3.25em;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -moz-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#contact-wrapper .info h3 {
  width: 100%;
}

#contact-wrapper .info .about {
  width: 50%;
}

#contact-wrapper .info .team {
  padding-left: 3.2em;
  width: 50%;
}

#contact-wrapper .info .team article {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

#contact-wrapper .info .team .image {
  border-radius: 100%;
  margin-bottom: 2em;
  margin-right: 1.5em;
}

#contact-wrapper .info .team .image img {
  border-radius: 100%;
  height: 80px;
  width: 80px;
  object-fit: cover;
}

#contact-wrapper .info .team .name {
  display: block;
}

#contact-wrapper .info .team .title {
  color: #999;
  font-size: 0.875em;
}

#contact-wrapper .contact {
  padding: 2.5em 3.25em 2.5em 3.25em;
  -moz-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  border-left: solid 1px rgba(144, 144, 144, 0.25);
  width: 33.3333333333%;
}

#contact-wrapper .copyright {
  /* border-top: solid 1px rgba(144, 144, 144, 0.25); */
  color: #999;
  font-size: 0.875em;
  padding: 2em 2em;
  text-align: center;
  width: 100%;
}

#contact-wrapper .copyright li {
  border-left: solid 1px rgba(144, 144, 144, 0.25);
  display: inline-block;
  list-style: none;
  margin-left: 1.5em;
  padding-left: 1.5em;
}

#contact-wrapper .copyright li:first-child {
  border-left: 0;
  margin-left: 0;
  padding-left: 0;
}

@media screen and (max-width: 1280px) {
  #contact-wrapper > .inner {
    width: calc(100% - 3em);
  }

  #contact-wrapper .info {
    padding: 2.5em 2.25em 0.5em 2.25em;
  }

  #contact-wrapper .info .team {
    padding-left: 2.25em;
  }

  #contact-wrapper .contact {
    padding: 2.5em 2.25em 0.5em 2.25em;
  }

  #contact-wrapper .copyright {
    padding-bottom: 2em;
    padding-top: 2em;
  }
}

@media screen and (max-width: 980px) {
  #contact-wrapper > .inner {
    -moz-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  #contact-wrapper .contact {
    border-left: none;
    width: 100%;
    position: relative;
  }

  #contact-wrapper .contact:before {
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: rgba(144, 144, 144, 0.25);
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 200vw;
  }
}

@media screen and (max-width: 736px) {
  #contact-wrapper > .inner {
    width: calc(100% - 2em);
  }

  #contact-wrapper .info .about {
    width: 100%;
    margin-bottom: 1em;
  }

  #contact-wrapper .info .team {
    padding-left: 0;
    width: 100%;
  }

  #contact-wrapper .copyright {
    padding-bottom: 2em;
    padding-top: 2em;
  }

  #contact-wrapper .copyright li {
    display: block;
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
  }
}

@media screen and (max-width: 480px) {
  #contact-wrapper > .inner {
    width: 100%;
  }

  #contact-wrapper .info {
    padding: 2em 1.5em 0.1em 1.5em;
  }

  #contact-wrapper .contact {
    padding: 2em 1.5em 0.1em 1.5em;
  }
}
</style>
