import Home from "./components/pages/Home.vue";
import NewOrContinue from "./components/pages/UrlaPage/NewOrContinue.vue";
import Urla from "./components/pages/UrlaPage/Urla.vue";
import UrlaSubmitted from "./components/pages/UrlaPage/UrlaSubmitted.vue";
import PendingApplications from "./components/pages/UrlaPage/PendingApplications.vue";
import FAQ from "./components/pages/FAQ.vue";
import Contact from "./components/pages/Contact.vue";
import About from "./components/pages/About.vue";
import Upload from "./components/pages/Upload.vue";
import ViewReviews from "./components/pages/ReviewsPage/ViewReviews.vue";
import CreateReview from "./components/pages/ReviewsPage/CreateReviews.vue";
import SuccessStories from "./components/pages/OtherPages/successStories.vue";
import PopularLoanPrograms from "./components/pages/OtherPages/PopularLoanPrograms.vue";
import StepsInMortgageProcess from "./components/pages/OtherPages/StepsInMortgageProcess.vue";
import DosAndDonts from "./components/pages/OtherPages/DosAndDonts.vue";
import Disclosure from "./components/pages/OtherPages/Disclosure.vue";
import Resources from "./components/pages/OtherPages/Resources.vue";
import DocsForProcessing from "./components/pages/OtherPages/DocsForProcessing.vue";
import MortgageDefinitions from "./components/pages/OtherPages/MortgageDefinitions.vue";
import LicensingInfo from "./components/pages/OtherPages/LicensingInfo.vue";
import Quote from "./components/pages/GetQuotePage/Quote.vue";
import QuoteSuccess from "./components/pages/GetQuotePage/QuoteSuccess.vue";
import Error from "./components/pages/Error.vue";

// vuex store
import { store } from "./store/store";

// navigation guard for routes
function requireAuth(to, from, next) {
  if(store.state.isAuthenticated == false){
    next("/newOrContinueApplication");
  }
  else{
    next();
  }
}

// Define routes
export default [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/newOrContinueApplication",
    name: "new-or-continue",
    component: NewOrContinue,
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ,
  },
  {
    path: "/SuccessStories",
    name: "success-stories",
    component: SuccessStories,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/licensing-info",
    name: "LicensingInfo",
    component: LicensingInfo,
  },
  {
    path: "/upload",
    name: "upload",
    component: Upload,
  },
  {
    path: "/reviews",
    name: "view-reviews",
    component: ViewReviews,
  },
  {
    path: "/CreateReview",
    name: "create-review",
    component: CreateReview,
  },
  {
    path: "/PolularLoanPrograms",
    name: "popular-loan-programs",
    component: PopularLoanPrograms,
  },
  {
    path: "/StepsInMortgageProcess",
    name: "steps-in-mortgage-process",
    component: StepsInMortgageProcess,
  },
  {
    path: "/DosAndDonts",
    name: "dos-and-donts",
    component: DosAndDonts,
  },
  {
    path: "/disclosure",
    name: "disclosure",
    component: Disclosure,
  },
  {
    path: "/resources",
    name: "resources",
    component: Resources,
  },
  {
    path: "/DocsForProcessing",
    name: "docs-for-processing",
    component: DocsForProcessing,
  },
  {
    path: "/MortgageDefinitions",
    name: "mortgage-definitions",
    component: MortgageDefinitions,
  },
  {
    path: "/AmortizationCalculator",
    name: "amortization-calculator",
    component: () => import("./components/pages/AmortizationCalculator.vue"),
  },
  {
    path: "/quote",
    name: "quote",
    component: Quote,
  },
  {
    path: "/quoteRequested",
    name: "quote-success",
    props: true,
    component: QuoteSuccess,
  },
  {
    path: "/urla",
    name: "urla",
    props: true,
    component: Urla,
    beforeEnter: requireAuth,
  },
  {
    path: "/urlaSubmitted",
    name: "urla-submitted",
    props: true,
    component: UrlaSubmitted,
  },
  {
    path: "/pendingApplications",
    name: "pending-applications",
    props: true,
    component: PendingApplications,
    beforeEnter: requireAuth,
  },
  {
    path: "/error",
    name: "error",
    component: Error,
  },
  {
    path: "*",
    redirect: {
      name: "home",
    },
  },
];
