<template>
  <div class="vue-photo-collage-wrapper">
    <photo-collage
      :disabled="disabled"
      :width="width"
      :height="internalHeight"
      :layout="layout"
      :layoutPhotoMaps="layoutPhotoMaps"
      :layoutNum="layoutNum"
      :remainingNum="remainingNum"
      :showNumOfRemainingPhotos="showNumOfRemainingPhotos"
      @itemClick="() => $emit('itemClick')"
    >
    </photo-collage>
  </div>
</template>

<script>
import PhotoCollage from "@/components/elements/collagemaker/PhotoCollage.vue";

function createPhotoIds(photos) {
  return photos.map((data, i) => {
    return { ...data, id: i };
  });
}

function createLayoutPhotoMaps(layout, photos) {
  const newPhotos = createPhotoIds(photos);
  const newMaps = {};
  layout.reduce((accumulator, currentValue, currentIndex) => {
    newMaps[currentIndex] = newPhotos.slice(
      accumulator,
      accumulator + currentValue
    );
    return accumulator + currentValue;
  }, 0);

  return newMaps;
}

export default {
  name: "PhotoCollageWrapper",
  components: {
    PhotoCollage,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "800px",
    },
    height: {
      type: Array,
    },
    layout: {
      type: Array,
      default() {
        return []
      }
    },
    photos: {
      type: Array,
      default() {
        return []
      }
    },
    showNumOfRemainingPhotos: {
      type: Boolean,
      default: false,
    },
    gapSize: String,
    borderRadius: String,
  },
  data() {
    const layout = this.layout;
    const internalHeight = this.height;

    if (internalHeight.length < layout.length) {
      for (let i = 0; i < layout.length; i++) {
        internalHeight[i] = "300px";
      }
    }

    return {
      internalHeight,
      allowRender: false,
      layoutPhotoMaps: {},
      viewerIsOpen: false,
      currentImage: 0,
      window: {
       width: 0
      }
    };
  },
  watch: {
    layoutPhotoMaps: {
      handler() {
        this.allowRender = !Object.keys(this.layoutPhotoMaps).length;
      },
    },
    gapSize: {
      handler() {
        this.setGapSize();
      },
      deep: true,
    },
    borderRadius: {
      handler() {
        this.setBorderRadius();
      },
      deep: true,
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.layoutPhotoMaps = createLayoutPhotoMaps(this.layout, this.photos);
    this.setGapSize();
    this.setBorderRadius();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
  handleResize() {
    if (window.width < 500) {
      for (let i = 0; i < this.layout.length; i++) {
        this.layout[i] = 1;
      }
    }
  },
    setGapSize() {
      if (document) {
        document.documentElement.style.setProperty(
          "--vue-photo-grid-gap",
          this.gapSize
        );
      }
    },
    setBorderRadius() {
      if (document) {
        document.documentElement.style.setProperty(
          "--vue-photo-grid-radius",
          this.borderRadius
        );
      }
    },
  },
  computed: {
    layoutNum() {
      return this.layout.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
    },
    remainingNum() {
      return this.photos.length - this.layoutNum;
    },
  },
};
</script>
