<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "AppContent"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>