<template>
  <div ref="sectionFive" class="urla-section">
    <h2>Declarations</h2>
    <!-- Section description -->
    <p class="urla-section-description">
      This section asks you specific questions about the property, your funding,
      and your past financial history.
    </p>

    <!-- Required fields instruction -->
    <p class="instruction">
      Required fields are followed by
      <strong>
        <abbr class="required" title="required">*</abbr> </strong
      >.
    </p>

    <!-- Form for section 5 of URL Application -->
    <form
      id="section-5-form"
      v-on:submit.prevent="submitSectionFive"
      method="post"
    >
      <!-- part 5a. About this Property and Your moey for this Loan -->
      <div>
        <h4>About this Property and Your money for this Loan</h4>
        <!-- question A -->
        <div>
          <!-- answer 1 -->
          <div class="row">
            <p class="col-7 col-12-xsmall">
              <label style="display: inline" for="question-a-1">
                A.
                <span class="font-weight-normal"
                  >Will you occupy the property as your primary residence?</span
                >
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
            </p>
            <fieldset class="urla-radio radio-inline col-5 col-12-xsmall">
              <ul>
                <li>
                  <input
                    name="question-a-1"
                    type="radio"
                    id="question-a-1-no"
                    v-bind:value="false"
                    v-model="
                      section5Declarations.part5aAboutThisProperty.questionA
                        .answer1
                    "
                    required
                  />
                  <label
                    class="font-weight-normal small-label"
                    for="question-a-1-no"
                    >No</label
                  >
                </li>
                <li>
                  <input
                    name="question-a-1"
                    type="radio"
                    id="question-a-1-yes"
                    v-bind:value="true"
                    v-model="
                      section5Declarations.part5aAboutThisProperty.questionA
                        .answer1
                    "
                  />
                  <label
                    class="font-weight-normal small-label"
                    for="question-a-1-yes"
                    >Yes</label
                  >
                </li>
              </ul>
            </fieldset>
          </div>

          <!-- answer 2 -->
          <transition name="fade">
            <div
              class="row"
              v-if="
                section5Declarations.part5aAboutThisProperty.questionA.answer1
              "
            >
              <p class="col-7 col-12-xsmall">
                <label style="display: inline" for="question-a-2">
                  <span class="font-weight-normal"
                    >have you had an ownership interest in another property in
                    the last three years?</span
                  >
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
              </p>
              <fieldset class="urla-radio radio-inline col-5 col-12-xsmall">
                <ul>
                  <li>
                    <input
                      name="question-a-2"
                      type="radio"
                      id="question-a-2-no"
                      v-bind:value="false"
                      v-model="
                        section5Declarations.part5aAboutThisProperty.questionA
                          .answer2
                      "
                      required
                    />
                    <label
                      class="font-weight-normal small-label"
                      for="question-a-2-no"
                      >No</label
                    >
                  </li>
                  <li>
                    <input
                      name="question-a-2"
                      type="radio"
                      id="question-a-2-yes"
                      v-bind:value="true"
                      v-model="
                        section5Declarations.part5aAboutThisProperty.questionA
                          .answer2
                      "
                    />
                    <label
                      class="font-weight-normal small-label"
                      for="question-a-2-yes"
                      >Yes</label
                    >
                  </li>
                </ul>
              </fieldset>
            </div>
          </transition>

          <!-- answer 3 -->
          <transition name="fade">
            <div
              v-if="
                section5Declarations.part5aAboutThisProperty.questionA
                  .answer1 &&
                  section5Declarations.part5aAboutThisProperty.questionA.answer2
              "
              class="row"
            >
              <div class="col-7 col-12-xsmall">
                <label
                  class="large-label font-weight-normal"
                  for="question-a-3"
                >
                  <span>What type of property did you own?</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <select
                  id="question-a-3"
                  v-model="
                    section5Declarations.part5aAboutThisProperty.questionA
                      .answer3
                  "
                  required
                >
                  <option value="Primary Residence" selected="selected"
                    >Primary Residence</option
                  >
                  <option value="Gift of Equity"
                    >FHA Secondary Residence</option
                  >
                  <option value="Second Home">Second Home</option>
                  <option value="Investment Property"
                    >Investment Property</option
                  >
                </select>
              </div>
            </div>
          </transition>

          <!-- answer 4 -->
          <transition name="fade">
            <div
              v-if="
                section5Declarations.part5aAboutThisProperty.questionA
                  .answer1 &&
                  section5Declarations.part5aAboutThisProperty.questionA.answer2
              "
              class="row"
            >
              <div class="col-7 col-12-xsmall">
                <label
                  class="large-label font-weight-normal"
                  for="question-a-4"
                >
                  <span>How did you hold title to the property?</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <select
                  id="question-a-4"
                  v-model="
                    section5Declarations.part5aAboutThisProperty.questionA
                      .answer4
                  "
                  required
                >
                  <option value="by yourself" selected="selected"
                    >By yourself</option
                  >
                  <option value="jointly with your spouse"
                    >Jointly with your spouse</option
                  >
                  <option value="jointly with another person"
                    >Jointly with another person</option
                  >
                </select>
              </div>
            </div>
          </transition>
        </div>

        <hr class="urla-break" />

        <!-- question B -->
        <div class="row">
          <p class="col-7 col-12-xsmall">
            <label style="display: inline" for="question-b">
              B.
              <span class="font-weight-normal"
                >If this is a Purchase Transaction: Do you have a family
                relationship or business affiliation with the seller of the
                property?</span
              >
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
          </p>
          <fieldset class="urla-radio radio-inline col-5 col-12-xsmall">
            <ul>
              <li>
                <input
                  name="question-b"
                  type="radio"
                  id="question-b-no"
                  v-bind:value="false"
                  v-model="
                    section5Declarations.part5aAboutThisProperty.questionB
                  "
                  required
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-b-no"
                  >No</label
                >
              </li>
              <li>
                <input
                  name="question-b"
                  type="radio"
                  id="question-b-yes"
                  v-bind:value="true"
                  v-model="
                    section5Declarations.part5aAboutThisProperty.questionB
                  "
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-b-yes"
                  >Yes</label
                >
              </li>
            </ul>
          </fieldset>
        </div>

        <hr class="urla-break" />

        <!-- question C -->
        <div>
          <!-- answer 1 -->
          <div class="row">
            <p class="col-7 col-12-xsmall">
              <label style="display: inline" for="question-c-1">
                C.
                <span class="font-weight-normal">
                  Are you borrowing any money for this real estate transaction
                  <span class="font-style-italic"
                    >(eg., money for your closing costs or down payment)</span
                  >
                  or obtaining any money from another party, such as the seller
                  or realtor, that you have not disclosed on this loan
                  application?
                </span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
            </p>
            <fieldset class="urla-radio radio-inline col-5 col-12-xsmall">
              <ul>
                <li>
                  <input
                    name="question-c-1"
                    type="radio"
                    id="question-c-1-no"
                    v-bind:value="false"
                    v-model="
                      section5Declarations.part5aAboutThisProperty.questionC
                        .answer1
                    "
                    required
                  />
                  <label
                    class="font-weight-normal small-label"
                    for="question-c-1-no"
                    >No</label
                  >
                </li>
                <li>
                  <input
                    name="question-c-1"
                    type="radio"
                    id="question-c-1-yes"
                    v-bind:value="true"
                    v-model="
                      section5Declarations.part5aAboutThisProperty.questionC
                        .answer1
                    "
                  />
                  <label
                    class="font-weight-normal small-label"
                    for="question-c-1-yes"
                    >Yes</label
                  >
                </li>
              </ul>
            </fieldset>
          </div>

          <!-- answer 2 -->
          <transition name="fade">
            <div
              class="row"
              v-if="
                section5Declarations.part5aAboutThisProperty.questionC.answer1
              "
            >
              <p>
                <label
                  class="large-label font-weight-normal"
                  for="question-c-2"
                >
                  <span>What is the amount of this money?</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  class="small-input"
                  id="question-c-2"
                  type="text"
                  placeholder="$250,000"
                  v-model.lazy="
                    section5Declarations.part5aAboutThisProperty.questionC
                      .answer2
                  "
                  v-money="
                    section5Declarations.part5aAboutThisProperty.questionC
                      .answer2 !== null
                      ? currencyFormat
                      : null
                  "
                  v-on:blur="validateQuestionC2($event)"
                  required
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part5aAboutThisProperty.questionC.answer2
                  "
                ></validation-icon>
              </p>
            </div>
          </transition>
        </div>

        <hr class="urla-break" />

        <!-- question D -->
        <div>
          <!-- answer 1 -->
          <div class="row">
            <p class="col-7 col-12-xsmall">
              <label style="display: inline" for="question-d-1">
                D.
                <span class="font-weight-normal"
                  >1. Have you or will you be applying for a mortgage loan on
                  another property (not the property securing this loan) on or
                  before closing this transaction that is not disclosed on this
                  loan application?</span
                >
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
            </p>
            <fieldset class="urla-radio radio-inline col-5 col-12-xsmall">
              <ul>
                <li>
                  <input
                    name="question-d-1"
                    type="radio"
                    id="question-d-1-no"
                    v-bind:value="false"
                    v-model="
                      section5Declarations.part5aAboutThisProperty.questionD
                        .answer1
                    "
                    required
                  />
                  <label
                    class="font-weight-normal small-label"
                    for="question-d-1-no"
                    >No</label
                  >
                </li>
                <li>
                  <input
                    name="question-d-1"
                    type="radio"
                    id="question-d-1-yes"
                    v-bind:value="true"
                    v-model="
                      section5Declarations.part5aAboutThisProperty.questionD
                        .answer1
                    "
                  />
                  <label
                    class="font-weight-normal small-label"
                    for="question-d-1-yes"
                    >Yes</label
                  >
                </li>
              </ul>
            </fieldset>
          </div>

          <!-- answer 2 -->
          <div class="row">
            <p class="col-7 col-12-xsmall">
              <label style="display: inline" for="question-d-2">
                <span class="font-weight-normal">
                  2. Have you or will you be applying for any new credit
                  <span class="font-style-italic"
                    >(e.g., installment loan, credit card, etc.)</span
                  >on or befor closing this loan that is not disclosed on this
                  application?
                </span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
            </p>
            <fieldset class="urla-radio radio-inline col-5 col-12-xsmall">
              <ul>
                <li>
                  <input
                    name="question-d-2"
                    type="radio"
                    id="question-d-2-no"
                    v-bind:value="false"
                    v-model="
                      section5Declarations.part5aAboutThisProperty.questionD
                        .answer2
                    "
                    required
                  />
                  <label
                    class="font-weight-normal small-label"
                    for="question-d-2-no"
                    >No</label
                  >
                </li>
                <li>
                  <input
                    name="question-d-2"
                    type="radio"
                    id="question-d-2-yes"
                    v-bind:value="true"
                    v-model="
                      section5Declarations.part5aAboutThisProperty.questionD
                        .answer2
                    "
                  />
                  <label
                    class="font-weight-normal small-label"
                    for="question-d-2-yes"
                    >Yes</label
                  >
                </li>
              </ul>
            </fieldset>
          </div>
        </div>

        <hr class="urla-break" />

        <!-- question E -->
        <div class="row">
          <p class="col-7 col-12-xsmall">
            <label style="display: inline" for="question-e">
              E.
              <span class="font-weight-normal">
                Will this property be subject to a lien that could take priority
                over the first mortgage lien, such as a clean energy lien paid
                through your property taxes
                <span class="font-style-italic"
                  >(e.g., the Property Assessed Clean Energy Program)</span
                >?
              </span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
          </p>
          <fieldset class="urla-radio radio-inline col-5 col-12-xsmall">
            <ul>
              <li>
                <input
                  name="question-e"
                  type="radio"
                  id="question-e-no"
                  v-bind:value="false"
                  v-model="
                    section5Declarations.part5aAboutThisProperty.questionE
                  "
                  required
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-e-no"
                  >No</label
                >
              </li>
              <li>
                <input
                  name="question-e"
                  type="radio"
                  id="question-e-yes"
                  v-bind:value="true"
                  v-model="
                    section5Declarations.part5aAboutThisProperty.questionE
                  "
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-e-yes"
                  >Yes</label
                >
              </li>
            </ul>
          </fieldset>
        </div>
      </div>

      <hr class="urla-break" />

      <!-- part 5b. About Your Finances -->
      <div>
        <h4>About Your Finances</h4>

        <!-- question F -->
        <div class="row">
          <p class="col-7 col-12-xsmall">
            <label style="display: inline" for="question-f">
              F.
              <span class="font-weight-normal"
                >Are you a co-signer or guarantor on any debt or loan that is
                not disclosed on this application?</span
              >
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
          </p>
          <fieldset class="urla-radio radio-inline col-5 col-12-xsmall">
            <ul>
              <li>
                <input
                  name="question-f"
                  type="radio"
                  id="question-f-no"
                  v-bind:value="false"
                  v-model="
                    section5Declarations.part5bAboutYourFinances.questionF
                  "
                  required
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-f-no"
                  >No</label
                >
              </li>
              <li>
                <input
                  name="question-f"
                  type="radio"
                  id="question-f-yes"
                  v-bind:value="true"
                  v-model="
                    section5Declarations.part5bAboutYourFinances.questionF
                  "
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-f-yes"
                  >Yes</label
                >
              </li>
            </ul>
          </fieldset>
        </div>

        <hr class="urla-break" />

        <!-- question G -->
        <div class="row">
          <p class="col-7 col-12-xsmall">
            <label style="display: inline" for="question-g">
              G.
              <span class="font-weight-normal"
                >Are there any outstanding judgements against you?</span
              >
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
          </p>
          <fieldset class="urla-radio radio-inline col-5 col-12-xsmall">
            <ul>
              <li>
                <input
                  name="question-g"
                  type="radio"
                  id="question-g-no"
                  v-bind:value="false"
                  v-model="
                    section5Declarations.part5bAboutYourFinances.questionG
                  "
                  required
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-g-no"
                  >No</label
                >
              </li>
              <li>
                <input
                  name="question-g"
                  type="radio"
                  id="question-g-yes"
                  v-bind:value="true"
                  v-model="
                    section5Declarations.part5bAboutYourFinances.questionG
                  "
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-g-yes"
                  >Yes</label
                >
              </li>
            </ul>
          </fieldset>
        </div>

        <hr class="urla-break" />

        <!-- question H -->
        <div class="row">
          <p class="col-7 col-12-xsmall">
            <label style="display: inline" for="question-h">
              H.
              <span class="font-weight-normal"
                >Are you currently delinquent or in default on a federal
                debt?</span
              >
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
          </p>
          <fieldset class="urla-radio radio-inline col-5 col-12-xsmall">
            <ul>
              <li>
                <input
                  name="question-h"
                  type="radio"
                  id="question-h-no"
                  v-bind:value="false"
                  v-model="
                    section5Declarations.part5bAboutYourFinances.questionH
                  "
                  required
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-h-no"
                  >No</label
                >
              </li>
              <li>
                <input
                  name="question-h"
                  type="radio"
                  id="question-h-yes"
                  v-bind:value="true"
                  v-model="
                    section5Declarations.part5bAboutYourFinances.questionH
                  "
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-h-yes"
                  >Yes</label
                >
              </li>
            </ul>
          </fieldset>
        </div>

        <hr class="urla-break" />

        <!-- question I -->
        <div class="row">
          <p class="col-7 col-12-xsmall">
            <label style="display: inline" for="question-i">
              I.
              <span class="font-weight-normal"
                >Are you a party to a lawsuit in which you potentially have any
                personal financial liability?</span
              >
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
          </p>
          <fieldset class="urla-radio radio-inline col-5 col-12-xsmall">
            <ul>
              <li>
                <input
                  name="question-i"
                  type="radio"
                  id="question-i-no"
                  v-bind:value="false"
                  v-model="
                    section5Declarations.part5bAboutYourFinances.questionI
                  "
                  required
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-i-no"
                  >No</label
                >
              </li>
              <li>
                <input
                  name="question-i"
                  type="radio"
                  id="question-i-yes"
                  v-bind:value="true"
                  v-model="
                    section5Declarations.part5bAboutYourFinances.questionI
                  "
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-i-yes"
                  >Yes</label
                >
              </li>
            </ul>
          </fieldset>
        </div>

        <hr class="urla-break" />

        <!-- question J -->
        <div class="row">
          <p class="col-7 col-12-xsmall">
            <label style="display: inline" for="question-j">
              J.
              <span class="font-weight-normal"
                >Have you conveyed title to any property in lieu of foreclosure
                in the past 7 years?</span
              >
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
          </p>
          <fieldset class="urla-radio radio-inline col-5 col-12-xsmall">
            <ul>
              <li>
                <input
                  name="question-j"
                  type="radio"
                  id="question-j-no"
                  v-bind:value="false"
                  v-model="
                    section5Declarations.part5bAboutYourFinances.questionJ
                  "
                  required
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-j-no"
                  >No</label
                >
              </li>
              <li>
                <input
                  name="question-j"
                  type="radio"
                  id="question-j-yes"
                  v-bind:value="true"
                  v-model="
                    section5Declarations.part5bAboutYourFinances.questionJ
                  "
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-j-yes"
                  >Yes</label
                >
              </li>
            </ul>
          </fieldset>
        </div>

        <hr class="urla-break" />

        <!-- question K -->
        <div class="row">
          <p class="col-7 col-12-xsmall">
            <label style="display: inline" for="question-k">
              K.
              <span class="font-weight-normal"
                >Within the past 7 years, have you completed a pre-foreclosure
                sale or short sale, whereby the property was sold to a third
                party and the Lender agreed to accept less than the outstanding
                mortgage balance due?</span
              >
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
          </p>
          <fieldset class="urla-radio radio-inline col-5 col-12-xsmall">
            <ul>
              <li>
                <input
                  name="question-k"
                  type="radio"
                  id="question-k-no"
                  v-bind:value="false"
                  v-model="
                    section5Declarations.part5bAboutYourFinances.questionK
                  "
                  required
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-k-no"
                  >No</label
                >
              </li>
              <li>
                <input
                  name="question-k"
                  type="radio"
                  id="question-k-yes"
                  v-bind:value="true"
                  v-model="
                    section5Declarations.part5bAboutYourFinances.questionK
                  "
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-k-yes"
                  >Yes</label
                >
              </li>
            </ul>
          </fieldset>
        </div>

        <hr class="urla-break" />

        <!-- question L -->
        <div class="row">
          <p class="col-7 col-12-xsmall">
            <label style="display: inline" for="question-l">
              L.
              <span class="font-weight-normal"
                >Have you had property foreclosed upon in the last 7
                years?</span
              >
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
          </p>
          <fieldset class="urla-radio radio-inline col-5 col-12-xsmall">
            <ul>
              <li>
                <input
                  name="question-l"
                  type="radio"
                  id="question-l-no"
                  v-bind:value="false"
                  v-model="
                    section5Declarations.part5bAboutYourFinances.questionL
                  "
                  required
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-l-no"
                  >No</label
                >
              </li>
              <li>
                <input
                  name="question-l"
                  type="radio"
                  id="question-l-yes"
                  v-bind:value="true"
                  v-model="
                    section5Declarations.part5bAboutYourFinances.questionL
                  "
                />
                <label
                  class="font-weight-normal small-label"
                  for="question-l-yes"
                  >Yes</label
                >
              </li>
            </ul>
          </fieldset>
        </div>

        <hr class="urla-break" />

        <!-- question M -->
        <div>
          <!-- answer 1 -->
          <div class="row">
            <p class="col-7 col-12-xsmall">
              <label style="display: inline" for="question-m">
                M.
                <span class="font-weight-normal"
                  >Have you declared bankruptcy within the past 7 years?</span
                >
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
            </p>
            <fieldset class="urla-radio radio-inline col-5 col-12-xsmall">
              <ul>
                <li>
                  <input
                    name="question-m"
                    type="radio"
                    id="question-m-no"
                    v-bind:value="false"
                    v-model="
                      section5Declarations.part5bAboutYourFinances.questionM
                        .answer1
                    "
                    required
                  />
                  <label
                    class="font-weight-normal small-label"
                    for="question-m-no"
                    >No</label
                  >
                </li>
                <li>
                  <input
                    name="question-m"
                    type="radio"
                    id="question-m-yes"
                    v-bind:value="true"
                    v-model="
                      section5Declarations.part5bAboutYourFinances.questionM
                        .answer1
                    "
                  />
                  <label
                    class="font-weight-normal small-label"
                    for="question-m-yes"
                    >Yes</label
                  >
                </li>
              </ul>
            </fieldset>
          </div>

          <!-- answer 2 -->
          <transition name="fade">
            <div
              v-if="
                section5Declarations.part5bAboutYourFinances.questionM.answer1
              "
              class="left-indent"
            >
              <fieldset class="checkbox-inline">
                <legend>
                  <span>Identify the type(s) of bankruptcy:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                  <validation-icon
                    v-bind:isValid="
                      validations.part5bAboutYourFinances.questionM.answer2
                    "
                  ></validation-icon>
                </legend>
                <ul>
                  <li>
                    <input
                      type="checkbox"
                      id="chapter-7"
                      value="Chapter 7"
                      v-model="
                        section5Declarations.part5bAboutYourFinances.questionM
                          .answer2
                      "
                      v-on:change="validateQuestionM2()"
                    />
                    <label class="medium-label" for="chapter-7"
                      >Chapter 7</label
                    >
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="chapter-11"
                      value="Chapter 11"
                      v-model="
                        section5Declarations.part5bAboutYourFinances.questionM
                          .answer2
                      "
                      v-on:change="validateQuestionM2()"
                    />
                    <label class="medium-label" for="chapter-11"
                      >Chapter 11</label
                    >
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="chapter-12"
                      value="Chapter 12"
                      v-model="
                        section5Declarations.part5bAboutYourFinances.questionM
                          .answer2
                      "
                      v-on:change="validateQuestionM2()"
                    />
                    <label class="medium-label" for="chapter-12"
                      >Chapter 12</label
                    >
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="chapter-13"
                      value="Chapter 13"
                      v-model="
                        section5Declarations.part5bAboutYourFinances.questionM
                          .answer2
                      "
                      v-on:change="validateQuestionM2()"
                    />
                    <label class="medium-label" for="chapter-13"
                      >Chapter 13</label
                    >
                  </li>
                </ul>
              </fieldset>
            </div>
          </transition>
        </div>
      </div>

      <!-- clearfix for back and save -->
      <div class="clearfix">
        <!-- back button -->
        <button
          type="button"
          class="primary wizard__back pull-left"
          @click.prevent="goBack()"
        >
          <span>
            <i class="fa fa-arrow-left fa-lg"></i>
            back
          </span>
        </button>
        <!-- save & continue button -->
        <button type="submit" class="primary wizard__next pull-right">
          <span>
            <i class="fa fa-floppy-o" aria-hidden="true"></i>
            save
            <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { bus } from "@/main";
import validationsMixin from "@/mixins/validationsMixin.js";
import inputMasksMixin from "@/mixins/inputMasksMixin.js";
import jsonUtilMixin from "@/mixins/jsonUtilMixin.js";

export default {
  name: "Section5",
  // date received from parent component
  props: ["sourceSection5"],
  // mixins used
  mixins: [validationsMixin, inputMasksMixin, jsonUtilMixin],
  data() {
    return {
      currencyFormat: {
        // input masking for currency
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 0,
      },

      section5Declarations: {
        loanApplicationProgress: 500,
        currentPage: 4,
        sectionName: "section5Declarations",
        part5aAboutThisProperty: {
          questionA: {
            answer1: null,
            answer2: null,
            answer3: null,
            answer4: null,
          },
          questionB: null,
          questionC: {
            answer1: null,
            answer2: null,
          },
          questionD: {
            answer1: null,
            answer2: null,
          },
          questionE: null,
        },
        part5bAboutYourFinances: {
          questionF: null,
          questionG: null,
          questionH: null,
          questionI: null,
          questionJ: null,
          questionK: null,
          questionL: null,
          questionM: {
            answer1: null,
            answer2: [],
          },
        },
      },
      validations: null,
      errors: null,
    };
  },

  methods: {
    goBack: function() {
      // emit event to go back with current page number
      bus.$emit("goBack", this.section5Declarations.currentPage);
    },

    submitSectionFive: function() {
      // validate form
      bus.$emit("postUrla", this.section5Declarations);
    },

    validateQuestionC2: function(event) {
      var errors = this.errors.part5aAboutThisProperty.questionC;
      var validations = this.validations.part5aAboutThisProperty.questionC;

      // validate price from validationsMixin
      errors.answer2 = this.checkPrice(event);

      // set validation-icon
      validations.answer2 = errors.answer2.length === 0 ? 1 : 0;
    },

    validateQuestionM2: function() {
      // clear array
      this.errors.part5bAboutYourFinances.questionM.answer2 = [];

      // check if array is empty
      if (
        this.section5Declarations.part5bAboutYourFinances.questionM.answer2
          .length === 0
      )
        this.errors.part5bAboutYourFinances.questionM.answer2.push(
          "please select an option"
        );

      // set validation-icon
      this.validations.part5bAboutYourFinances.questionM.answer2 =
        this.errors.part5bAboutYourFinances.questionM.answer2.length === 0
          ? 1
          : 0;
    },
  },

  mounted: function() {
    // set default values
    // default value for question A answer 3
    if (
      this.section5Declarations.part5aAboutThisProperty.questionA.answer3 ===
      null
    )
      this.section5Declarations.part5aAboutThisProperty.questionA.answer3 =
        "Primary Residence";

    // default value for question A answer 4
    if (
      this.section5Declarations.part5aAboutThisProperty.questionA.answer4 ===
      null
    )
      this.section5Declarations.part5aAboutThisProperty.questionA.answer4 =
        "by yourself";

    // scroll to top of page
    this.$scrollTo(this.$refs.sectionFive, {
      duration: 500,
      easing: "ease",
      offset: -200,
    });
  },

  created: function() {
    // copy data received from urla into current section
    this.section5Declarations = this.parseSection(
      this.section5Declarations,
      this.sourceSection5
    );

    // initialize validations object to null
    this.validations = this.initializeToNull(this.section5Declarations);

    // initialize errors object to empty array
    this.errors = this.initializeToEmptyArray(this.section5Declarations);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.urla-section {
  margin: 0 2rem 0 2rem;
}
</style>
