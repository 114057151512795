<template>
  <div>
    <header id="header">
      <!-- Logo -->
      <span class="logo">
        <router-link :to="{ name: 'home' }">
          <!--span class="image-logo">
            <img src="@/assets/images/logo-website.jpeg" alt="website logo" />
          </span-->
          American Smart Lending
        </router-link>
      </span>
      <!-- Nav -->
      <nav id="nav">
        <ul>
          <li>
            <router-link :to="{ name: 'home' }">Home</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'urla' }">Apply Now</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'upload' }">Upload</router-link>
          </li>
          <li
            class="link depth-0"
            ref="dropdown"
            @mouseover="expandDropdown"
            @mouseleave="collapseDropdown"
          >
            Services
            <span class="fa fa-angle-down" aria-hidden="true"></span>
          </li>
          <li>
            <router-link :to="{ name: 'faq' }">FAQ</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'contact' }">Contact</router-link>
          </li>
          <li
            class="link depth-0"
            v-on:click="$router.push({ name: 'view-reviews' })"
          >
            Reviews
          </li>
          <li>
            <router-link :to="{ name: 'success-stories' }">Success Stories</router-link>
          </li>
        </ul>
      </nav>
      <!-- click event to toggle NavPanel in mobile view-->
      <a v-on:click="isVisible = true" class="navPanelToggle" href="#navPanel"
        >Menu</a
      >
    </header>
    <div id="navPanel" v-bind:class="{ visible: isVisible }">
      <!--create nav list for mobile view-->
      <a v-on:click="isVisible = false" class="close" href="#navPanel"></a>
      <nav id="nav">
        <a
          class="link depth-0 nav-link"
          v-on:click="
            isVisible = false;
            $router.push({ name: 'home' });
          "
          >Home</a
        >
        <a class="link depth-0 nav-link">Services</a>
        <a
          class="link depth-1 nav-link"
          v-on:click="
            isVisible = false;
            $router.push({ name: 'amortization-calculator' });
          "
        >
          <span class="indent-1"></span>
          Amortization Calculator
        </a>
        <!--a
          class="link depth-1 nav-link"
          v-on:click="isVisible = false; $router.push({ name: 'quote'})"
        >
          <span class="indent-1"></span>
          Quick Quote
        </a-->
        <a
          class="link depth-1 nav-link"
          v-on:click="
            isVisible = false;
            $router.push({ name: 'upload' });
          "
        >
          <span class="indent-1"></span>
          Upload Files
        </a>
        <a
          class="link depth-1 nav-link"
          v-on:click="
            isVisible = false;
            $router.push({ name: 'new-or-continue' });
          "
        >
          <span class="indent-1"></span>
          Apply for Mortgage/Refinance
        </a>
        <a
          class="link depth-0 nav-link"
          v-on:click="
            isVisible = false;
            $router.push({ name: 'faq' });
          "
          >FAQ</a
        >
        <a
          class="link depth-0 nav-link"
          v-on:click="
            isVisible = false;
            $router.push({ name: 'contact' });
          "
          >Contact</a
        >
        <a
          class="link depth-0 nav-link"
          v-on:click="
            isVisible = false;
            $router.push({ name: 'view-reviews' });
          "
          >Reviews</a
        >
        <a
          class="link depth-0 nav-link"
          v-on:click="
            isVisible = false;
            $router.push({ name: 'success-stories' });
          "
          >Success Stories</a
        >
      </nav>
    </div>
    <transition name="fade" tag="ul">
      <ul
        @mouseover="expandDropdown"
        @mouseleave="collapseDropdown"
        v-show="dropdownVisible"
        ref="dropdownList"
        style="
          -moz-user-select: none;
          position: fixed;
          z-index: 1000;
          top: 76.8667px;
          opacity: 1;
          height: 13.75em;
        "
        class="dropotron level-0 right"
      >
        <li style="white-space: nowrap">
          <a
            class="link"
            v-on:click="$router.push({ name: 'amortization-calculator' })"
            style="display: block"
            >Amortization Calculator</a
          >
        </li>
        <li style="white-space: nowrap">
          <a
            class="link"
            v-on:click="$router.push({ name: 'upload' })"
            style="display: block"
            >Upload Files</a
          >
        </li>
        <!--li style="white-space: nowrap;">
          <a
            class="link"
            v-on:click="$router.push({ name: 'quote'})"
            style="display: block;"
          >Quick Quote</a>
        </li-->
        <li style="white-space: nowrap">
          <a
            class="link"
            v-on:click="$router.push({ name: 'new-or-continue' })"
            style="display: block"
            >Apply for Mortgage/Refinance</a
          >
        </li>
        <li style="white-space: nowrap">
          <a
            class="link"
            v-on:click="$router.push({ name: 'contact' })"
            style="display: block"
            >Licensing information</a
          >
        </li>
        <li style="white-space: nowrap">
          <a
            class="link"
            v-on:click="$router.push({ name: 'disclosure' })"
            style="display: block"
            >Important Disclosure</a
          >
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      isVisible: false,
      dropdownVisible: false,
      images: [require("@/assets/images/logo-website.jpeg")],
    };
  },
  methods: {
    expandDropdown: function() {
      this.$refs.dropdownList.style.left =
        this.$refs.dropdown.offsetLeft - 100 + "px";
      this.dropdownVisible = true;
    },
    collapseDropdown: function() {
      this.dropdownVisible = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#header > nav > ul > li {
  padding: 0 1em;
  margin: 0 0.5em;
}

#header > nav > ul > li a {
  padding: 0;
  margin: 0;
}

/* website logo image */
.image-logo {
  width: 40px;
  height: 40px;
  display: inline-block;
}

.image-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav-link {
  cursor: pointer;
}

.link {
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
