<template>
  <div ref="sectionOne" class="urla-section">
    <!-- Heading for Section 1 -->
    <h2>Borrower Information</h2>

    <!-- Section description -->
    <p class="urla-section-description">
      This section asks about your personal information and your income from
      employment and other sources, such as retirement, that you want considered
      to qualify for this loan.
    </p>

    <!-- Required fields instruction -->
    <p class="instruction">
      Required fields are followed by
      <strong> <abbr class="required" title="required">*</abbr> </strong>.
    </p>

    <!-- Form for section 1 of URL Application -->
    <form
      id="section-1-form"
      v-on:submit.prevent="submitSectionOne"
      method="post"
    >
      <!-- part 1a. Personal Information -->
      <div class="row">
        <!-- first, middle & last names, suffix -->
        <div class="col-4 col-12-xsmall">
          <!-- First name -->
          <p>
            <label class="small-label" for="first-name">
              <span>First Name:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <input
              class="medium-input"
              id="first-name"
              type="text"
              placeholder="First Name"
              v-model="
                section1BorrowerInformation.part1aPersonalInformation.firstName
              "
              v-on:input="validateFirstName"
              v-on:invalid="validations.part1aPersonalInformation.firstName = 0"
              required
            />
            <!-- Icon will be inserted below after validating the above input -->
            <validation-icon
              v-bind:isValid="validations.part1aPersonalInformation.firstName"
            ></validation-icon>
          </p>

          <!-- Middle name -->
          <p>
            <label for="middle-name" class="small-label">Middle Name:</label>
            <input
              class="medium-input"
              id="middle-name"
              type="text"
              placeholder="Middle Name"
              v-model="
                section1BorrowerInformation.part1aPersonalInformation.middleName
              "
              v-on:input="validateMiddleName"
            />
            <!-- Icon will be inserted below after validating the above input -->
            <validation-icon
              v-bind:isValid="validations.part1aPersonalInformation.middleName"
            ></validation-icon>
          </p>

          <!-- Last name -->
          <p>
            <label for="last-name" class="small-label">
              <span>Last Name:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <input
              class="medium-input"
              id="last-name"
              type="text"
              placeholder="Last Name"
              v-model="
                section1BorrowerInformation.part1aPersonalInformation.lastName
              "
              v-on:input="validateLastName"
              v-on:invalid="validations.part1aPersonalInformation.lastName = 0"
              required
            />
            <!-- Icon will be inserted below after validating the above input -->
            <validation-icon
              v-bind:isValid="validations.part1aPersonalInformation.lastName"
            ></validation-icon>
          </p>

          <!-- Suffix -->
          <p>
            <label for="suffix" class="small-label">Suffix:</label>
            <input
              class="medium-input"
              id="suffix"
              type="text"
              placeholder="Suffix"
              v-model="
                section1BorrowerInformation.part1aPersonalInformation.suffix
              "
              v-on:input="validateSuffix"
            />
            <!-- Icon will be inserted below after validating the above input -->
            <validation-icon
              v-bind:isValid="validations.part1aPersonalInformation.suffix"
            ></validation-icon>
          </p>
        </div>

        <!-- Cell phone, email, SSN, DoB -->
        <div class="col-4 col-12-xsmall">
          <!-- Cell Phone -->
          <p>
            <label for="cell-phone" class="small-label">
              <span>Cell Phone:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <input
              class="medium-input"
              id="cell-phone"
              type="tel"
              maxlength="15"
              minlength="15"
              v-mask="'+1(###)###-####'"
              placeholder="+1(123)456-7890"
              v-model="
                section1BorrowerInformation.part1aPersonalInformation.cellPhone
              "
              v-on:change="validateCellPhone"
              v-on:invalid="validations.part1aPersonalInformation.cellPhone = 0"
              required
            />
            <!-- An icon will be inserted below after the validating above input -->
            <validation-icon
              v-bind:isValid="validations.part1aPersonalInformation.cellPhone"
            ></validation-icon>
          </p>

          <!-- email address -->
          <p>
            <label for="email-address" class="small-label">
              <span>Email Address:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <input
              class="medium-input"
              id="email-address"
              type="email"
              placeholder="dan@gmail.com"
              v-model="
                section1BorrowerInformation.part1aPersonalInformation
                  .emailAddress
              "
              v-on:change="validateEmailAddress"
              v-on:invalid="
                validations.part1aPersonalInformation.emailAddress = 0
              "
              required
            />
            <!-- An icon will be inserted below after validating the above input -->
            <validation-icon
              v-bind:isValid="
                validations.part1aPersonalInformation.emailAddress
              "
            ></validation-icon>
          </p>

          <!-- Social Security Number -->
          <p>
            <label class="medium-label" for="social-security-number">
              <span>Social Security Number:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <input
              class="small-input"
              id="social-security-number"
              type="text"
              maxlength="11"
              minlength="11"
              v-mask="'###-##-####'"
              placeholder="xxx-xx-xxxx"
              v-model="
                section1BorrowerInformation.part1aPersonalInformation
                  .socialSecurityNumber
              "
              v-on:change="validateSsn"
              v-on:invalid="
                validations.part1aPersonalInformation.socialSecurityNumber = 0
              "
              required
            />
            <!-- Icon will be inserted below after validating the above input -->
            <validation-icon
              v-bind:isValid="
                validations.part1aPersonalInformation.socialSecurityNumber
              "
            ></validation-icon>
          </p>

          <!-- Date of Birth -->
          <p>
            <label for="date-of-birth" class="small-label">
              <span>Date of Birth:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <date-pick
              id="date-of-birth"
              class="date-pick medium-input"
              v-mask="'##/##/####'"
              v-bind:inputAttributes="{
                placeholder: 'MM/DD/YYYY',
                required: 'required',
              }"
              v-bind:isDateDisabled="isFutureDate"
              v-bind:displayFormat="'MM/DD/YYYY'"
              v-model="
                section1BorrowerInformation.part1aPersonalInformation
                  .dateOfBirth
              "
              v-on:input="validateDateOfBirth"
              v-on:invalid="
                validations.part1aPersonalInformation.dateOfBirth = 0
              "
            ></date-pick>
            <!-- An icon will be inserted below after validating the above input -->
            <validation-icon
              v-bind:isValid="validations.part1aPersonalInformation.dateOfBirth"
            ></validation-icon>
          </p>
        </div>

        <div class="col-4 col-12-xsmall">
          <!-- Marital Status -->
          <div>
            <p>
              <label class="urla-radio-label" for="marital-status">
                <span>Marital Status:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
            </p>
            <fieldset class="urla-radio">
              <ul>
                <li>
                  <input
                    name="marital-status"
                    type="radio"
                    id="married"
                    value="Married"
                    v-model="
                      section1BorrowerInformation.part1aPersonalInformation
                        .maritalStatus
                    "
                    required
                  />
                  <label class="long font-weight-normal" for="married"
                    >Married</label
                  >
                </li>
                <li>
                  <input
                    name="marital-status"
                    type="radio"
                    id="separated"
                    value="Separated"
                    v-model="
                      section1BorrowerInformation.part1aPersonalInformation
                        .maritalStatus
                    "
                  />
                  <label class="long font-weight-normal" for="separated"
                    >Separated</label
                  >
                </li>
                <li>
                  <input
                    name="marital-status"
                    type="radio"
                    id="unmarried"
                    value="Unmarried"
                    v-model="
                      section1BorrowerInformation.part1aPersonalInformation
                        .maritalStatus
                    "
                  />
                  <label class="long font-weight-normal" for="unmarried"
                    >Unmarried</label
                  >
                </li>
              </ul>
              <p style="padding-left: 1em">
                (Single, Divorced, Widowed, Civil Union, Domestic Partnership,
                Registered Reciprocal Beneficiary Relationship)
              </p>
            </fieldset>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- Citizenship -->
        <div class="col-4 col-12-xsmall urla-left-section">
          <p>
            <label class="urla-radio-label" for="citizenhip">
              <span>Citizenship:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
          </p>
          <fieldset class="urla-radio">
            <ul>
              <li>
                <input
                  name="citizenship"
                  type="radio"
                  id="us-citizen"
                  value="U.S. Citizen"
                  v-model="
                    section1BorrowerInformation.part1aPersonalInformation
                      .citizenship
                  "
                  required
                />
                <label class="long font-weight-normal" for="us-citizen"
                  >U.S. Citizen</label
                >
              </li>
              <li>
                <input
                  name="citizenship"
                  type="radio"
                  id="permanent-resident-alien"
                  value="Permanent Resident Alien"
                  v-model="
                    section1BorrowerInformation.part1aPersonalInformation
                      .citizenship
                  "
                />
                <label
                  class="long font-weight-normal"
                  for="permanent-resident-alien"
                  >Permanent Resident Alien</label
                >
              </li>
              <li>
                <input
                  name="citizenship"
                  type="radio"
                  id="non-permanent-resident-alien"
                  value="Non-Permanent Resident Alien"
                  v-model="
                    section1BorrowerInformation.part1aPersonalInformation
                      .citizenship
                  "
                />
                <label
                  class="long font-weight-normal"
                  for="non-permanent-resident-alien"
                  >Non-Permanent Resident Alien</label
                >
              </li>
            </ul>
          </fieldset>
        </div>

        <div
          class="col-4 col-12-xsmall"
          v-if="
            section1BorrowerInformation.part1aPersonalInformation
              .maritalStatus == 'Married' ||
              section1BorrowerInformation.part1aPersonalInformation
                .maritalStatus == 'Separated'
          "
        >
          <h6 class="spouse-information">Spouse Information:</h6>
          <!-- Spouse First name -->
          <p>
            <label class="small-label spouse-label" for="spouse-first-name">
              <span>First&nbsp;Name:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <input
              class="medium-input"
              id="spouse-first-name"
              type="text"
              placeholder="Spouse First Name"
              v-model="
                section1BorrowerInformation.part1aPersonalInformation.spouse
                  .firstName
              "
              v-on:input="validateSpouseFirstName"
              v-on:invalid="
                validations.part1aPersonalInformation.spouse.firstName = 0
              "
              required
            />
            <!-- Icon will be inserted below after validating the above input -->
            <validation-icon
              v-bind:isValid="
                validations.part1aPersonalInformation.spouse.firstName
              "
            ></validation-icon>
          </p>
          <!-- Spouse Last name -->
          <p>
            <label class="small-label spouse-label" for="spouse-last-name">
              <span>Last&nbsp;Name:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <input
              class="medium-input"
              id="spouse-last-name"
              type="text"
              placeholder="Spouse Last Name"
              v-model="
                section1BorrowerInformation.part1aPersonalInformation.spouse
                  .lastName
              "
              v-on:input="validateSpouseLastName"
              v-on:invalid="
                validations.part1aPersonalInformation.spouse.lastName = 0
              "
              required
            />
            <!-- Icon will be inserted below after validating the above input -->
            <validation-icon
              v-bind:isValid="
                validations.part1aPersonalInformation.spouse.lastName
              "
            ></validation-icon>
          </p>
          <!-- Spouse Email Address -->
          <p>
            <label class="small-label spouse-label" for="spouse-email">
              <span>Email:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <input
              class="medium-input"
              id="spouse-email"
              type="email"
              placeholder="Spouse Email"
              v-model="
                section1BorrowerInformation.part1aPersonalInformation.spouse
                  .emailAddress
              "
              v-on:input="validateSpouseEmailAddress"
              v-on:invalid="
                validations.part1aPersonalInformation.spouse.emailAddress = 0
              "
              required
            />
            <!-- Icon will be inserted below after validating the above input -->
            <validation-icon
              v-bind:isValid="
                validations.part1aPersonalInformation.spouse.emailAddress
              "
            ></validation-icon>
          </p>
        </div>

        <!-- Dependents -->
        <div class="col-4 col-12-xsmall urla-right-section">
          <!-- Number of Dependents -->
          <p>
            <label for="number-of-dependents">
              <span>Number of Dependents:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <input
              class="x-small-input"
              id="number-of-dependents"
              type="number"
              min="0"
              max="3"
              v-model.number="
                section1BorrowerInformation.part1aPersonalInformation
                  .numberOfDependents
              "
              v-on:input="validateNumberOfDependents"
              v-on:invalid="
                validations.part1aPersonalInformation.numberOfDependents = 0
              "
              required
            />
            <!-- Icon will be inserted below after validating the above input -->
            <validation-icon
              v-bind:isValid="
                validations.part1aPersonalInformation.numberOfDependents
              "
            ></validation-icon>
            <br />
            <span class="font-style-italic"
              >(not listed by another Borrower)</span
            >
          </p>

          <!-- Ages of Dependents -->
          <p>
            <label
              for="ages-of-dependents"
              v-if="
                section1BorrowerInformation.part1aPersonalInformation
                  .agesOfDependents.length !== 0
              "
            >
              <span>Ages of Dependents:</span>
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
            <transition-group name="list" tag="div" style="display: inline">
              <div
                class="list-item-transition inline-list"
                v-for="(age, index) in section1BorrowerInformation
                  .part1aPersonalInformation.agesOfDependents"
                v-bind:key="index + 1"
              >
                <input
                  style="margin-right: 2%"
                  class="x-small-input"
                  v-bind:id="'ages-of-dependents-' + index"
                  type="number"
                  v-model.number="
                    section1BorrowerInformation.part1aPersonalInformation
                      .agesOfDependents[index]
                  "
                  v-on:change="validateAgesOfDependents"
                  v-on:invalid="
                    validations.part1aPersonalInformation.agesOfDependents = 0
                  "
                  required
                />
              </div>
            </transition-group>
            <!-- Icon will be inserted below after validating the above input -->
            <validation-icon
              v-if="
                section1BorrowerInformation.part1aPersonalInformation
                  .agesOfDependents.length !== 0
              "
              v-bind:isValid="
                validations.part1aPersonalInformation.agesOfDependents
              "
            ></validation-icon>
          </p>
        </div>
      </div>

      <!-- List of Other Borrowers -->
      <div>
        <!-- Total nubmer of borrowers -->
        <p style="padding-bottom: 0">
          <label for="number-of-borrowers">
            <span>Total Number of Borrowers:</span>
            <strong>
              <abbr class="required" title="required">*</abbr>
            </strong>
          </label>
          <input
            class="x-small-input"
            id="number-of-borrowers"
            type="number"
            min="1"
            max="3"
            v-model.number="
              section1BorrowerInformation.part1aPersonalInformation
                .numberOfBorrowers
            "
            v-on:input="validateNumberOfBorrowers"
            v-on:invalid="
              validations.part1aPersonalInformation.numberOfBorrowers = 0
            "
            required
          />
          <!-- Icon will be inserted below after validating the above input -->
          <validation-icon
            v-bind:isValid="
              validations.part1aPersonalInformation.numberOfBorrowers
            "
          ></validation-icon>
        </p>

        <!-- Full Name of other Borrower -->
        <transition-group name="list" tag="div">
          <div
            class="list-item-transition row"
            v-for="(otherBorrower, index) in section1BorrowerInformation
              .part1aPersonalInformation.otherBorrowers"
            v-bind:key="index + 1"
          >
            <p
              class="col-12 col-12-xsmall font-weight-bold"
              style="padding-bottom: 0"
            >
              Borrower {{ index + 2 }} - Full Name:
            </p>
            <!-- First name -->
            <p class="col-3 col-12-xsmall">
              <label v-bind:for="'first-name-' + index" class="hidden-label">
                <span>First Name:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
              <input
                class="medium-input"
                v-bind:id="'first-name-' + index"
                type="text"
                placeholder="First Name"
                v-model="otherBorrower.firstName"
                v-on:input="validateFirstName($event, index)"
                v-on:invalid="
                  validations.part1aPersonalInformation.otherBorrowers[
                    index
                  ].firstName = 0
                "
                required
              />
              <!-- Icon will be inserted below after validating the above input -->
              <validation-icon
                v-bind:isValid="
                  validations.part1aPersonalInformation.otherBorrowers[index]
                    .firstName
                "
              ></validation-icon>
            </p>

            <!-- Middle name -->
            <p class="col-3 col-12-xsmall">
              <label v-bind:for="'middle-name-' + index" class="hidden-label"
                >Middle Name:</label
              >
              <input
                class="medium-input"
                v-bind:id="'middle-name-' + index"
                type="text"
                placeholder="Middle Name"
                v-model="otherBorrower.middleName"
                v-on:input="validateMiddleName($event, index)"
              />
              <!-- Icon will be inserted below after validating the above input -->
              <validation-icon
                v-bind:isValid="
                  validations.part1aPersonalInformation.otherBorrowers[index]
                    .middleName
                "
              ></validation-icon>
            </p>

            <!-- Last name -->
            <p class="col-3 col-12-xsmall">
              <label v-bind:for="'last-name-' + index" class="hidden-label">
                <span>Last Name:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
              <input
                class="medium-input"
                v-bind:id="'last-name-' + index"
                type="text"
                placeholder="Last Name"
                v-model="otherBorrower.lastName"
                v-on:input="validateLastName($event, index)"
                v-on:invalid="
                  validations.part1aPersonalInformation.otherBorrowers[
                    index
                  ].lastName = 0
                "
                required
              />
              <!-- Icon will be inserted below after validating the above input -->
              <validation-icon
                v-bind:isValid="
                  validations.part1aPersonalInformation.otherBorrowers[index]
                    .lastName
                "
              ></validation-icon>
            </p>

            <!-- Suffix -->
            <p class="col-3 col-12-xsmall">
              <label v-bind:for="'suffix-' + index" class="hidden-label"
                >Suffix:</label
              >
              <input
                class="small-input"
                id="suffix"
                type="text"
                placeholder="Suffix"
                v-model="otherBorrower.suffix"
                v-on:input="validateSuffix($event, index)"
              />
              <!-- Icon will be inserted below after validating the above input -->
              <validation-icon
                v-bind:isValid="
                  validations.part1aPersonalInformation.otherBorrowers[index]
                    .suffix
                "
              ></validation-icon>
            </p>
          </div>
        </transition-group>
      </div>

      <hr class="urla-break" />

      <!-- Addresses --->
      <div>
        <transition-group name="list" tag="div">
          <div
            class="list-item-transition row"
            v-for="(address, index) in section1BorrowerInformation
              .part1aPersonalInformation.addresses"
            v-bind:key="index + 1"
          >
            <div class="col-12 col-12-xsmall">
              <h4 v-if="index === 0">Current Address:</h4>
              <h4 v-else>Former Address:</h4>
            </div>
            <div class="urla-left-section col-6 col-12-xsmall">
              <!-- Street -->
              <p>
                <label v-bind:for="'street-' + index" class="small-label">
                  <span>Street:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  class="medium-input"
                  v-bind:id="'street-' + index"
                  type="text"
                  placeholder="Street"
                  v-model="address.street"
                  v-on:input="validateStreet($event, index)"
                  v-on:invalid="
                    validations.part1aPersonalInformation.addresses[
                      index
                    ].street = 0
                  "
                  required
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part1aPersonalInformation.addresses[index]
                      .street
                  "
                ></validation-icon>
              </p>

              <!-- Unit -->
              <p>
                <label v-bind:for="'unit-' + index" class="small-label"
                  >Unit:</label
                >
                <input
                  class="medium-input"
                  v-bind:id="'unit-' + index"
                  type="text"
                  placeholder="unit"
                  v-model="address.unit"
                  v-on:change="validateUnit($event, index)"
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part1aPersonalInformation.addresses[index].unit
                  "
                ></validation-icon>
              </p>

              <!-- zip -->
              <p>
                <label v-bind:for="'zip-' + index" class="small-label">
                  <span>Zip:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  class="medium-input"
                  v-bind:id="'zip-' + index"
                  type="text"
                  minlength="5"
                  maxlength="5"
                  placeholder="00000"
                  v-model="address.zip"
                  v-on:input="validateZip($event, index)"
                  v-on:invalid="
                    validations.part1aPersonalInformation.addresses[
                      index
                    ].zip = 0
                  "
                  required
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part1aPersonalInformation.addresses[index].zip
                  "
                ></validation-icon>
              </p>

              <!-- City -->
              <p>
                <label v-bind:for="'city-' + index" class="small-label">
                  <span>City:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  class="medium-input"
                  v-bind:id="'city-' + index"
                  type="text"
                  placeholder="City"
                  v-model="address.city"
                  v-on:input="validateCity($event, index)"
                  v-on:invalid="
                    validations.part1aPersonalInformation.addresses[
                      index
                    ].city = 0
                  "
                  required
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part1aPersonalInformation.addresses[index].city
                  "
                ></validation-icon>
              </p>

              <!-- State -->
              <p>
                <label v-bind:for="'state-' + index" class="small-label">
                  <span>State:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  class="medium-input"
                  v-bind:id="'state-' + index"
                  type="text"
                  placeholder="State"
                  v-model="address.state"
                  v-on:input="validateState($event, index)"
                  v-on:invalid="
                    validations.part1aPersonalInformation.addresses[
                      index
                    ].state = 0
                  "
                  required
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part1aPersonalInformation.addresses[index].state
                  "
                ></validation-icon>
              </p>

              <!-- State -->
              <p class="own-property">
                <input
                  class="medium-input"
                  v-bind:id="'status-own' + index"
                  type="radio"
                  :name="'address-status' + index"
                  :value="true"
                  v-model="address.isOwnProperty"
                  required
                />
                <label v-bind:for="'status-own' + index">
                  <span>Own</span>
                </label>

                <input
                  class="medium-input"
                  v-bind:id="'status-rental' + index"
                  type="radio"
                  :name="'address-status' + index"
                  :value="false"
                  v-model="address.isOwnProperty"
                  checked
                  required
                />
                <label v-bind:for="'status-rental' + index" class="small-label">
                  <span>Rental</span>
                </label>
              </p>
            </div>

            <div class="col-6 col-12-xsmall">
              <p class="font-weight-bold">
                How long have you been at this address?
              </p>
              <!-- Years at this address -->
              <p>
                <label
                  v-bind:for="'years-at-address-' + index"
                  class="small-label"
                >
                  <span>Years:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  min="0"
                  max="50"
                  class="x-small-input"
                  v-bind:id="'years-at-address-' + index"
                  type="number"
                  v-model.number="address.yearsAtAddress"
                  v-on:input="validateYearsAtAddress($event, index)"
                  v-on:invalid="
                    validations.part1aPersonalInformation.addresses[
                      index
                    ].yearsAtAddress = 0
                  "
                  required
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part1aPersonalInformation.addresses[index]
                      .yearsAtAddress
                  "
                ></validation-icon>
              </p>

              <!-- Months at this address -->
              <p>
                <label
                  v-bind:for="'months-at-address-' + index"
                  class="small-label"
                >
                  <span>Months:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  min="0"
                  max="11"
                  class="x-small-input"
                  v-bind:id="'months-at-address-' + index"
                  type="number"
                  v-model.number="address.monthsAtAddress"
                  v-on:input="validateMonthsAtAddress($event, index)"
                  v-on:invalid="
                    validations.part1aPersonalInformation.addresses[
                      index
                    ].monthsAtAddress = 0
                  "
                  required
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part1aPersonalInformation.addresses[index]
                      .monthsAtAddress
                  "
                ></validation-icon>
              </p>
            </div>
          </div>
        </transition-group>
      </div>

      <hr class="urla-break" />

      <!-- Current Employment -->
      <div>
        <h4>Current Employment</h4>
        <transition-group name="list" tag="div">
          <div
            class="list-item-transition row"
            v-for="(currentEmployment,
            index) in section1BorrowerInformation.part1bcCurrentEmployment"
            v-bind:key="index + 1"
          >
            <div class="urla-left-section col-6 col-12-xsmall">
              <!-- Employer Name -->
              <p>
                <label
                  v-bind:for="'current-employer-name-' + index"
                  class="medium-label"
                >
                  <span>Employer/Business Name:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  class="medium-input"
                  v-bind:id="'current-employer-name-' + index"
                  type="text"
                  placeholder="Organization Name"
                  v-model="currentEmployment.employerName"
                  v-on:input="
                    validateEmployerName($event, index, 'current-employment')
                  "
                  v-on:invalid="
                    validations.part1bcCurrentEmployment[index].employerName = 0
                  "
                  required
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part1bcCurrentEmployment[index].employerName
                  "
                ></validation-icon>
              </p>

              <!-- Employer Phone -->
              <p>
                <label
                  v-bind:for="'current-employer-phone-' + index"
                  class="medium-label"
                >
                  <span>Employer Phone:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  class="medium-input"
                  v-bind:id="'current-employer-phone-' + index"
                  type="tel"
                  maxlength="15"
                  minlength="15"
                  v-mask="'+1(###)###-####'"
                  placeholder="+1(123)456-7890"
                  v-model="currentEmployment.employerPhone"
                  v-on:change="
                    validateEmployerPhone($event, index, 'current-employment')
                  "
                  v-on:invalid="
                    validations.part1bcCurrentEmployment[
                      index
                    ].employerPhone = 0
                  "
                  required
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part1bcCurrentEmployment[index].employerPhone
                  "
                ></validation-icon>
              </p>

              <!-- Position -->
              <p>
                <label
                  v-bind:for="'current-position-' + index"
                  class="medium-label"
                >
                  <span>Position or Title:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  class="medium-input"
                  v-bind:id="'current-position-' + index"
                  type="text"
                  placeholder="Position"
                  v-model="currentEmployment.position"
                  v-on:input="
                    validatePosition($event, index, 'current-employment')
                  "
                  v-on:invalid="
                    validations.part1bcCurrentEmployment[index].position = 0
                  "
                  required
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part1bcCurrentEmployment[index].position
                  "
                ></validation-icon>
              </p>

              <!-- Start Date -->
              <p>
                <label
                  v-bind:for="'current-employment-start-date-' + index"
                  class="medium-label"
                >
                  <span>Start Date:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <date-pick
                  v-bind:id="'current-employment-start-date-' + index"
                  class="date-pick medium-input"
                  v-mask="'##/##/####'"
                  v-bind:inputAttributes="{
                    placeholder: 'MM/DD/YYYY',
                    required: 'required',
                  }"
                  v-bind:isDateDisabled="isFutureDate"
                  v-bind:displayFormat="'MM/DD/YYYY'"
                  v-model="currentEmployment.startDate"
                  v-on:input="validateCurrentEmploymentStartDate($event, index)"
                  v-on:invalid="
                    validations.part1bcCurrentEmployment[index].startDate = 0
                  "
                ></date-pick>
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part1bcCurrentEmployment[index].startDate
                  "
                ></validation-icon>
              </p>

              <!-- Employer Address -->
              <div>
                <h5>Employer Address:</h5>
                <!-- Street -->
                <p>
                  <label
                    class="medium-label"
                    v-bind:for="'current-employer-street-' + index"
                  >
                    <span>Street:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                  <input
                    class="medium-input"
                    v-bind:id="'current-employer-street-' + index"
                    type="text"
                    placeholder="Street"
                    v-model="currentEmployment.street"
                    v-on:input="
                      validateStreet($event, index, 'current-employment')
                    "
                    v-on:invalid="
                      validations.part1bcCurrentEmployment[index].street = 0
                    "
                    required
                  />
                  <!-- Icon will be inserted below after validating the above input -->
                  <validation-icon
                    v-bind:isValid="
                      validations.part1bcCurrentEmployment[index].street
                    "
                  ></validation-icon>
                </p>

                <!-- zip -->
                <p>
                  <label
                    class="medium-label"
                    v-bind:for="'current-employer-zip=' + index"
                  >
                    <span>Zip:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                  <input
                    class="medium-input"
                    v-bind:id="'current-employer-zip-' + index"
                    type="text"
                    minlength="5"
                    maxlength="5"
                    placeholder="00000"
                    v-model="currentEmployment.zip"
                    v-on:input="
                      validateZip($event, index, 'current-employment')
                    "
                    v-on:invalid="
                      validations.part1bcCurrentEmployment[index].zip = 0
                    "
                    required
                  />
                  <!-- Icon will be inserted below after validating the above input -->
                  <validation-icon
                    v-bind:isValid="
                      validations.part1bcCurrentEmployment[index].zip
                    "
                  ></validation-icon>
                </p>

                <!-- City -->
                <p>
                  <label
                    class="medium-label"
                    v-bind:for="'current-employer-city-' + index"
                  >
                    <span>City:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                  <input
                    class="medium-input"
                    v-bind:id="'current-employer-city-' + index"
                    type="text"
                    placeholder="City"
                    v-model="currentEmployment.city"
                    v-on:input="
                      validateCity($event, index, 'current-employment')
                    "
                    v-on:invalid="
                      validations.part1bcCurrentEmployment[index].city = 0
                    "
                    required
                  />
                  <!-- Icon will be inserted below after validating the above input -->
                  <validation-icon
                    v-bind:isValid="
                      validations.part1bcCurrentEmployment[index].city
                    "
                  ></validation-icon>
                </p>

                <!-- State -->
                <p>
                  <label
                    class="medium-label"
                    v-bind:for="'current-employer-state-' + index"
                  >
                    <span>State:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                  <input
                    class="medium-input"
                    v-bind:id="'current-employer-state-' + index"
                    type="text"
                    placeholder="State"
                    v-model="currentEmployment.state"
                    v-on:input="
                      validateState($event, index, 'current-employment')
                    "
                    v-on:invalid="
                      validations.part1bcCurrentEmployment[index].state = 0
                    "
                    required
                  />
                  <!-- Icon will be inserted below after validating the above input -->
                  <validation-icon
                    v-bind:isValid="
                      validations.part1bcCurrentEmployment[index].state
                    "
                  ></validation-icon>
                </p>
              </div>
            </div>

            <div class="urla-right-section col-6 col-12-xsmall">
              <!-- Self employed / Business Owner? -->
              <div>
                <p style="line-height: 35px; padding-bottom: 0">
                  <label
                    v-bind:for="'self-employed-business-owner-' + index"
                    style="display: inline"
                  >
                    <span>Are you Self-Employed or the Business Owner?</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                </p>
                <fieldset
                  class="urla-radio radio-inline"
                  style="display: inline"
                >
                  <ul>
                    <li>
                      <input
                        v-bind:name="'self-employed-business-owner-' + index"
                        type="radio"
                        v-bind:id="'self-employed-business-owner-no-' + index"
                        v-bind:value="false"
                        v-model="
                          currentEmployment.selfEmployment
                            .isSelfEmployedBusinessOwner
                        "
                        required
                      />
                      <label
                        class="font-weight-normal small-label"
                        v-bind:for="'self-employed-business-owner-no-' + index"
                        >No</label
                      >
                    </li>
                    <li>
                      <input
                        v-bind:name="'self-employed-business-owner-' + index"
                        type="radio"
                        v-bind:id="'self-employed-business-owner-yes-' + index"
                        v-bind:value="true"
                        v-model="
                          currentEmployment.selfEmployment
                            .isSelfEmployedBusinessOwner
                        "
                      />
                      <label
                        class="font-weight-normal small-label"
                        v-bind:for="'self-employed-business-owner-yes-' + index"
                        >Yes</label
                      >
                    </li>
                  </ul>
                </fieldset>
              </div>

              <transition name="fade">
                <div
                  v-if="
                    currentEmployment.selfEmployment.isSelfEmployedBusinessOwner
                  "
                >
                  <!-- ownership share more than 25%? -->
                  <div>
                    <p style="line-height: 35px; padding-bottom: 0">
                      <label
                        v-bind:for="
                          'ownership-share-more-than-twenty-five-' + index
                        "
                        style="display: inline"
                      >
                        <span
                          >Do you have an ownership share of 25% or more?</span
                        >
                        <strong>
                          <abbr class="required" title="required">*</abbr>
                        </strong>
                      </label>
                    </p>
                    <fieldset
                      class="urla-radio radio-inline"
                      style="display: inline"
                    >
                      <ul>
                        <li>
                          <input
                            v-bind:name="
                              'ownership-share-more-than-twenty-five-' + index
                            "
                            type="radio"
                            v-bind:id="
                              'ownership-share-more-than-twenty-five-no-' +
                                index
                            "
                            v-bind:value="false"
                            v-model="
                              currentEmployment.selfEmployment
                                .isOwnershipInCompanyMoreThanTwentyFivePercent
                            "
                            required
                          />
                          <label
                            class="font-weight-normal small-label"
                            v-bind:for="
                              'ownership-share-more-than-twenty-five-no-' +
                                index
                            "
                            >No</label
                          >
                        </li>
                        <li>
                          <input
                            v-bind:name="
                              'ownership-share-more-than-twenty-five-' + index
                            "
                            type="radio"
                            v-bind:id="
                              'ownership-share-more-than-twenty-five-yes-' +
                                index
                            "
                            v-bind:value="true"
                            v-model="
                              currentEmployment.selfEmployment
                                .isOwnershipInCompanyMoreThanTwentyFivePercent
                            "
                          />
                          <label
                            class="font-weight-normal small-label"
                            v-bind:for="
                              'ownership-share-more-than-twenty-five-yes-' +
                                index
                            "
                            >Yes</label
                          >
                        </li>
                      </ul>
                    </fieldset>
                  </div>

                  <!-- Self Employment Monthly Income -->
                  <p>
                    <label
                      v-bind:for="'self-employment-monthly-income-' + index"
                      class="medium-label"
                    >
                      <span>Monthly Income:</span>
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                    <input
                      class="small-input"
                      v-bind:id="'self-employment-monthly-income-' + index"
                      type="text"
                      maxlength="15"
                      v-model.lazy="
                        currentEmployment.selfEmployment
                          .selfEmploymentMonthlyIncome
                      "
                      v-money="
                        currentEmployment.selfEmployment
                          .selfEmploymentMonthlyIncome !== null
                          ? currencyFormat
                          : null
                      "
                      v-on:blur="validateCurrentMonthlyIncome($event, index)"
                      v-on:invalid="
                        validations.part1bcCurrentEmployment[
                          index
                        ].selfEmployment.selfEmploymentMonthlyIncome = 0
                      "
                      required
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      class="x-small-column"
                      v-bind:isValid="
                        validations.part1bcCurrentEmployment[index]
                          .selfEmployment.selfEmploymentMonthlyIncome
                      "
                    ></validation-icon>
                  </p>
                </div>
              </transition>

              <!-- Gross Monthly Income -->
              <transition name="fade">
                <div
                  v-if="
                    currentEmployment.selfEmployment
                      .isSelfEmployedBusinessOwner === false
                  "
                >
                  <hr class="urla-break" />
                  <h5>Gross Monthly Income:</h5>
                  <!-- Base Salary -->
                  <p>
                    <label
                      v-bind:for="'current-employment-base-' + index"
                      class="medium-label"
                    >
                      <span>Base:</span>
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                    <input
                      class="small-input"
                      v-bind:id="'current-employment-base-' + index"
                      type="text"
                      maxlength="15"
                      v-model.lazy="
                        currentEmployment.grossMonthlyIncome.baseSalary
                      "
                      v-money="
                        currentEmployment.grossMonthlyIncome.baseSalary !== null
                          ? currencyFormat
                          : null
                      "
                      v-on:blur="
                        validateCurrentEmploymentBaseSalary($event, index)
                      "
                      v-on:invalid="
                        validations.part1bcCurrentEmployment[
                          index
                        ].grossMonthlyIncome.baseSalary = 0
                      "
                      required
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      class="x-small-column"
                      v-bind:isValid="
                        validations.part1bcCurrentEmployment[index]
                          .grossMonthlyIncome.baseSalary
                      "
                    ></validation-icon>
                  </p>

                  <!-- Overtime -->
                  <p>
                    <label
                      v-bind:for="'current-employment-overtime-' + index"
                      class="medium-label"
                      >Overtime:</label
                    >
                    <input
                      class="small-input"
                      v-bind:id="'current-employment-overtime-' + index"
                      type="text"
                      maxlength="15"
                      v-model.lazy="
                        currentEmployment.grossMonthlyIncome.overtime
                      "
                      v-money="
                        currentEmployment.grossMonthlyIncome.overtime !== null
                          ? currencyFormat
                          : null
                      "
                      v-on:blur="
                        validateCurrentEmploymentOvertime($event, index)
                      "
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      class="x-small-column"
                      v-bind:isValid="
                        validations.part1bcCurrentEmployment[index]
                          .grossMonthlyIncome.overtime
                      "
                    ></validation-icon>
                  </p>

                  <!-- Bonus -->
                  <p>
                    <label
                      v-bind:for="'current-employment-bonus-' + index"
                      class="medium-label"
                      >Bonus:</label
                    >
                    <input
                      class="small-input"
                      v-bind:id="'current-employment-bonus-' + index"
                      type="text"
                      maxlength="15"
                      v-model.lazy="currentEmployment.grossMonthlyIncome.bonus"
                      v-money="
                        currentEmployment.grossMonthlyIncome.bonus !== null
                          ? currencyFormat
                          : null
                      "
                      v-on:blur="validateCurrentEmploymentBonus($event, index)"
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      class="x-small-column"
                      v-bind:isValid="
                        validations.part1bcCurrentEmployment[index]
                          .grossMonthlyIncome.bonus
                      "
                    ></validation-icon>
                  </p>

                  <!-- Commission -->
                  <p>
                    <label
                      v-bind:for="'current-employment-base-' + index"
                      class="medium-label"
                      >Commission:</label
                    >
                    <input
                      class="small-input"
                      v-bind:id="'current-employment-base-' + index"
                      type="text"
                      maxlength="15"
                      v-model.lazy="
                        currentEmployment.grossMonthlyIncome.commission
                      "
                      v-money="
                        currentEmployment.grossMonthlyIncome.commission !== null
                          ? currencyFormat
                          : null
                      "
                      v-on:blur="
                        validateCurrentEmploymentCommission($event, index)
                      "
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      class="x-small-column"
                      v-bind:isValid="
                        validations.part1bcCurrentEmployment[index]
                          .grossMonthlyIncome.commission
                      "
                    ></validation-icon>
                  </p>

                  <!-- Military Entitlements -->
                  <p>
                    <label
                      v-bind:for="'current-employment-base-' + index"
                      class="medium-label"
                      >Military Entitlements:</label
                    >
                    <input
                      class="small-input"
                      v-bind:id="'current-employment-base-' + index"
                      type="text"
                      maxlength="15"
                      v-model.lazy="
                        currentEmployment.grossMonthlyIncome
                          .militaryEntitlements
                      "
                      v-money="
                        currentEmployment.grossMonthlyIncome
                          .militaryEntitlements !== null
                          ? currencyFormat
                          : null
                      "
                      v-on:blur="
                        validateCurrentEmploymentMilitaryEntitlements(
                          $event,
                          index
                        )
                      "
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      class="x-small-column"
                      v-bind:isValid="
                        validations.part1bcCurrentEmployment[index]
                          .grossMonthlyIncome.militaryEntitlements
                      "
                    ></validation-icon>
                  </p>

                  <!-- Other -->
                  <p>
                    <label
                      v-bind:for="'current-employment-base-' + index"
                      class="medium-label"
                      >Other:</label
                    >
                    <input
                      class="small-input"
                      v-bind:id="'current-employment-base-' + index"
                      type="text"
                      maxlength="15"
                      v-model.lazy="currentEmployment.grossMonthlyIncome.other"
                      v-money="
                        currentEmployment.grossMonthlyIncome.other !== null
                          ? currencyFormat
                          : null
                      "
                      v-on:blur="validateCurrentEmploymentOther($event, index)"
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      class="x-small-column"
                      v-bind:isValid="
                        validations.part1bcCurrentEmployment[index]
                          .grossMonthlyIncome.other
                      "
                    ></validation-icon>
                  </p>
                </div>
              </transition>
            </div>
          </div>
        </transition-group>
      </div>

      <!-- Previous Employment -->
      <div>
        <div
          v-if="
            section1BorrowerInformation.part1dPreviousEmployment.length !== 0
          "
        ></div>
        <transition-group name="list" tag="div">
          <div
            class="list-item-transition row"
            v-for="(previousEmployment,
            index) in section1BorrowerInformation.part1dPreviousEmployment"
            v-bind:key="index + 1"
          >
            <div class="col-12 col-12-xsmall">
              <hr class="urla-break" />
              <h4>Previous Employment</h4>
            </div>
            <div class="urla-left-section col-6 col-12-xsmall">
              <!-- Employer Name -->
              <p>
                <label
                  v-bind:for="'previous-employer-name-' + index"
                  class="medium-label"
                >
                  <span>Employer/Business Name:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  class="medium-input"
                  v-bind:id="'previous-employer-name-' + index"
                  type="text"
                  placeholder="Orgazination Name"
                  v-model="previousEmployment.employerName"
                  v-on:input="
                    validateEmployerName($event, index, 'previous-employment')
                  "
                  v-on:invalid="
                    validations.part1dPreviousEmployment[index].employerName = 0
                  "
                  required
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part1dPreviousEmployment[index].employerName
                  "
                ></validation-icon>
              </p>

              <!-- Employer Phone -->
              <p>
                <label
                  v-bind:for="'previous-employer-phone-' + index"
                  class="medium-label"
                >
                  <span>Employer Phone:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  class="medium-input"
                  v-bind:id="'previous-employer-phone-' + index"
                  type="tel"
                  maxlength="15"
                  minlength="15"
                  v-mask="'+1(###)###-####'"
                  placeholder="+1(123)456-7890"
                  v-model="previousEmployment.employerPhone"
                  v-on:change="
                    validateEmployerPhone($event, index, 'previous-employment')
                  "
                  v-on:invalid="
                    validations.part1dPreviousEmployment[
                      index
                    ].employerPhone = 0
                  "
                  required
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part1dPreviousEmployment[index].employerPhone
                  "
                ></validation-icon>
              </p>
              <!-- Position -->
              <p>
                <label
                  v-bind:for="'previous-position-' + index"
                  class="medium-label"
                >
                  <span>Position or Title:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  class="medium-input"
                  v-bind:id="'previous-position-' + index"
                  type="text"
                  placeholder="Position"
                  v-model="previousEmployment.position"
                  v-on:input="
                    validatePosition($event, index, 'previous-employment')
                  "
                  v-on:invalid="
                    validations.part1dPreviousEmployment[index].position = 0
                  "
                  required
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part1dPreviousEmployment[index].position
                  "
                ></validation-icon>
              </p>

              <!-- Start Date -->
              <p>
                <label
                  v-bind:for="'previous-employment-start-date-' + index"
                  class="medium-label"
                >
                  <span>Start Date:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <date-pick
                  v-bind:id="'previous-employment-start-date-' + index"
                  class="date-pick medium-input"
                  v-mask="'##/##/####'"
                  v-bind:inputAttributes="{
                    placeholder: 'MM/DD/YYYY',
                    required: 'required',
                  }"
                  v-bind:isDateDisabled="isFutureDate"
                  v-bind:displayFormat="'MM/DD/YYYY'"
                  v-model="previousEmployment.startDate"
                  v-on:input="
                    validatePreviousEmploymentStartDate($event, index)
                  "
                  v-on:invalid="
                    validations.part1dPreviousEmployment[index].startDate = 0
                  "
                ></date-pick>
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part1dPreviousEmployment[index].startDate
                  "
                ></validation-icon>
              </p>

              <!-- End Date -->
              <p>
                <label
                  v-bind:for="'previous-employment-end-date-' + index"
                  class="medium-label"
                >
                  <span>End Date:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <date-pick
                  v-bind:id="'previous-employment-end-date-' + index"
                  class="date-pick medium-input"
                  v-mask="'##/##/####'"
                  v-bind:inputAttributes="{
                    placeholder: 'MM/DD/YYYY',
                    required: 'required',
                  }"
                  v-bind:isDateDisabled="isFutureDate"
                  v-bind:displayFormat="'MM/DD/YYYY'"
                  v-model="previousEmployment.endDate"
                  v-on:input="validateEmploymentEndDate($event, index)"
                  v-on:invalid="
                    validations.part1dPreviousEmployment[index].endDate = 0
                  "
                ></date-pick>
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.part1dPreviousEmployment[index].endDate
                  "
                ></validation-icon>
              </p>

              <div>
                <h5>Employer Address:</h5>
                <!-- Street -->
                <p>
                  <label
                    class="medium-label"
                    v-bind:for="'previous-employer-street-' + index"
                  >
                    <span>Street:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                  <input
                    class="medium-input"
                    v-bind:id="'previous-employer-street-' + index"
                    type="text"
                    placeholder="Street"
                    v-model="previousEmployment.street"
                    v-on:input="
                      validateStreet($event, index, 'previous-employment')
                    "
                    v-on:invalid="
                      validations.part1dPreviousEmployment[index].street = 0
                    "
                    required
                  />
                  <!-- Icon will be inserted below after validating the above input -->
                  <validation-icon
                    v-bind:isValid="
                      validations.part1dPreviousEmployment[index].street
                    "
                  ></validation-icon>
                </p>

                <!-- zip -->
                <p>
                  <label
                    class="medium-label"
                    v-bind:for="'previous-employer-zip=' + index"
                  >
                    <span>Zip:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                  <input
                    class="medium-input"
                    v-bind:id="'previous-employer-zip-' + index"
                    type="text"
                    minlength="5"
                    maxlength="5"
                    placeholder="00000"
                    v-model="previousEmployment.zip"
                    v-on:input="
                      validateZip($event, index, 'previous-employment')
                    "
                    v-on:invalid="
                      validations.part1dPreviousEmployment[index].zip = 0
                    "
                    required
                  />
                  <!-- Icon will be inserted below after validating the above input -->
                  <validation-icon
                    v-bind:isValid="
                      validations.part1dPreviousEmployment[index].zip
                    "
                  ></validation-icon>
                </p>

                <!-- City -->
                <p>
                  <label
                    class="medium-label"
                    v-bind:for="'previous-employer-city-' + index"
                  >
                    <span>City:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                  <input
                    class="medium-input"
                    v-bind:id="'previous-employer-city-' + index"
                    type="text"
                    placeholder="City"
                    v-model="previousEmployment.city"
                    v-on:input="
                      validateCity($event, index, 'previous-employment')
                    "
                    v-on:invalid="
                      validations.part1dPreviousEmployment[index].city = 0
                    "
                    required
                  />
                  <!-- Icon will be inserted below after validating the above input -->
                  <validation-icon
                    v-bind:isValid="
                      validations.part1dPreviousEmployment[index].city
                    "
                  ></validation-icon>
                </p>

                <!-- State -->
                <p>
                  <label
                    class="medium-label"
                    v-bind:for="'previous-employer-state-' + index"
                  >
                    <span>State:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                  <input
                    class="medium-input"
                    v-bind:id="'previous-employer-state-' + index"
                    type="text"
                    placeholder="State"
                    v-model="previousEmployment.state"
                    v-on:input="
                      validateState($event, index, 'previous-employment')
                    "
                    v-on:invalid="
                      validations.part1dPreviousEmployment[index].state = 0
                    "
                    required
                  />
                  <!-- Icon will be inserted below after validating the above input -->
                  <validation-icon
                    v-bind:isValid="
                      validations.part1dPreviousEmployment[index].state
                    "
                  ></validation-icon>
                </p>
              </div>
            </div>

            <div class="urla-right-section col-6 col-12-xsmall">
              <!-- Self employed / Business Owner? -->
              <div>
                <p style="line-height: 35px; padding-bottom: 0">
                  <label
                    v-bind:for="
                      'previous-self-employed-business-owner-' + index
                    "
                    style="display: inline"
                  >
                    <span>Are you Self-Employed or the Business Owner?</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                </p>
                <fieldset
                  class="urla-radio radio-inline"
                  style="display: inline"
                >
                  <ul>
                    <li>
                      <input
                        v-bind:name="
                          'previous-self-employed-business-owner-' + index
                        "
                        type="radio"
                        v-bind:id="
                          'previous-self-employed-business-owner-no-' + index
                        "
                        v-bind:value="false"
                        v-model="
                          previousEmployment.selfEmployment
                            .isSelfEmployedBusinessOwner
                        "
                        required
                      />
                      <label
                        class="font-weight-normal small-label"
                        v-bind:for="
                          'previous-self-employed-business-owner-no-' + index
                        "
                        >No</label
                      >
                    </li>
                    <li>
                      <input
                        v-bind:name="
                          'previous-self-employed-business-owner-' + index
                        "
                        type="radio"
                        v-bind:id="
                          'previous-self-employed-business-owner-yes-' + index
                        "
                        v-bind:value="true"
                        v-model="
                          previousEmployment.selfEmployment
                            .isSelfEmployedBusinessOwner
                        "
                      />
                      <label
                        class="font-weight-normal small-label"
                        v-bind:for="
                          'previous-self-employed-business-owner-yes-' + index
                        "
                        >Yes</label
                      >
                    </li>
                  </ul>
                </fieldset>
              </div>

              <transition name="fade">
                <div
                  v-if="
                    previousEmployment.selfEmployment
                      .isSelfEmployedBusinessOwner
                  "
                >
                  <!-- ownership share more than 25%? -->
                  <div>
                    <p style="line-height: 35px; padding-bottom: 0">
                      <label
                        v-bind:for="
                          'previous-ownership-share-more-than-twenty-five-' +
                            index
                        "
                        style="display: inline"
                      >
                        <span
                          >Do you have an ownership share of 25% or more?</span
                        >
                        <strong>
                          <abbr class="required" title="required">*</abbr>
                        </strong>
                      </label>
                    </p>
                    <fieldset
                      class="urla-radio radio-inline"
                      style="display: inline"
                    >
                      <ul>
                        <li>
                          <input
                            v-bind:name="
                              'previous-ownership-share-more-than-twenty-five-' +
                                index
                            "
                            type="radio"
                            v-bind:id="
                              'previous-ownership-share-more-than-twenty-five-no-' +
                                index
                            "
                            v-bind:value="false"
                            v-model="
                              previousEmployment.selfEmployment
                                .isOwnershipInCompanyMoreThanTwentyFivePercent
                            "
                            required
                          />
                          <label
                            class="font-weight-normal small-label"
                            v-bind:for="
                              'previous-ownership-share-more-than-twenty-five-no-' +
                                index
                            "
                            >No</label
                          >
                        </li>
                        <li>
                          <input
                            v-bind:name="
                              'previous-ownership-share-more-than-twenty-five-' +
                                index
                            "
                            type="radio"
                            v-bind:id="
                              'previous-ownership-share-more-than-twenty-five-yes-' +
                                index
                            "
                            v-bind:value="true"
                            v-model="
                              previousEmployment.selfEmployment
                                .isOwnershipInCompanyMoreThanTwentyFivePercent
                            "
                          />
                          <label
                            class="font-weight-normal small-label"
                            v-bind:for="
                              'previous-ownership-share-more-than-twenty-five-yes-' +
                                index
                            "
                            >Yes</label
                          >
                        </li>
                      </ul>
                    </fieldset>
                  </div>

                  <!-- Self Employment Monthly Income -->
                  <p>
                    <label
                      v-bind:for="
                        'previous-self-employment-monthly-income-' + index
                      "
                      class="medium-label"
                    >
                      <span>Monthly Income:</span>
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                    <input
                      class="small-input"
                      v-bind:id="
                        'previous-self-employment-monthly-income-' + index
                      "
                      type="text"
                      maxlength="15"
                      v-model.lazy="
                        previousEmployment.selfEmployment
                          .selfEmploymentMonthlyIncome
                      "
                      v-money="
                        previousEmployment.selfEmployment
                          .selfEmploymentMonthlyIncome !== null
                          ? currencyFormat
                          : null
                      "
                      v-on:blur="validatePreviousMonthlyIncome($event, index)"
                      v-on:invalid="
                        validations.part1dPreviousEmployment[
                          index
                        ].selfEmployment.selfEmploymentMonthlyIncome = 0
                      "
                      required
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      class="x-small-column"
                      v-bind:isValid="
                        validations.part1dPreviousEmployment[index]
                          .selfEmployment.selfEmploymentMonthlyIncome
                      "
                    ></validation-icon>
                  </p>
                </div>
              </transition>

              <!-- Gross Monthly Income -->
              <transition name="fade">
                <div
                  v-if="
                    previousEmployment.selfEmployment
                      .isSelfEmployedBusinessOwner === false
                  "
                >
                  <hr class="urla-break" />
                  <h5>Gross Monthly Income:</h5>
                  <!-- Base Salary -->
                  <p>
                    <label
                      v-bind:for="'previous-employment-base-' + index"
                      class="medium-label"
                    >
                      <span>Base:</span>
                      <strong>
                        <abbr class="required" title="required">*</abbr>
                      </strong>
                    </label>
                    <input
                      class="small-input"
                      v-bind:id="'previous-employment-base-' + index"
                      type="text"
                      maxlength="15"
                      v-model.lazy="
                        previousEmployment.grossMonthlyIncome.baseSalary
                      "
                      v-money="
                        previousEmployment.grossMonthlyIncome.baseSalary !==
                        null
                          ? currencyFormat
                          : null
                      "
                      v-on:blur="
                        validatePreviousEmploymentBaseSalary($event, index)
                      "
                      v-on:invalid="
                        validations.part1dPreviousEmployment[
                          index
                        ].grossMonthlyIncome.baseSalary = 0
                      "
                      required
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      class="x-small-column"
                      v-bind:isValid="
                        validations.part1dPreviousEmployment[index]
                          .grossMonthlyIncome.baseSalary
                      "
                    ></validation-icon>
                  </p>

                  <!-- Overtime -->
                  <p>
                    <label
                      v-bind:for="'previous-employment-overtime-' + index"
                      class="medium-label"
                      >Overtime:</label
                    >
                    <input
                      class="small-input"
                      v-bind:id="'previous-employment-overtime-' + index"
                      type="text"
                      maxlength="15"
                      v-model.lazy="
                        previousEmployment.grossMonthlyIncome.overtime
                      "
                      v-money="
                        previousEmployment.grossMonthlyIncome.overtime !== null
                          ? currencyFormat
                          : null
                      "
                      v-on:blur="
                        validatePreviousEmploymentOvertime($event, index)
                      "
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      class="x-small-column"
                      v-bind:isValid="
                        validations.part1dPreviousEmployment[index]
                          .grossMonthlyIncome.overtime
                      "
                    ></validation-icon>
                  </p>

                  <!-- Bonus -->
                  <p>
                    <label
                      v-bind:for="'previous-employment-bonus-' + index"
                      class="medium-label"
                      >Bonus:</label
                    >
                    <input
                      class="small-input"
                      v-bind:id="'previous-employment-bonus-' + index"
                      type="text"
                      maxlength="15"
                      v-model.lazy="previousEmployment.grossMonthlyIncome.bonus"
                      v-money="
                        previousEmployment.grossMonthlyIncome.bonus !== null
                          ? currencyFormat
                          : null
                      "
                      v-on:blur="validatePreviousEmploymentBonus($event, index)"
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      class="x-small-column"
                      v-bind:isValid="
                        validations.part1dPreviousEmployment[index]
                          .grossMonthlyIncome.bonus
                      "
                    ></validation-icon>
                  </p>

                  <!-- Commission -->
                  <p>
                    <label
                      v-bind:for="'previous-employment-base-' + index"
                      class="medium-label"
                      >Commission:</label
                    >
                    <input
                      class="small-input"
                      v-bind:id="'previous-employment-base-' + index"
                      type="text"
                      maxlength="15"
                      v-model.lazy="
                        previousEmployment.grossMonthlyIncome.commission
                      "
                      v-money="
                        previousEmployment.grossMonthlyIncome.commission !==
                        null
                          ? currencyFormat
                          : null
                      "
                      v-on:blur="
                        validatePreviousEmploymentCommission($event, index)
                      "
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      class="x-small-column"
                      v-bind:isValid="
                        validations.part1dPreviousEmployment[index]
                          .grossMonthlyIncome.commission
                      "
                    ></validation-icon>
                  </p>

                  <!-- Military Entitlements -->
                  <p>
                    <label
                      v-bind:for="'previous-employment-base-' + index"
                      class="medium-label"
                      >Military Entitlements:</label
                    >
                    <input
                      class="small-input"
                      v-bind:id="'previous-employment-base-' + index"
                      type="text"
                      maxlength="15"
                      v-model.lazy="
                        previousEmployment.grossMonthlyIncome
                          .militaryEntitlements
                      "
                      v-money="
                        previousEmployment.grossMonthlyIncome
                          .militaryEntitlements !== null
                          ? currencyFormat
                          : null
                      "
                      v-on:blur="
                        validatePreviousEmploymentMilitaryEntitlements(
                          $event,
                          index
                        )
                      "
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      class="x-small-column"
                      v-bind:isValid="
                        validations.part1dPreviousEmployment[index]
                          .grossMonthlyIncome.militaryEntitlements
                      "
                    ></validation-icon>
                  </p>

                  <!-- Other -->
                  <p>
                    <label
                      v-bind:for="'previous-employment-base-' + index"
                      class="medium-label"
                      >Other:</label
                    >
                    <input
                      class="small-input"
                      v-bind:id="'previous-employment-base-' + index"
                      type="text"
                      maxlength="15"
                      v-model.lazy="previousEmployment.grossMonthlyIncome.other"
                      v-money="
                        previousEmployment.grossMonthlyIncome.other !== null
                          ? currencyFormat
                          : null
                      "
                      v-on:blur="validatePreviousEmploymentOther($event, index)"
                    />
                    <!-- Icon will be inserted below after validating the above input -->
                    <validation-icon
                      class="x-small-column"
                      v-bind:isValid="
                        validations.part1dPreviousEmployment[index]
                          .grossMonthlyIncome.other
                      "
                    ></validation-icon>
                  </p>
                </div>
              </transition>
            </div>
          </div>
        </transition-group>
      </div>

      <!-- clearfix for back and save -->
      <div class="clearfix">
        <!-- save & continue button -->
        <button type="submit" class="primary wizard__next pull-right">
          <span>
            <i class="fa fa-floppy-o" aria-hidden="true"></i>
            save
            <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { bus } from "@/main";
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";
import validationsMixin from "@/mixins/validationsMixin.js";
import inputMasksMixin from "@/mixins/inputMasksMixin.js";
import jsonUtilMixin from "@/mixins/jsonUtilMixin.js";
import zipCodeMixin from "@/mixins/zipCodeMixin.js";

export default {
  // component name
  name: "Section1",
  // components used
  components: { "date-pick": DatePick },
  // date received from parent component
  props: ["sourceSection1"],
  // mixins used
  mixins: [validationsMixin, inputMasksMixin, jsonUtilMixin, zipCodeMixin],
  data() {
    return {
      minDateOfBirth: null,
      maxDateOfBirth: null,
      currentDate: null,
      currencyFormat: {
        // input masking for currency
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 0,
      },
      section1BorrowerInformation: {
        loanApplicationProgress: 100,
        currentPage: 0,
        sectionName: "section1BorrowerInformation",
        part1aPersonalInformation: {
          firstName: null,
          middleName: null,
          lastName: null,
          suffix: null,
          alternateNames: null,
          socialSecurityNumber: null,
          dateOfBirth: null,
          citizenship: null,
          typeOfCredit: "individual credit",
          numberOfBorrowers: null,
          otherBorrowers: [],
          maritalStatus: null,
          numberOfDependents: null,
          agesOfDependents: [],
          homePhone: null,
          cellPhone: null,
          workPhone: null,
          workPhoneExt: null,
          emailAddress: null,
          addresses: [
            {
              addressType: "Current Address",
              startDate: null,
              endDate: null,
              chronologicalOrder: null,
              street: null,
              unit: null,
              city: null,
              state: null,
              zip: null,
              country: "USA",
              monthsAtAddress: null,
              yearsAtAddress: null,
              housingType: null,
              housingMonthlyExpense: null,
              isOwnProperty: null,
            },
          ],
          isInMilitaryService: null,
          isCurrentlyInActiveDuty: null,
          projectedExpirationDate: null,
          isCurrentlyRetired: null,
          isNonActivatedMember: null,
          isSurvivingSpouse: null,
          languagePreference: null,
          spouse: {
            emailAddress: null,
            firstName: null,
            lastName: null,
          },
        },
        part1bcCurrentEmployment: [
          {
            employerName: null,
            employerPhone: null,
            startDate: null,
            endDate: null,
            chronologicalOrder: null,
            street: null,
            city: null,
            state: null,
            zip: null,
            country: "USA",
            monthsAtJob: null,
            yearsAtJob: null,
            position: null,
            selfEmployment: {
              isEmployedByFamily: null,
              isSelfEmployedBusinessOwner: null,
              isOwnershipInCompanyMoreThanTwentyFivePercent: null,
              selfEmploymentMonthlyIncome: null,
            },
            grossMonthlyIncome: {
              baseSalary: null,
              overtime: "$0",
              bonus: "$0",
              commission: "$0",
              militaryEntitlements: "$0",
              other: "$0",
              total: null,
            },
          },
        ],
        part1dPreviousEmployment: [],
      },
      validations: null,
      errors: null,
    };
  },
  
  methods: {
    updateOtherBorrowersSize: function() {
      // number of other borrowers specified
      const num =
        this.section1BorrowerInformation.part1aPersonalInformation
          .numberOfBorrowers - 1;

      // length of other borrowers array
      const len = this.section1BorrowerInformation.part1aPersonalInformation
        .otherBorrowers.length;

      // return if number of borrowers and length is same
      if (num === len) return;

      if (num < len) {
        // splice the array if the length of array is greater
        this.section1BorrowerInformation.part1aPersonalInformation.otherBorrowers.splice(
          num
        );

        // splice array in validations
        this.validations.part1aPersonalInformation.otherBorrowers.splice(num);

        // splice array in errors
        this.errors.part1aPersonalInformation.otherBorrowers.splice(num);
      } else {
        // empty otherBorrower object
        const otherBorower = {
          firstName: null,
          middleName: null,
          lastName: null,
          suffix: null,
          alternateNames: [],
        };

        // empty otherBorrower object for error
        const otherBorowerError = {
          firstName: [],
          middleName: [],
          lastName: [],
          suffix: [],
          alternateNames: [],
        };

        for (var i = 0; i < num - len; i++) {
          // add empty object to otherBorrowers list
          this.section1BorrowerInformation.part1aPersonalInformation.otherBorrowers.push(
            JSON.parse(JSON.stringify(otherBorower))
          );

          // add empty object to validations list
          this.validations.part1aPersonalInformation.otherBorrowers.push(
            JSON.parse(JSON.stringify(otherBorower))
          );

          // add empty object to errors list
          this.errors.part1aPersonalInformation.otherBorrowers.push(
            JSON.parse(JSON.stringify(otherBorowerError))
          );
        }
      }
    },

    updateAgesOfDependentsSize: function() {
      // number of dependents specified
      const num = this.section1BorrowerInformation.part1aPersonalInformation
        .numberOfDependents;

      // length of other borrowers array
      const len = this.section1BorrowerInformation.part1aPersonalInformation
        .agesOfDependents.length;

      // return if number of borrowers and length is same
      if (num === len) return;

      if (num < len) {
        // splice the array if the length of array is greater
        this.section1BorrowerInformation.part1aPersonalInformation.agesOfDependents.splice(
          num
        );
      } else {
        for (var i = 0; i < num - len; i++) {
          // add empty object to agesOfDependents list
          this.section1BorrowerInformation.part1aPersonalInformation.agesOfDependents.push(
            null
          );
        }
      }
    },

    updateAddressesSize: function() {
      const addresses = this.section1BorrowerInformation
        .part1aPersonalInformation.addresses;
      var cumulativeNumberOfMonths = 0;
      const len = addresses.length;

      // empty address object
      const emptyAddress = {
        addressType: "Former Address",
        startDate: null,
        endDate: null,
        chronologicalOrder: null,
        street: null,
        unit: null,
        city: null,
        state: null,
        zip: null,
        country: "USA",
        monthsAtAddress: 0,
        yearsAtAddress: 0,
        housingType: null,
        housingMonthlyExpense: null,
      };

      // empty address object for error
      const addressError = {
        addressType: [],
        startDate: [],
        endDate: [],
        chronologicalOrder: [],
        street: [],
        unit: [],
        city: [],
        state: [],
        zip: [],
        country: [],
        monthsAtAddress: [],
        yearsAtAddress: [],
        housingType: [],
        housingMonthlyExpense: [],
      };

      for (var i = 0; i < len; i++) {
        // do not update addresses array size if duration is null for any address
        if (
          addresses[i].yearsAtAddress === null ||
          addresses[i].monthsAtAddress === null
        )
          return;

        // calculate total number of months
        cumulativeNumberOfMonths += addresses[i].yearsAtAddress * 12;
        cumulativeNumberOfMonths += addresses[i].monthsAtAddress;

        if (cumulativeNumberOfMonths >= 24) {
          // splice the array if the length of array is greater
          this.section1BorrowerInformation.part1aPersonalInformation.addresses.splice(
            i + 1
          );

          // splice array in validations
          this.validations.part1aPersonalInformation.addresses.splice(i + 1);

          // splice array in errors
          this.errors.part1aPersonalInformation.addresses.splice(i + 1);

          // break the loop
          break;
        } else if (cumulativeNumberOfMonths < 24 && i === len - 1) {
          // increase size of array only after checking the last element (i === len -1)
          // add empty object to addresses list
          this.section1BorrowerInformation.part1aPersonalInformation.addresses.push(
            JSON.parse(JSON.stringify(emptyAddress))
          );

          // add empty object to validations list
          this.validations.part1aPersonalInformation.addresses.push(
            JSON.parse(JSON.stringify(emptyAddress))
          );

          // add empty object to errors list
          this.errors.part1aPersonalInformation.addresses.push(
            JSON.parse(JSON.stringify(addressError))
          );
        }
      }
    },

    getDurationInMonths: function(startDate, endDate) {
      if (startDate === null || endDate === null) return 0;

      // split date to yyyy, mm, dd
      const startArr = startDate.split("-");
      const endArr = endDate.split("-");

      // calculate number of months *IGNORING DAYS*
      const diff = endArr[1] - startArr[1] + 12 * (endArr[0] - startArr[0]);

      // return difference
      return diff;
    },

    getFormattedDate: function(date) {
      // extract day, month and year from date
      const dd = String(date.getDate()).padStart(2, "0");
      const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = date.getFullYear();
      // concat string to yyyy-mm-dd format
      const formattedDate = yyyy + "-" + mm + "-" + dd;
      // return formatted date
      return formattedDate;
    },

    getToday: function() {
      // create new Date
      const date = new Date();

      // return today
      return this.getFormattedDate(date);
    },

    calculateMonthsAtCurrentJob: function() {
      var max = 0;
      // list of employment
      const employment = this.section1BorrowerInformation
        .part1bcCurrentEmployment;
      // length of employment list
      const len = employment.length;
      // get today
      const today = this.getToday();

      for (var i = 0; i < len; i++) {
        var monthsAtJob = 0;
        // get duration till today
        if (employment[i].startDate !== null)
          monthsAtJob = this.getDurationInMonths(
            employment[i].startDate,
            today
          );
        // check max value
        if (monthsAtJob > max) max = monthsAtJob;
      }
      // return max number of months at current job
      return max;
    },

    addPreviousEmployment: function() {
      // empty employment
      const emptyEmployment = {
        employerName: null,
        employerPhone: null,
        startDate: null,
        endDate: null,
        chronologicalOrder: null,
        street: null,
        city: null,
        state: null,
        zip: null,
        position: null,
        selfEmployment: {
          isEmployedByFamily: null,
          isSelfEmployedBusinessOwner: null,
          isOwnershipInCompanyMoreThanTwentyFivePercent: null,
          selfEmploymentMonthlyIncome: null,
        },
        grossMonthlyIncome: {
          baseSalary: null,
          overtime: "$0",
          bonus: "$0",
          commission: "$0",
          militaryEntitlements: "$0",
          other: "$0",
          total: "$0",
        },
      };

      // empty address object for error
      const employmentError = {
        employerName: [],
        employerPhone: [],
        startDate: [],
        endDate: [],
        chronologicalOrder: [],
        street: [],
        city: [],
        state: [],
        zip: [],
        position: [],
        selfEmployment: {
          isEmployedByFamily: [],
          isSelfEmployedBusinessOwner: [],
          isOwnershipInCompanyMoreThanTwentyFivePercent: [],
          selfEmploymentMonthlyIncome: [],
        },
        grossMonthlyIncome: {
          baseSalary: [],
          overtime: [],
          bonus: [],
          commission: [],
          militaryEntitlements: [],
          other: [],
          total: [],
        },
      };

      // add empty object to previous employment list
      this.section1BorrowerInformation.part1dPreviousEmployment.push(
        JSON.parse(JSON.stringify(emptyEmployment))
      );

      // add empty object to validations list
      this.validations.part1dPreviousEmployment.push(
        JSON.parse(JSON.stringify(emptyEmployment))
      );

      // add empty object to errors list
      this.errors.part1dPreviousEmployment.push(
        JSON.parse(JSON.stringify(employmentError))
      );
    },

    updatePreviousEmploymentSize: function() {
      const previousEmployment = this.section1BorrowerInformation
        .part1dPreviousEmployment;
      const len = previousEmployment.length;
      var cumulativeEmploymentDuration = this.calculateMonthsAtCurrentJob();

      if (len === 0 && cumulativeEmploymentDuration < 24) {
        // add employment object to list of previous employment
        this.addPreviousEmployment();
        return;
      }

      if (len > 0 && cumulativeEmploymentDuration > 24) {
        // if current employment duration is more than 24 months, remove all previous employment
        this.section1BorrowerInformation.part1dPreviousEmployment = [];

        // splice array in validations
        this.validations.part1dPreviousEmployment = [];

        // splice array in errors
        this.errors.part1dPreviousEmployment = [];
        return;
      }

      for (var i = 0; i < len; i++) {
        // do not update if startdate or end date is null
        if (
          previousEmployment[i].startDate === null ||
          previousEmployment[i].endDate === null
        )
          return;

        // calculate cumulative employment duration
        cumulativeEmploymentDuration += this.getDurationInMonths(
          previousEmployment[i].startDate,
          previousEmployment[i].endDate
        );

        if (cumulativeEmploymentDuration >= 24) {
          // splice the array if the length of array is greater
          this.section1BorrowerInformation.part1dPreviousEmployment.splice(
            i + 1
          );

          // splice array in validations
          this.validations.part1dPreviousEmployment.splice(i + 1);

          // splice array in errors
          this.errors.part1dPreviousEmployment.splice(i + 1);

          // break loop
          break;
        } else if (cumulativeEmploymentDuration < 24 && i === len - 1) {
          // increase size of array only after checking the last element (i === len -1)
          this.addPreviousEmployment();
        }
      }
    },
    autoFillCityAndStateErrorDisplay: function(field, event, validations) {
      // return from function if zipcode is null
      if (field.zipCode === null) return;

      // call POST /customers method of URLA API
      this.getCityAndState(field.zip)
        .then((data) => {
          // return if data is empty
          if (Object.keys(data).length === 0 && data.constructor === Object) {
            field.city = '';
            field.state = '';
            field.county = '';
            this.setZipErorAfterApiResponse(event);
            validations.zip =0;
            validations.city = 0;
            validations.state = 0;
            return;
          }
          // auto fill city
          field.city = data.City;
          // auto fill State
          field.state = data.State;
          // auto fill county if present
          if ("county" in field) field.county = data.County;
          validations.zip = 1;
          validations.city = 1;
          validations.state = 1;
        })
        .catch(() => {});
    },

    setDateOfBirthRange: function() {
      // min DoB
      var min = new Date();
      min.setFullYear(min.getFullYear() - 100);
      this.minDateOfBirth = this.getFormattedDate(min);

      // max DoB
      var max = new Date();
      max.setFullYear(max.getFullYear() - 18);
      this.maxDateOfBirth = this.getFormattedDate(max);
    },

    validateFirstName: function(event, index) {
      if (index === undefined) {
        // validate first name from validationsMixin
        this.errors.part1aPersonalInformation.firstName = this.checkFirstName(
          event
        );
        // set validation-icon
        this.validations.part1aPersonalInformation.firstName =
          this.errors.part1aPersonalInformation.firstName.length === 0 ? 1 : 0;
      } else {
        // validate other borrowerinformation if index is not undefined
        // validate first name from validationsMixin
        this.errors.part1aPersonalInformation.otherBorrowers[
          index
        ].firstName = this.checkFirstName(event);
        // set validation-icon
        this.validations.part1aPersonalInformation.otherBorrowers[
          index
        ].firstName =
          this.errors.part1aPersonalInformation.otherBorrowers[index].firstName
            .length === 0
            ? 1
            : 0;
      }
    },

    validateSpouseFirstName: function(event) {
      // validate first name from validationsMixin
      this.errors.part1aPersonalInformation.spouse.firstName = this.checkFirstName(
        event
      );
      // set validation-icon
      this.validations.part1aPersonalInformation.spouse.firstName =
        this.errors.part1aPersonalInformation.spouse.firstName.length === 0
          ? 1
          : 0;
    },

    validateSpouseLastName: function(event) {
      // validate first name from validationsMixin
      this.errors.part1aPersonalInformation.spouse.lastName = this.checkFirstName(
        event
      );
      // set validation-icon
      this.validations.part1aPersonalInformation.spouse.lastName =
        this.errors.part1aPersonalInformation.spouse.lastName.length === 0
          ? 1
          : 0;
    },

    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },

    validateLastName: function(event, index) {
      if (index === undefined) {
        // validate last name from validationsMixin
        this.errors.part1aPersonalInformation.lastName = this.checkLastName(
          event
        );
        // set validation-icon
        this.validations.part1aPersonalInformation.lastName =
          this.errors.part1aPersonalInformation.lastName.length === 0 ? 1 : 0;
      } else {
        // validate other borrowerinformation if index is not undefined
        // validate last name from validationsMixin
        this.errors.part1aPersonalInformation.otherBorrowers[
          index
        ].lastName = this.checkLastName(event);
        // set validation-icon
        this.validations.part1aPersonalInformation.otherBorrowers[
          index
        ].lastName =
          this.errors.part1aPersonalInformation.otherBorrowers[index].lastName
            .length === 0
            ? 1
            : 0;
      }
    },

    validateMiddleName: function(event, index) {
      if (index === undefined) {
        // validate last name from validationsMixin
        this.errors.part1aPersonalInformation.middleName = this.checkMiddleName(
          event
        );
        // set validation-icon
        this.validations.part1aPersonalInformation.middleName =
          this.errors.part1aPersonalInformation.middleName.length === 0 ? 1 : 0;
      } else {
        // validate other borrowerinformation if index is not undefined
        // validate middle name from validationsMixin
        this.errors.part1aPersonalInformation.otherBorrowers[
          index
        ].middleName = this.checkMiddleName(event);
        // set validation-icon
        this.validations.part1aPersonalInformation.otherBorrowers[
          index
        ].middleName =
          this.errors.part1aPersonalInformation.otherBorrowers[index].middleName
            .length === 0
            ? 1
            : 0;
      }
    },

    validateSuffix: function(event, index) {
      if (index === undefined) {
        // validate last name from validationsMixin
        this.errors.part1aPersonalInformation.suffix = this.checkSuffix(event);
        // set validation-icon
        this.validations.part1aPersonalInformation.suffix =
          this.errors.part1aPersonalInformation.suffix.length === 0 ? 1 : 0;
      } else {
        // validate other borrowerinformation if index is not undefined
        // validate suffix from validationsMixin
        this.errors.part1aPersonalInformation.otherBorrowers[
          index
        ].suffix = this.checkSuffix(event);
        // set validation-icon
        this.validations.part1aPersonalInformation.otherBorrowers[
          index
        ].suffix =
          this.errors.part1aPersonalInformation.otherBorrowers[index].suffix
            .length === 0
            ? 1
            : 0;
      }
    },

    validateSsn: function() {
      // validate ssn from validationsMixin
      this.errors.part1aPersonalInformation.socialSecurityNumber = this.checkSocialSecurityNumber(
        event
      );
      // set validation-icon
      this.validations.part1aPersonalInformation.socialSecurityNumber =
        this.errors.part1aPersonalInformation.socialSecurityNumber.length === 0
          ? 1
          : 0;
    },

    validateDateOfBirth: function(inputValue) {
      // validate Date from validationsMixin and check range for date
      this.errors.part1aPersonalInformation.dateOfBirth = this.checkDateRangeFromValue(
        inputValue,
        this.minDateOfBirth,
        this.maxDateOfBirth
      );

      // set validation-icon
      this.validations.part1aPersonalInformation.dateOfBirth =
        this.errors.part1aPersonalInformation.dateOfBirth.length === 0 ? 1 : 0;
    },

    validateNumberOfBorrowers: function() {
      // validate number from validationsMixin
      this.errors.part1aPersonalInformation.numberOfBorrowers = this.checkNumber(
        event
      );

      // set number of borrowers between 1 and 9
      const num = this.section1BorrowerInformation.part1aPersonalInformation
        .numberOfBorrowers;
      if (num < 1 || num > 3)
        this.errors.part1aPersonalInformation.numberOfBorrowers.push(
          "number of borrowers should be between 1 and 3"
        );

      // set validation-icon
      this.validations.part1aPersonalInformation.numberOfBorrowers =
        this.errors.part1aPersonalInformation.numberOfBorrowers.length === 0
          ? 1
          : 0;

      // modify the size of otherBorrowers Array if number of borrowers is valid
      if (this.validations.part1aPersonalInformation.numberOfBorrowers === 1)
        this.updateOtherBorrowersSize();

      // set type of credit
      if (
        this.section1BorrowerInformation.part1aPersonalInformation
          .numberOfBorrowers > 1
      )
        this.section1BorrowerInformation.part1aPersonalInformation.typeOfCredit =
          "joint credit";
      else
        this.section1BorrowerInformation.part1aPersonalInformation.typeOfCredit =
          "individual credit";
    },

    validateNumberOfDependents: function() {
      // validate number from validationsMixin
      this.errors.part1aPersonalInformation.numberOfDependents = this.checkNumber(
        event
      );

      // set number of borrowers between 1 and 3
      const num = this.section1BorrowerInformation.part1aPersonalInformation
        .numberOfDependents;
      if (num < 0 || num > 3)
        this.errors.part1aPersonalInformation.numberOfDependents.push(
          "number of dependents should be between 0 and 3"
        );

      // set validation-icon
      this.validations.part1aPersonalInformation.numberOfDependents =
        this.errors.part1aPersonalInformation.numberOfDependents.length === 0
          ? 1
          : 0;

      // modify the size of agesOfDependents Array if number of dependents is valid
      if (this.validations.part1aPersonalInformation.numberOfDependents === 1) {
        this.updateAgesOfDependentsSize();
        this.validateAgesOfDependents();
      }
    },

    validateAgesOfDependents: function() {
      // clear errors
      this.errors.part1aPersonalInformation.agesOfDependents = [];
      this.validations.part1aPersonalInformation.agesOfDependents = null;

      // loop through ages
      this.section1BorrowerInformation.part1aPersonalInformation.agesOfDependents.forEach(
        (age) => {
          // check for number
          if (isNaN(age))
            this.errors.part1aPersonalInformation.agesOfDependents.push(
              "Please enter a valid age"
            );

          // range for age
          if (age <= 0 || age > 150)
            this.errors.part1aPersonalInformation.agesOfDependents.push(
              "ages of dependents should be between 0 and 150"
            );
        }
      );

      // set validation-icon
      this.validations.part1aPersonalInformation.agesOfDependents =
        this.errors.part1aPersonalInformation.agesOfDependents.length === 0
          ? 1
          : 0;
    },

    validateCellPhone: function(event) {
      // validate phone number from validationsMixin
      this.errors.part1aPersonalInformation.cellPhone = this.checkPhoneNumber(
        event
      );

      // set validation-icon
      this.validations.part1aPersonalInformation.cellPhone =
        this.errors.part1aPersonalInformation.cellPhone.length === 0 ? 1 : 0;
    },

    validateHomePhone: function(event) {
      // validate phone number from validationsMixin
      this.errors.part1aPersonalInformation.homePhone = this.checkPhoneNumber(
        event
      );

      // set validation-icon
      this.validations.part1aPersonalInformation.homePhone =
        this.errors.part1aPersonalInformation.homePhone.length === 0 ? 1 : 0;
    },

    validateEmailAddress: function(event) {
      // validate email address from validationsMixin
      this.errors.part1aPersonalInformation.emailAddress = this.checkEmail(
        event
      );

      // set validation-icon
      this.validations.part1aPersonalInformation.emailAddress =
        this.errors.part1aPersonalInformation.emailAddress.length === 0 ? 1 : 0;
    },

    validateSpouseEmailAddress: function(event) {
      // validate email address from validationsMixin
      this.errors.part1aPersonalInformation.spouse.emailAddress = this.checkEmail(
        event
      );

      // set validation-icon
      this.validations.part1aPersonalInformation.spouse.emailAddress =
        this.errors.part1aPersonalInformation.spouse.emailAddress.length === 0
          ? 1
          : 0;
    },

    validateStreet: function(event, index, element) {
      var errors;
      var validations;

      if (element === "current-employment") {
        // validate current employment
        errors = this.errors.part1bcCurrentEmployment[index];
        validations = this.validations.part1bcCurrentEmployment[index];
      } else if (element === "previous-employment") {
        // validate previous employment
        errors = this.errors.part1dPreviousEmployment[index];
        validations = this.validations.part1dPreviousEmployment[index];
      } else {
        // validate personal information
        errors = this.errors.part1aPersonalInformation.addresses[index];
        validations = this.validations.part1aPersonalInformation.addresses[
          index
        ];
      }

      // validate street address from validationsMixin
      errors.street = this.checkStreet(event);

      // set validation-icon
      validations.street = errors.street.length === 0 ? 1 : 0;
    },

    validateUnit: function(event, index) {
      // validate unit address from validationsMixin
      this.errors.part1aPersonalInformation.addresses[
        index
      ].unit = this.checkUnit(event);

      // set validation-icon
      this.validations.part1aPersonalInformation.addresses[index].unit =
        this.errors.part1aPersonalInformation.addresses[index].unit.length === 0
          ? 1
          : 0;
    },

    validateCity: function(event, index, element) {
      var errors;
      var validations;

      if (element === "current-employment") {
        // validate current employment
        errors = this.errors.part1bcCurrentEmployment[index];
        validations = this.validations.part1bcCurrentEmployment[index];
      } else if (element === "previous-employment") {
        // validate previous employment
        errors = this.errors.part1dPreviousEmployment[index];
        validations = this.validations.part1dPreviousEmployment[index];
      } else {
        // validate personal information
        errors = this.errors.part1aPersonalInformation.addresses[index];
        validations = this.validations.part1aPersonalInformation.addresses[
          index
        ];
      }

      // validate city name from validationsMixin
      errors.city = this.checkCity(event);

      // set validation-icon
      validations.city = errors.city.length === 0 ? 1 : 0;
    },

    validateState: function(event, index, element) {
      var errors;
      var validations;

      if (element === "current-employment") {
        // validate current employment
        errors = this.errors.part1bcCurrentEmployment[index];
        validations = this.validations.part1bcCurrentEmployment[index];
      } else if (element === "previous-employment") {
        // validate previous employment
        errors = this.errors.part1dPreviousEmployment[index];
        validations = this.validations.part1dPreviousEmployment[index];
      } else {
        // validate personal information
        errors = this.errors.part1aPersonalInformation.addresses[index];
        validations = this.validations.part1aPersonalInformation.addresses[
          index
        ];
      }

      // validate state name from validationsMixin
      errors.state = this.checkState(event);

      // set validation-icon
      validations.state = errors.state.length === 0 ? 1 : 0;
    },

    validateZip: function(event, index, element) {
      var errors;
      var validations;
      var field;

      if (element === "current-employment") {
        // validate current employment
        errors = this.errors.part1bcCurrentEmployment[index];
        validations = this.validations.part1bcCurrentEmployment[index];
        field = this.section1BorrowerInformation.part1bcCurrentEmployment[
          index
        ];
      } else if (element === "previous-employment") {
        // validate previous employment
        errors = this.errors.part1dPreviousEmployment[index];
        validations = this.validations.part1dPreviousEmployment[index];
        field = this.section1BorrowerInformation.part1dPreviousEmployment[
          index
        ];
      } else {
        // validate personal information
        errors = this.errors.part1aPersonalInformation.addresses[index];
        validations = this.validations.part1aPersonalInformation.addresses[
          index
        ];
        field = this.section1BorrowerInformation.part1aPersonalInformation
          .addresses[index];
      }

      // validate zip from validationsMixin
      errors.zip = this.checkZip(event);

      var zipNoError = errors.zip.length === 0 ? 1 : 0;
      // get city name and State from Zip Code API
      if (zipNoError === 1){
        this.autoFillCityAndStateErrorDisplay(field, event, validations);
      }else {
      // set validation-icon
      validations.zip = errors.zip.length === 0 ? 1 : 0;
      }

    },

    validateYearsAtAddress: function(event, index) {
      // validate years at address from validationsMixin
      this.errors.part1aPersonalInformation.addresses[
        index
      ].yearsAtAddress = this.checkNumber(event);

      // check range for years at address
      if (
        this.section1BorrowerInformation.part1aPersonalInformation.addresses[
          index
        ].yearsAtAddress < 0 ||
        this.section1BorrowerInformation.part1aPersonalInformation.addresses[
          index
        ].yearsAtAddress > 100
      )
        this.errors.part1aPersonalInformation.addresses[
          index
        ].yearsAtAddress.push("years at address should be between 0 and 100");

      // check if both months and years are null
      if (
        this.section1BorrowerInformation.part1aPersonalInformation.addresses[
          index
        ].yearsAtAddress === 0 &&
        this.section1BorrowerInformation.part1aPersonalInformation.addresses[
          index
        ].monthsAtAddress === 0
      ) {
        // push error to yearsAtAddress
        this.errors.part1aPersonalInformation.addresses[
          index
        ].yearsAtAddress.push(
          "duration at address cannot be 0 years and 0 months"
        );
        // push error to monthsAtAddress
        this.errors.part1aPersonalInformation.addresses[
          index
        ].monthsAtAddress.push(
          "duration at address cannot be 0 years and 0 months"
        );
      }

      // set validation-icon
      this.validations.part1aPersonalInformation.addresses[
        index
      ].yearsAtAddress =
        this.errors.part1aPersonalInformation.addresses[index].yearsAtAddress
          .length === 0
          ? 1
          : 0;

      // update size of addresses list only if years and months are specified
      if (
        this.validations.part1aPersonalInformation.addresses[index]
          .yearsAtAddress === 1
      )
        this.updateAddressesSize();
    },

    validateMonthsAtAddress: function(event, index) {
      // validate months at address from validationsMixin
      this.errors.part1aPersonalInformation.addresses[
        index
      ].monthsAtAddress = this.checkNumber(event);

      // check range for months at address
      if (
        this.section1BorrowerInformation.part1aPersonalInformation.addresses[
          index
        ].monthsAtAddress < 0 ||
        this.section1BorrowerInformation.part1aPersonalInformation.addresses[
          index
        ].monthsAtAddress > 11
      )
        this.errors.part1aPersonalInformation.addresses[
          index
        ].monthsAtAddress.push("months at address should be between 0 and 11");

      // check if both months and years are null
      if (
        this.section1BorrowerInformation.part1aPersonalInformation.addresses[
          index
        ].yearsAtAddress === 0 &&
        this.section1BorrowerInformation.part1aPersonalInformation.addresses[
          index
        ].monthsAtAddress === 0
      ) {
        // push error to yearsAtAddress
        this.errors.part1aPersonalInformation.addresses[
          index
        ].yearsAtAddress.push(
          "duration at address cannot be 0 years and 0 months"
        );
        // push error to monthsAtAddress
        this.errors.part1aPersonalInformation.addresses[
          index
        ].monthsAtAddress.push(
          "duration at address cannot be 0 years and 0 months"
        );
      }

      // set validation-icon
      this.validations.part1aPersonalInformation.addresses[
        index
      ].monthsAtAddress =
        this.errors.part1aPersonalInformation.addresses[index].monthsAtAddress
          .length === 0
          ? 1
          : 0;

      // update size of addresses list
      if (
        this.validations.part1aPersonalInformation.addresses[index]
          .monthsAtAddress === 1
      )
        this.updateAddressesSize();
    },

    validateEmployerName: function(event, index, element) {
      var errors;
      var validations;

      if (element === "current-employment") {
        // validate current employment
        errors = this.errors.part1bcCurrentEmployment[index];
        validations = this.validations.part1bcCurrentEmployment[index];
      } else if (element === "previous-employment") {
        // validate previous employment
        errors = this.errors.part1dPreviousEmployment[index];
        validations = this.validations.part1dPreviousEmployment[index];
      }

      // validate employer name from validationsMixin
      errors.employerName = this.checkOrganizationName(event);

      // set validation-icon
      validations.employerName = errors.employerName.length === 0 ? 1 : 0;
    },

    validateEmployerPhone: function(event, index, element) {
      var errors;
      var validations;

      if (element === "current-employment") {
        // validate current employment
        errors = this.errors.part1bcCurrentEmployment[index];
        validations = this.validations.part1bcCurrentEmployment[index];
      } else if (element === "previous-employment") {
        // validate previous employment
        errors = this.errors.part1dPreviousEmployment[index];
        validations = this.validations.part1dPreviousEmployment[index];
      }

      // validate employerPhone from validationsMixin
      errors.employerPhone = this.checkPhoneNumber(event);

      // set validation-icon
      validations.employerPhone = errors.employerPhone.length === 0 ? 1 : 0;
    },

    validateCurrentEmploymentStartDate: function(inputValue, index) {
      // validate current employment start date
      var errors = this.errors.part1bcCurrentEmployment[index];
      var validations = this.validations.part1bcCurrentEmployment[index];

      // validate startDate from validationsMixin
      errors.startDate = this.checkDateRangeFromValue(
        inputValue,
        this.minDateOfBirth,
        this.currentDate
      );

      // set validation-icon
      validations.startDate = errors.startDate.length === 0 ? 1 : 0;

      // update array size only if both start date and end date are valid
      if (validations.startDate === 1) this.updatePreviousEmploymentSize();
    },

    validateCurrentMonthlyIncome: function(event, index) {
      // validate current employment monthly income
      var errors = this.errors.part1bcCurrentEmployment[index].selfEmployment;
      var validations = this.validations.part1bcCurrentEmployment[index]
        .selfEmployment;

      // validate price from validationsMixin
      errors.selfEmploymentMonthlyIncome = this.checkPrice(event);

      // set validation-icon
      validations.selfEmploymentMonthlyIncome =
        errors.selfEmploymentMonthlyIncome.length === 0 ? 1 : 0;
    },

    validateCurrentEmploymentBaseSalary: function(event, index) {
      // validate current employment Base Salary
      var errors = this.errors.part1bcCurrentEmployment[index]
        .grossMonthlyIncome;
      var validations = this.validations.part1bcCurrentEmployment[index]
        .grossMonthlyIncome;

      // validate price from validationsMixin
      errors.baseSalary = this.checkPrice(event);

      // set validation-icon
      validations.baseSalary = errors.baseSalary.length === 0 ? 1 : 0;
    },

    validateCurrentEmploymentOvertime: function(event, index) {
      // validate current employment overtime salary
      var errors = this.errors.part1bcCurrentEmployment[index]
        .grossMonthlyIncome;
      var validations = this.validations.part1bcCurrentEmployment[index]
        .grossMonthlyIncome;

      // validate price from validationsMixin
      errors.overtime = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.overtime = errors.overtime.length === 0 ? 1 : 0;
    },

    validateCurrentEmploymentBonus: function(event, index) {
      // validate current employment bonus salary
      var errors = this.errors.part1bcCurrentEmployment[index]
        .grossMonthlyIncome;
      var validations = this.validations.part1bcCurrentEmployment[index]
        .grossMonthlyIncome;

      // validate price from validationsMixin
      errors.bonus = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.bonus = errors.bonus.length === 0 ? 1 : 0;
    },

    validateCurrentEmploymentCommission: function(event, index) {
      // validate current employment salary commission
      var errors = this.errors.part1bcCurrentEmployment[index]
        .grossMonthlyIncome;
      var validations = this.validations.part1bcCurrentEmployment[index]
        .grossMonthlyIncome;

      // validate price from validationsMixin
      errors.commission = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.commission = errors.commission.length === 0 ? 1 : 0;
    },

    validateCurrentEmploymentMilitaryEntitlements: function(event, index) {
      // validate current employment military entitlements
      var errors = this.errors.part1bcCurrentEmployment[index]
        .grossMonthlyIncome;
      var validations = this.validations.part1bcCurrentEmployment[index]
        .grossMonthlyIncome;

      // validate price from validationsMixin
      errors.militaryEntitlements = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.militaryEntitlements =
        errors.militaryEntitlements.length === 0 ? 1 : 0;
    },

    validateCurrentEmploymentOther: function(event, index) {
      // validate current employment other salary
      var errors = this.errors.part1bcCurrentEmployment[index]
        .grossMonthlyIncome;
      var validations = this.validations.part1bcCurrentEmployment[index]
        .grossMonthlyIncome;

      // validate price from validationsMixin
      errors.other = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.other = errors.other.length === 0 ? 1 : 0;
    },

    validatePreviousEmploymentStartDate: function(inputValue, index) {
      // validate previous employment start date
      var errors = this.errors.part1dPreviousEmployment[index];
      var validations = this.validations.part1dPreviousEmployment[index];

      // validate startDate from validationsMixin
      errors.startDate = this.checkDateRangeFromValue(
        inputValue,
        this.minDateOfBirth,
        this.currentDate
      );

      // set validation-icon
      validations.startDate = errors.startDate.length === 0 ? 1 : 0;

      // update array size only if both start date and end date are valid
      if (validations.startDate === 1 && validations.endDate === 1)
        this.updatePreviousEmploymentSize();
    },

    validateEmploymentEndDate: function(inputValue, index) {
      var errors;
      var validations;
      var min = this.minDateOfBirth;

      // set end date for range
      if (
        this.section1BorrowerInformation.part1dPreviousEmployment[index]
          .startDate !== null
      )
        min = this.section1BorrowerInformation.part1dPreviousEmployment[index]
          .startDate;

      // errors and validations reference for previous Employment
      errors = this.errors.part1dPreviousEmployment[index];
      validations = this.validations.part1dPreviousEmployment[index];

      // validate endDate from validationsMixin
      errors.endDate = this.checkDateRangeFromValue(
        inputValue,
        min,
        this.currentDate
      );

      // set validation-icon
      validations.endDate = errors.endDate.length === 0 ? 1 : 0;

      // update array size only if both start date and end date are valid
      if (validations.startDate === 1 && validations.endDate === 1)
        this.updatePreviousEmploymentSize();
    },

    validatePreviousMonthlyIncome: function(event, index) {
      // validate previous employment monthly income
      var errors = this.errors.part1dPreviousEmployment[index].selfEmployment;
      var validations = this.validations.part1dPreviousEmployment[index]
        .selfEmployment;

      // validate price from validationsMixin
      errors.selfEmploymentMonthlyIncome = this.checkPrice(event);

      // set validation-icon
      validations.selfEmploymentMonthlyIncome =
        errors.selfEmploymentMonthlyIncome.length === 0 ? 1 : 0;
    },

    validatePreviousEmploymentBaseSalary: function(event, index) {
      // validate previous employment Base Salary
      var errors = this.errors.part1dPreviousEmployment[index]
        .grossMonthlyIncome;
      var validations = this.validations.part1dPreviousEmployment[index]
        .grossMonthlyIncome;

      // validate price from validationsMixin
      errors.baseSalary = this.checkPrice(event);

      // set validation-icon
      validations.baseSalary = errors.baseSalary.length === 0 ? 1 : 0;
    },

    validatePreviousEmploymentOvertime: function(event, index) {
      // validate previous employment overtime salary
      var errors = this.errors.part1dPreviousEmployment[index]
        .grossMonthlyIncome;
      var validations = this.validations.part1dPreviousEmployment[index]
        .grossMonthlyIncome;

      // validate price from validationsMixin
      errors.overtime = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.overtime = errors.overtime.length === 0 ? 1 : 0;
    },

    validatePreviousEmploymentBonus: function(event, index) {
      // validate previous employment bonus salary
      var errors = this.errors.part1dPreviousEmployment[index]
        .grossMonthlyIncome;
      var validations = this.validations.part1dPreviousEmployment[index]
        .grossMonthlyIncome;

      // validate price from validationsMixin
      errors.bonus = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.bonus = errors.bonus.length === 0 ? 1 : 0;
    },

    validatePreviousEmploymentCommission: function(event, index) {
      // validate previous employment salary commission
      var errors = this.errors.part1dPreviousEmployment[index]
        .grossMonthlyIncome;
      var validations = this.validations.part1dPreviousEmployment[index]
        .grossMonthlyIncome;

      // validate price from validationsMixin
      errors.commission = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.commission = errors.commission.length === 0 ? 1 : 0;
    },

    validatePreviousEmploymentMilitaryEntitlements: function(event, index) {
      // validate previous employment military entitlements
      var errors = this.errors.part1dPreviousEmployment[index]
        .grossMonthlyIncome;
      var validations = this.validations.part1dPreviousEmployment[index]
        .grossMonthlyIncome;

      // validate price from validationsMixin
      errors.militaryEntitlements = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.militaryEntitlements =
        errors.militaryEntitlements.length === 0 ? 1 : 0;
    },

    validatePreviousEmploymentOther: function(event, index) {
      // validate previous employment other salary
      var errors = this.errors.part1dPreviousEmployment[index]
        .grossMonthlyIncome;
      var validations = this.validations.part1dPreviousEmployment[index]
        .grossMonthlyIncome;

      // validate price from validationsMixin
      errors.other = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.other = errors.other.length === 0 ? 1 : 0;
    },

    validatePosition: function(event, index, element) {
      var errors;
      var validations;

      if (element === "current-employment") {
        // validate current employment
        errors = this.errors.part1bcCurrentEmployment[index];
        validations = this.validations.part1bcCurrentEmployment[index];
      } else if (element === "previous-employment") {
        // validate previous employment
        errors = this.errors.part1dPreviousEmployment[index];
        validations = this.validations.part1dPreviousEmployment[index];
      }

      // validate employment position from validationsMixin
      errors.position = this.checkJobTitle(event);

      // set validation-icon
      validations.position = errors.position.length === 0 ? 1 : 0;
    },

    validateSectionOne: function() {
      var isValid = false;

      // validate part1a
      if (this.validatePart1a) isValid = true;
      // validate part1bc
      if (this.validatePart1bc) isValid = true;
      // validate part1d
      if (this.validatePart1d) isValid = true;

      // check errors in each field and update validation icon
      this.checkSectionOneErrors();

      // return isValid
      return isValid;
    },
    validateYearsAtAddressOnSubmit: function() {
      //check years-at-address
      
      if(this.section1BorrowerInformation) {
        const addresses = this.section1BorrowerInformation
        .part1aPersonalInformation.addresses;
        const len = addresses.length;
        var cumulativeNumberOfMonths = 0;
        var individualNumberOfMonths = 0;
        for(var i=0; i<len; i++) {
           individualNumberOfMonths = (addresses[i].yearsAtAddress * 12) + addresses[i].monthsAtAddress;
           cumulativeNumberOfMonths += individualNumberOfMonths;
           if(individualNumberOfMonths == 0) {
            this.setPersonalInformationAddressError(i);
            return false;
           }
        }
        if (cumulativeNumberOfMonths <= 24) {
            this.validations.part1aPersonalInformation.addresses[
              len-1 
            ].yearsAtAddress = 0;
            this.validations.part1aPersonalInformation.addresses[
              len-1
            ].monthsAtAddress = 0;
            this.$scrollTo(this.$refs.sectionOne, {
                duration: 500,
                easing: "ease",
                offset: 500,
              });
            return false;
        }
        return true;
      }
    },
    setPersonalInformationAddressError: function(index) {
      this.errors.part1aPersonalInformation.addresses[
              index
            ].yearsAtAddress = [];
            this.errors.part1aPersonalInformation.addresses[
              index
            ].monthsAtAddress = [];
            this.errors.part1aPersonalInformation.addresses[
              index
            ].yearsAtAddress.push(
              "duration at address cannot be 0 years and 0 months"
            );
            // push error to monthsAtAddress
            this.errors.part1aPersonalInformation.addresses[
              index
            ].monthsAtAddress.push(
              "duration at address cannot be 0 years and 0 months"
            );
            // set validation-icon
            this.validations.part1aPersonalInformation.addresses[
              index
            ].yearsAtAddress =
              this.errors.part1aPersonalInformation.addresses[index].yearsAtAddress
                .length === 0
                ? 1
                : 0;
            this.validations.part1aPersonalInformation.addresses[
              index
            ].monthsAtAddress = 
              this.errors.part1aPersonalInformation.addresses[index].monthsAtAddress
                .length === 0
                ? 1
                : 0;
            this.$scrollTo(this.$refs.sectionOne, {
                duration: 500,
                easing: "ease",
                offset: 300,
              });
    },
    
    submitSectionOne: function() {
      // validate form and submit
      if(this.validateYearsAtAddressOnSubmit()) {
        bus.$emit("postUrla", this.section1BorrowerInformation);
      }else {
        return false;
      }
      
    },
  },

  mounted: function() {
    // set default values

    // number of borrowers
    if (
      this.section1BorrowerInformation.part1aPersonalInformation
        .numberOfBorrowers === null ||
      this.section1BorrowerInformation.part1aPersonalInformation
        .numberOfBorrowers === 0
    )
      this.section1BorrowerInformation.part1aPersonalInformation.numberOfBorrowers = 1;

    // number of dependents
    if (
      this.section1BorrowerInformation.part1aPersonalInformation
        .numberOfDependents === null
    )
      this.section1BorrowerInformation.part1aPersonalInformation.numberOfDependents = 0;

    // years at current address
    if (
      this.section1BorrowerInformation.part1aPersonalInformation.addresses[0]
        .yearsAtAddress === null
    )
      this.section1BorrowerInformation.part1aPersonalInformation.addresses[0].yearsAtAddress = 0;

    // months at current address
    if (
      this.section1BorrowerInformation.part1aPersonalInformation.addresses[0]
        .monthsAtAddress === null
    )
      this.section1BorrowerInformation.part1aPersonalInformation.addresses[0].monthsAtAddress = 0;

    // min and max for date of birth
    this.setDateOfBirthRange();

    // current Date
    this.currentDate = this.getToday();

    // scroll to top of page
    this.$scrollTo(this.$refs.sectionOne, {
      duration: 500,
      easing: "ease",
      offset: -200,
    });
  },

  created: function() {
    // copy data received from urla into current section
    this.section1BorrowerInformation = this.parseSection(
      this.section1BorrowerInformation,
      this.sourceSection1
    );

    // initialize validations object to null
    this.validations = this.initializeToNull(this.section1BorrowerInformation);

    // initialize errors object to empty array
    this.errors = this.initializeToEmptyArray(this.section1BorrowerInformation);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.own-property {
  display: flex;
  flex-direction: row-reverse;
}
.spouse-label {
  min-width: 145px;
  display: inline-block;
}
.spouse-information {
  color: #000;
  font-size: 15px;
  font-weight: bold;
}
</style>
