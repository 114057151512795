<template>
  <!-- wrapper -->
  <div class="wrapper">
    <!-- main -->
    <section class="main">
      <!-- Content -->
      <section>
        <!-- create review form -->
        <form
          id="quote"
          v-on:submit="postReview"
          method="post"
          class="gtr-uniform"
        >
          <h1>Write your review</h1>
          <p class="instruction">
            Required fields are followed by
            <strong>
              <abbr class="required" title="required">*</abbr> </strong
            >.
          </p>
          <br />
          <!-- this form has only one section -->
          <section>
            <!-- First name -->
            <p ref="firstName">
              <label for="first-name">
                <span>First Name:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
              <input
                id="first-name"
                type="text"
                placeholder="First Name"
                v-model="customer.firstName"
                required
              />
            </p>
            <!-- Last name -->
            <p ref="lastName">
              <label for="last-name">
                <span>Last Name:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
              <input
                id="last-name"
                type="text"
                placeholder="Last Name"
                v-model="customer.lastName"
                required
              />
            </p>
            <!-- email address -->
            <p ref="email">
              <label for="email">
                <span>Email Address:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
              <input
                id="email"
                type="email"
                placeholder="dan@gmail.com"
                v-model="customer.email"
                required
              />
            </p>
            <!-- Local knowledge -->
            <div ref="localKnowledge">
              <label for="local-knowledge">
                <span>Local knowledge:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
              <star-rating
                class="star-rating"
                id="local-knowledge"
                v-bind="starRatingConfig"
                v-on:rating-selected="validateLocalKnowledge"
                v-model="review.localKnowledge"
              ></star-rating>
            </div>
            <!-- Process expertise -->
            <div ref="processExpertise">
              <label for="process-expertise">
                <span>Process expertise:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
              <star-rating
                class="star-rating"
                id="process-expertise"
                v-bind="starRatingConfig"
                v-on:rating-selected="validateProcessExpertise"
                v-model="review.processExpertise"
              ></star-rating>
            </div>
            <!-- Responsiveness -->
            <div ref="responsiveness">
              <label for="responsiveness">
                <span>Responsiveness:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
              <star-rating
                class="star-rating"
                id="responsiveness"
                v-bind="starRatingConfig"
                v-on:rating-selected="validateResponsiveness"
                v-model="review.responsiveness"
              ></star-rating>
            </div>
            <!-- Negotiation skills -->
            <div ref="negotiationSkills">
              <label for="negotiation-skills">
                <span>Negotiation skills:</span>
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
              <star-rating
                class="star-rating"
                id="negotiation-skills"
                v-bind="starRatingConfig"
                v-on:rating-selected="validateNegotiationSkills"
                v-model="review.negotiationSkills"
              ></star-rating>
            </div>
            <br />
            <!-- Likeliness to recommend -->
            <fieldset>
              <legend>
                <span
                  >How likely are you to recommed American Smart Lending to a
                  friend of colleague?</span
                >
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </legend>
              <ul>
                <li>
                  <input
                    type="radio"
                    id="highly-likely"
                    value="Highly likely to recommend"
                    v-model="review.headline"
                  />
                  <label for="highly-likely">Highly likely</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="very-likely"
                    value="Very likely to recommend"
                    v-model="review.headline"
                  />
                  <label for="very-likely">Very likely</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="somewhat-likely"
                    value="Somoewhat likely to recommend"
                    v-model="review.headline"
                  />
                  <label for="somewhat-likely">Somewhat likely</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="not-likely"
                    value="Not very likely to recommend"
                    v-model="review.headline"
                  />
                  <label for="not-likely">Not very likely</label>
                </li>
              </ul>
            </fieldset>
            <p class="note-wrapper">
              <label for="note">Write your review:</label>
              <textarea
                id="note"
                rows="4"
                v-bind:maxlength="noteMaxLength"
                v-model="review.note"
              ></textarea>
              <span class="note-info"
                >{{ review.note.length }}/{{ noteMaxLength }}</span
              >
            </p>
            <br />
            <p>
              <!-- Submit button -->
              <button type="submit" class="primary">Submit</button>
            </p>
          </section>
        </form>
      </section>
    </section>
  </div>
</template>

<script>
export default {
  name: "CreateReviews",
  data() {
    return {
      regExp: {
        /* eslint-disable */
        firstName: /^[a-zA-Z\'\,\.\- ]+$/,
        lastName: /^[a-zA-Z\'\,\.\- ]+$/,
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
      customer: { firstName: "", lastName: "", email: "" },
      review: {
        localKnowledge: 0,
        processExpertise: 0,
        responsiveness: 0,
        negotiationSkills: 0,
        headline: "Highly likely to recommend",
        time: null,
        note: "",
      },
      starRatingConfig: {
        starSize: 22,
        inactiveColor: "#ffffff",
        activeColor: "#2ebfac",
        borderColor: "#2ebfac",
        borderWidth: 2,
        roundedCorners: true,
        inline: true,
        showRating: false,
      },
      noteMaxLength: 1000,
    };
  },
  methods: {
    // add invalid class to the DOM element specified
    addInvalidClass: function (domElement) {
      domElement.classList.remove("validInput");
      domElement.classList.add("invalidInput");
    },
    // add valid class to the DOM element specified
    addValidClass: function (domElement) {
      domElement.classList.remove("invalidInput");
      domElement.classList.add("validInput");
    },
    // setup event listeners for this vue component
    setupEventListeners: function () {
      // event listner on firstName input
      this.$refs.firstName.addEventListener(
        "input",
        function (event) {
          // catch bubbled event, store target element's value
          var inputValue = event.target.value;
          if (!inputValue.match(this.regExp.firstName)) {
            // add invalidInput to classList if input is not valid
            this.addInvalidClass(this.$refs.firstName);
            // set custom message
            event.target.setCustomValidity("please enter a valid First Name");
            // display the message
            event.target.reportValidity();
          } else {
            // add validInput to classList if input is valid
            this.addValidClass(this.$refs.firstName);
            // set custom message
            event.target.setCustomValidity("");
          }
        }.bind(this)
      );
      // event listner on lastName input
      this.$refs.lastName.addEventListener(
        "input",
        function (event) {
          // catch bubbled event, store target element's value
          var inputValue = event.target.value;

          if (!inputValue.match(this.regExp.lastName)) {
            // add invalidInput to classList if input is not valid
            this.addInvalidClass(this.$refs.lastName);
            // set custom message
            event.target.setCustomValidity("please enter a valid Last Name");
            // display the message
            event.target.reportValidity();
          } else {
            // add validInput to classList if input is valid
            this.addValidClass(this.$refs.lastName);
            // set custom message
            event.target.setCustomValidity("");
          }
        }.bind(this)
      );
      // event listner on email input
      this.$refs.email.addEventListener(
        "input",
        function (event) {
          // catch bubbled event, store target element's value
          var inputValue = event.target.value;

          if (!inputValue.match(this.regExp.email)) {
            // add invalidInput to classList if input is not valid
            this.addInvalidClass(this.$refs.email);
            // set custom message
            event.target.setCustomValidity(
              "please enter a valid email address"
            );
            // display the message
            event.target.reportValidity();
          } else {
            // add validInput to classList if input is valid
            this.addValidClass(this.$refs.email);
            // set custom message
            event.target.setCustomValidity("");
          }
        }.bind(this)
      );
    },
    // validate local knowledge star-rating
    validateLocalKnowledge() {
      var validity = false;
      if (this.review.localKnowledge > 0) {
        // add valid class if rated
        this.addValidClass(this.$refs.localKnowledge);
        validity = true;
      } else {
        // add invalid class if not rated
        this.addInvalidClass(this.$refs.localKnowledge);
      }
      return validity;
    },
    // validate process expertise star-rating
    validateProcessExpertise() {
      var validity = false;
      if (this.review.processExpertise > 0) {
        // add valid class if rated
        this.addValidClass(this.$refs.processExpertise);
        validity = true;
      } else {
        // add invalid class if not rated
        this.addInvalidClass(this.$refs.processExpertise);
      }
      return validity;
    },
    // validate responsiveness star-rating
    validateResponsiveness() {
      var validity = false;
      if (this.review.responsiveness > 0) {
        // add valid class if rated
        this.addValidClass(this.$refs.responsiveness);
        validity = true;
      } else {
        // add invalid class if not rated
        this.addInvalidClass(this.$refs.responsiveness);
      }
      return validity;
    },
    // validate negotiation skills star-rating
    validateNegotiationSkills() {
      var validity = false;
      if (this.review.negotiationSkills > 0) {
        // add valid class if rated
        this.addValidClass(this.$refs.negotiationSkills);
        validity = true;
      } else {
        // add invalid class if not rated
        this.addInvalidClass(this.$refs.negotiationSkills);
      }
      return validity;
    },
    validateForm() {
      var validity = true;
      // validate local knowledge
      validity = this.validateLocalKnowledge() && validity;
      // validate process expertise
      validity = this.validateProcessExpertise() && validity;
      // validate responsiveness
      validity = this.validateResponsiveness() && validity;
      // validate negotiation skills
      validity = this.validateNegotiationSkills() && validity;

      return validity;
    },

    postReview: function (event) {
      if (this.validateForm()) {
      }
      event.preventDefault();
    },

    init: function () {
      // setup event listeners
      this.setupEventListeners();
    },
  },

  mounted: function () {
    this.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.required,
.instruction,
.error {
  text-decoration: none;
  color: #bf4d2e;
  margin: 0;
  padding: 0;
}

/* style for input and labels */
input,
label,
textarea {
  display: inline-block;
  width: 250px;
  box-sizing: border-box;
}

/* style for star rating */
.star-rating {
  height: 2.75em;
}

/* style for textarea */
.note-wrapper {
  width: 525px;
}

textarea {
  display: inline-block;
  box-sizing: border-box;
  width: 525px;
}

/* style for number of characters remaining in text area*/
.note-info {
  color: #aaa;
  float: right;
}

/* width for list elements in radio */
input[type="radio"] {
  width: auto;
}

/* style for list elements in radio/select/checkbox */
fieldset ul {
  margin: 0 0 2% 0;
}

fieldset li {
  display: inline;
  list-style-type: none;
  padding: 0;
}

/* font styles for form elements */
form p,
form legend,
form label {
  font: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  margin: 0 0 2% 0;
  color: #464646;
}

/* align form to center */
form {
  margin: auto;
  width: 50%;
  /*background-color: #f5f5f5;*/
}

/* align submit button to right */
.primary {
  margin: 0 0 0 70%;
}

/* styles for valid form elements */
.validInput:after {
  font-family: FontAwesome;
  font-size: 24px;
  content: "\f00c";
  padding: 0 0 0 5px;
  color: green;
}

/* styles for invalid form elements */
.invalidInput:after {
  font-family: FontAwesome;
  font-size: 24px;
  content: "\f00d";
  padding: 0 0 0 5px;
  color: red;
}
</style>