var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"sectionFive",staticClass:"urla-section"},[_c('h2',[_vm._v("Declarations")]),_c('p',{staticClass:"urla-section-description"},[_vm._v(" This section asks you specific questions about the property, your funding, and your past financial history. ")]),_vm._m(0),_c('form',{attrs:{"id":"section-5-form","method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.submitSectionFive.apply(null, arguments)}}},[_c('div',[_c('h4',[_vm._v("About this Property and Your money for this Loan")]),_c('div',[_c('div',{staticClass:"row"},[_vm._m(1),_c('fieldset',{staticClass:"urla-radio radio-inline col-5 col-12-xsmall"},[_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                    _vm.section5Declarations.part5aAboutThisProperty.questionA
                      .answer1
                  ),expression:"\n                    section5Declarations.part5aAboutThisProperty.questionA\n                      .answer1\n                  "}],attrs:{"name":"question-a-1","type":"radio","id":"question-a-1-no","required":""},domProps:{"value":false,"checked":_vm._q(
                    _vm.section5Declarations.part5aAboutThisProperty.questionA
                      .answer1
                  ,false)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5aAboutThisProperty.questionA
                      , "answer1", false)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-a-1-no"}},[_vm._v("No")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                    _vm.section5Declarations.part5aAboutThisProperty.questionA
                      .answer1
                  ),expression:"\n                    section5Declarations.part5aAboutThisProperty.questionA\n                      .answer1\n                  "}],attrs:{"name":"question-a-1","type":"radio","id":"question-a-1-yes"},domProps:{"value":true,"checked":_vm._q(
                    _vm.section5Declarations.part5aAboutThisProperty.questionA
                      .answer1
                  ,true)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5aAboutThisProperty.questionA
                      , "answer1", true)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-a-1-yes"}},[_vm._v("Yes")])])])])]),_c('transition',{attrs:{"name":"fade"}},[(
              _vm.section5Declarations.part5aAboutThisProperty.questionA.answer1
            )?_c('div',{staticClass:"row"},[_c('p',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticStyle:{"display":"inline"},attrs:{"for":"question-a-2"}},[_c('span',{staticClass:"font-weight-normal"},[_vm._v("have you had an ownership interest in another property in the last three years?")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])])]),_c('fieldset',{staticClass:"urla-radio radio-inline col-5 col-12-xsmall"},[_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.section5Declarations.part5aAboutThisProperty.questionA
                        .answer2
                    ),expression:"\n                      section5Declarations.part5aAboutThisProperty.questionA\n                        .answer2\n                    "}],attrs:{"name":"question-a-2","type":"radio","id":"question-a-2-no","required":""},domProps:{"value":false,"checked":_vm._q(
                      _vm.section5Declarations.part5aAboutThisProperty.questionA
                        .answer2
                    ,false)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5aAboutThisProperty.questionA
                        , "answer2", false)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-a-2-no"}},[_vm._v("No")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.section5Declarations.part5aAboutThisProperty.questionA
                        .answer2
                    ),expression:"\n                      section5Declarations.part5aAboutThisProperty.questionA\n                        .answer2\n                    "}],attrs:{"name":"question-a-2","type":"radio","id":"question-a-2-yes"},domProps:{"value":true,"checked":_vm._q(
                      _vm.section5Declarations.part5aAboutThisProperty.questionA
                        .answer2
                    ,true)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5aAboutThisProperty.questionA
                        , "answer2", true)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-a-2-yes"}},[_vm._v("Yes")])])])])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(
              _vm.section5Declarations.part5aAboutThisProperty.questionA
                .answer1 &&
                _vm.section5Declarations.part5aAboutThisProperty.questionA.answer2
            )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticClass:"large-label font-weight-normal",attrs:{"for":"question-a-3"}},[_c('span',[_vm._v("What type of property did you own?")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5aAboutThisProperty.questionA
                    .answer3
                ),expression:"\n                  section5Declarations.part5aAboutThisProperty.questionA\n                    .answer3\n                "}],attrs:{"id":"question-a-3","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.section5Declarations.part5aAboutThisProperty.questionA
                    , "answer3", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"Primary Residence","selected":"selected"}},[_vm._v("Primary Residence")]),_c('option',{attrs:{"value":"Gift of Equity"}},[_vm._v("FHA Secondary Residence")]),_c('option',{attrs:{"value":"Second Home"}},[_vm._v("Second Home")]),_c('option',{attrs:{"value":"Investment Property"}},[_vm._v("Investment Property")])])])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(
              _vm.section5Declarations.part5aAboutThisProperty.questionA
                .answer1 &&
                _vm.section5Declarations.part5aAboutThisProperty.questionA.answer2
            )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticClass:"large-label font-weight-normal",attrs:{"for":"question-a-4"}},[_c('span',[_vm._v("How did you hold title to the property?")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5aAboutThisProperty.questionA
                    .answer4
                ),expression:"\n                  section5Declarations.part5aAboutThisProperty.questionA\n                    .answer4\n                "}],attrs:{"id":"question-a-4","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.section5Declarations.part5aAboutThisProperty.questionA
                    , "answer4", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"by yourself","selected":"selected"}},[_vm._v("By yourself")]),_c('option',{attrs:{"value":"jointly with your spouse"}},[_vm._v("Jointly with your spouse")]),_c('option',{attrs:{"value":"jointly with another person"}},[_vm._v("Jointly with another person")])])])]):_vm._e()])],1),_c('hr',{staticClass:"urla-break"}),_c('div',{staticClass:"row"},[_vm._m(2),_c('fieldset',{staticClass:"urla-radio radio-inline col-5 col-12-xsmall"},[_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5aAboutThisProperty.questionB
                ),expression:"\n                  section5Declarations.part5aAboutThisProperty.questionB\n                "}],attrs:{"name":"question-b","type":"radio","id":"question-b-no","required":""},domProps:{"value":false,"checked":_vm._q(
                  _vm.section5Declarations.part5aAboutThisProperty.questionB
                ,false)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5aAboutThisProperty, "questionB", false)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-b-no"}},[_vm._v("No")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5aAboutThisProperty.questionB
                ),expression:"\n                  section5Declarations.part5aAboutThisProperty.questionB\n                "}],attrs:{"name":"question-b","type":"radio","id":"question-b-yes"},domProps:{"value":true,"checked":_vm._q(
                  _vm.section5Declarations.part5aAboutThisProperty.questionB
                ,true)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5aAboutThisProperty, "questionB", true)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-b-yes"}},[_vm._v("Yes")])])])])]),_c('hr',{staticClass:"urla-break"}),_c('div',[_c('div',{staticClass:"row"},[_vm._m(3),_c('fieldset',{staticClass:"urla-radio radio-inline col-5 col-12-xsmall"},[_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                    _vm.section5Declarations.part5aAboutThisProperty.questionC
                      .answer1
                  ),expression:"\n                    section5Declarations.part5aAboutThisProperty.questionC\n                      .answer1\n                  "}],attrs:{"name":"question-c-1","type":"radio","id":"question-c-1-no","required":""},domProps:{"value":false,"checked":_vm._q(
                    _vm.section5Declarations.part5aAboutThisProperty.questionC
                      .answer1
                  ,false)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5aAboutThisProperty.questionC
                      , "answer1", false)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-c-1-no"}},[_vm._v("No")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                    _vm.section5Declarations.part5aAboutThisProperty.questionC
                      .answer1
                  ),expression:"\n                    section5Declarations.part5aAboutThisProperty.questionC\n                      .answer1\n                  "}],attrs:{"name":"question-c-1","type":"radio","id":"question-c-1-yes"},domProps:{"value":true,"checked":_vm._q(
                    _vm.section5Declarations.part5aAboutThisProperty.questionC
                      .answer1
                  ,true)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5aAboutThisProperty.questionC
                      , "answer1", true)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-c-1-yes"}},[_vm._v("Yes")])])])])]),_c('transition',{attrs:{"name":"fade"}},[(
              _vm.section5Declarations.part5aAboutThisProperty.questionC.answer1
            )?_c('div',{staticClass:"row"},[_c('p',[_c('label',{staticClass:"large-label font-weight-normal",attrs:{"for":"question-c-2"}},[_c('span',[_vm._v("What is the amount of this money?")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])]),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(
                  _vm.section5Declarations.part5aAboutThisProperty.questionC
                    .answer2
                ),expression:"\n                  section5Declarations.part5aAboutThisProperty.questionC\n                    .answer2\n                ",modifiers:{"lazy":true}},{name:"money",rawName:"v-money",value:(
                  _vm.section5Declarations.part5aAboutThisProperty.questionC
                    .answer2 !== null
                    ? _vm.currencyFormat
                    : null
                ),expression:"\n                  section5Declarations.part5aAboutThisProperty.questionC\n                    .answer2 !== null\n                    ? currencyFormat\n                    : null\n                "}],staticClass:"small-input",attrs:{"id":"question-c-2","type":"text","placeholder":"$250,000","required":""},domProps:{"value":(
                  _vm.section5Declarations.part5aAboutThisProperty.questionC
                    .answer2
                )},on:{"blur":function($event){return _vm.validateQuestionC2($event)},"change":function($event){return _vm.$set(_vm.section5Declarations.part5aAboutThisProperty.questionC
                    , "answer2", $event.target.value)}}}),_c('validation-icon',{attrs:{"isValid":_vm.validations.part5aAboutThisProperty.questionC.answer2}})],1)]):_vm._e()])],1),_c('hr',{staticClass:"urla-break"}),_c('div',[_c('div',{staticClass:"row"},[_vm._m(4),_c('fieldset',{staticClass:"urla-radio radio-inline col-5 col-12-xsmall"},[_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                    _vm.section5Declarations.part5aAboutThisProperty.questionD
                      .answer1
                  ),expression:"\n                    section5Declarations.part5aAboutThisProperty.questionD\n                      .answer1\n                  "}],attrs:{"name":"question-d-1","type":"radio","id":"question-d-1-no","required":""},domProps:{"value":false,"checked":_vm._q(
                    _vm.section5Declarations.part5aAboutThisProperty.questionD
                      .answer1
                  ,false)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5aAboutThisProperty.questionD
                      , "answer1", false)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-d-1-no"}},[_vm._v("No")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                    _vm.section5Declarations.part5aAboutThisProperty.questionD
                      .answer1
                  ),expression:"\n                    section5Declarations.part5aAboutThisProperty.questionD\n                      .answer1\n                  "}],attrs:{"name":"question-d-1","type":"radio","id":"question-d-1-yes"},domProps:{"value":true,"checked":_vm._q(
                    _vm.section5Declarations.part5aAboutThisProperty.questionD
                      .answer1
                  ,true)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5aAboutThisProperty.questionD
                      , "answer1", true)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-d-1-yes"}},[_vm._v("Yes")])])])])]),_c('div',{staticClass:"row"},[_vm._m(5),_c('fieldset',{staticClass:"urla-radio radio-inline col-5 col-12-xsmall"},[_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                    _vm.section5Declarations.part5aAboutThisProperty.questionD
                      .answer2
                  ),expression:"\n                    section5Declarations.part5aAboutThisProperty.questionD\n                      .answer2\n                  "}],attrs:{"name":"question-d-2","type":"radio","id":"question-d-2-no","required":""},domProps:{"value":false,"checked":_vm._q(
                    _vm.section5Declarations.part5aAboutThisProperty.questionD
                      .answer2
                  ,false)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5aAboutThisProperty.questionD
                      , "answer2", false)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-d-2-no"}},[_vm._v("No")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                    _vm.section5Declarations.part5aAboutThisProperty.questionD
                      .answer2
                  ),expression:"\n                    section5Declarations.part5aAboutThisProperty.questionD\n                      .answer2\n                  "}],attrs:{"name":"question-d-2","type":"radio","id":"question-d-2-yes"},domProps:{"value":true,"checked":_vm._q(
                    _vm.section5Declarations.part5aAboutThisProperty.questionD
                      .answer2
                  ,true)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5aAboutThisProperty.questionD
                      , "answer2", true)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-d-2-yes"}},[_vm._v("Yes")])])])])])]),_c('hr',{staticClass:"urla-break"}),_c('div',{staticClass:"row"},[_vm._m(6),_c('fieldset',{staticClass:"urla-radio radio-inline col-5 col-12-xsmall"},[_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5aAboutThisProperty.questionE
                ),expression:"\n                  section5Declarations.part5aAboutThisProperty.questionE\n                "}],attrs:{"name":"question-e","type":"radio","id":"question-e-no","required":""},domProps:{"value":false,"checked":_vm._q(
                  _vm.section5Declarations.part5aAboutThisProperty.questionE
                ,false)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5aAboutThisProperty, "questionE", false)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-e-no"}},[_vm._v("No")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5aAboutThisProperty.questionE
                ),expression:"\n                  section5Declarations.part5aAboutThisProperty.questionE\n                "}],attrs:{"name":"question-e","type":"radio","id":"question-e-yes"},domProps:{"value":true,"checked":_vm._q(
                  _vm.section5Declarations.part5aAboutThisProperty.questionE
                ,true)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5aAboutThisProperty, "questionE", true)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-e-yes"}},[_vm._v("Yes")])])])])])]),_c('hr',{staticClass:"urla-break"}),_c('div',[_c('h4',[_vm._v("About Your Finances")]),_c('div',{staticClass:"row"},[_vm._m(7),_c('fieldset',{staticClass:"urla-radio radio-inline col-5 col-12-xsmall"},[_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5bAboutYourFinances.questionF
                ),expression:"\n                  section5Declarations.part5bAboutYourFinances.questionF\n                "}],attrs:{"name":"question-f","type":"radio","id":"question-f-no","required":""},domProps:{"value":false,"checked":_vm._q(
                  _vm.section5Declarations.part5bAboutYourFinances.questionF
                ,false)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5bAboutYourFinances, "questionF", false)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-f-no"}},[_vm._v("No")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5bAboutYourFinances.questionF
                ),expression:"\n                  section5Declarations.part5bAboutYourFinances.questionF\n                "}],attrs:{"name":"question-f","type":"radio","id":"question-f-yes"},domProps:{"value":true,"checked":_vm._q(
                  _vm.section5Declarations.part5bAboutYourFinances.questionF
                ,true)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5bAboutYourFinances, "questionF", true)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-f-yes"}},[_vm._v("Yes")])])])])]),_c('hr',{staticClass:"urla-break"}),_c('div',{staticClass:"row"},[_vm._m(8),_c('fieldset',{staticClass:"urla-radio radio-inline col-5 col-12-xsmall"},[_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5bAboutYourFinances.questionG
                ),expression:"\n                  section5Declarations.part5bAboutYourFinances.questionG\n                "}],attrs:{"name":"question-g","type":"radio","id":"question-g-no","required":""},domProps:{"value":false,"checked":_vm._q(
                  _vm.section5Declarations.part5bAboutYourFinances.questionG
                ,false)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5bAboutYourFinances, "questionG", false)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-g-no"}},[_vm._v("No")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5bAboutYourFinances.questionG
                ),expression:"\n                  section5Declarations.part5bAboutYourFinances.questionG\n                "}],attrs:{"name":"question-g","type":"radio","id":"question-g-yes"},domProps:{"value":true,"checked":_vm._q(
                  _vm.section5Declarations.part5bAboutYourFinances.questionG
                ,true)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5bAboutYourFinances, "questionG", true)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-g-yes"}},[_vm._v("Yes")])])])])]),_c('hr',{staticClass:"urla-break"}),_c('div',{staticClass:"row"},[_vm._m(9),_c('fieldset',{staticClass:"urla-radio radio-inline col-5 col-12-xsmall"},[_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5bAboutYourFinances.questionH
                ),expression:"\n                  section5Declarations.part5bAboutYourFinances.questionH\n                "}],attrs:{"name":"question-h","type":"radio","id":"question-h-no","required":""},domProps:{"value":false,"checked":_vm._q(
                  _vm.section5Declarations.part5bAboutYourFinances.questionH
                ,false)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5bAboutYourFinances, "questionH", false)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-h-no"}},[_vm._v("No")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5bAboutYourFinances.questionH
                ),expression:"\n                  section5Declarations.part5bAboutYourFinances.questionH\n                "}],attrs:{"name":"question-h","type":"radio","id":"question-h-yes"},domProps:{"value":true,"checked":_vm._q(
                  _vm.section5Declarations.part5bAboutYourFinances.questionH
                ,true)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5bAboutYourFinances, "questionH", true)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-h-yes"}},[_vm._v("Yes")])])])])]),_c('hr',{staticClass:"urla-break"}),_c('div',{staticClass:"row"},[_vm._m(10),_c('fieldset',{staticClass:"urla-radio radio-inline col-5 col-12-xsmall"},[_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5bAboutYourFinances.questionI
                ),expression:"\n                  section5Declarations.part5bAboutYourFinances.questionI\n                "}],attrs:{"name":"question-i","type":"radio","id":"question-i-no","required":""},domProps:{"value":false,"checked":_vm._q(
                  _vm.section5Declarations.part5bAboutYourFinances.questionI
                ,false)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5bAboutYourFinances, "questionI", false)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-i-no"}},[_vm._v("No")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5bAboutYourFinances.questionI
                ),expression:"\n                  section5Declarations.part5bAboutYourFinances.questionI\n                "}],attrs:{"name":"question-i","type":"radio","id":"question-i-yes"},domProps:{"value":true,"checked":_vm._q(
                  _vm.section5Declarations.part5bAboutYourFinances.questionI
                ,true)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5bAboutYourFinances, "questionI", true)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-i-yes"}},[_vm._v("Yes")])])])])]),_c('hr',{staticClass:"urla-break"}),_c('div',{staticClass:"row"},[_vm._m(11),_c('fieldset',{staticClass:"urla-radio radio-inline col-5 col-12-xsmall"},[_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5bAboutYourFinances.questionJ
                ),expression:"\n                  section5Declarations.part5bAboutYourFinances.questionJ\n                "}],attrs:{"name":"question-j","type":"radio","id":"question-j-no","required":""},domProps:{"value":false,"checked":_vm._q(
                  _vm.section5Declarations.part5bAboutYourFinances.questionJ
                ,false)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5bAboutYourFinances, "questionJ", false)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-j-no"}},[_vm._v("No")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5bAboutYourFinances.questionJ
                ),expression:"\n                  section5Declarations.part5bAboutYourFinances.questionJ\n                "}],attrs:{"name":"question-j","type":"radio","id":"question-j-yes"},domProps:{"value":true,"checked":_vm._q(
                  _vm.section5Declarations.part5bAboutYourFinances.questionJ
                ,true)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5bAboutYourFinances, "questionJ", true)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-j-yes"}},[_vm._v("Yes")])])])])]),_c('hr',{staticClass:"urla-break"}),_c('div',{staticClass:"row"},[_vm._m(12),_c('fieldset',{staticClass:"urla-radio radio-inline col-5 col-12-xsmall"},[_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5bAboutYourFinances.questionK
                ),expression:"\n                  section5Declarations.part5bAboutYourFinances.questionK\n                "}],attrs:{"name":"question-k","type":"radio","id":"question-k-no","required":""},domProps:{"value":false,"checked":_vm._q(
                  _vm.section5Declarations.part5bAboutYourFinances.questionK
                ,false)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5bAboutYourFinances, "questionK", false)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-k-no"}},[_vm._v("No")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5bAboutYourFinances.questionK
                ),expression:"\n                  section5Declarations.part5bAboutYourFinances.questionK\n                "}],attrs:{"name":"question-k","type":"radio","id":"question-k-yes"},domProps:{"value":true,"checked":_vm._q(
                  _vm.section5Declarations.part5bAboutYourFinances.questionK
                ,true)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5bAboutYourFinances, "questionK", true)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-k-yes"}},[_vm._v("Yes")])])])])]),_c('hr',{staticClass:"urla-break"}),_c('div',{staticClass:"row"},[_vm._m(13),_c('fieldset',{staticClass:"urla-radio radio-inline col-5 col-12-xsmall"},[_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5bAboutYourFinances.questionL
                ),expression:"\n                  section5Declarations.part5bAboutYourFinances.questionL\n                "}],attrs:{"name":"question-l","type":"radio","id":"question-l-no","required":""},domProps:{"value":false,"checked":_vm._q(
                  _vm.section5Declarations.part5bAboutYourFinances.questionL
                ,false)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5bAboutYourFinances, "questionL", false)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-l-no"}},[_vm._v("No")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.section5Declarations.part5bAboutYourFinances.questionL
                ),expression:"\n                  section5Declarations.part5bAboutYourFinances.questionL\n                "}],attrs:{"name":"question-l","type":"radio","id":"question-l-yes"},domProps:{"value":true,"checked":_vm._q(
                  _vm.section5Declarations.part5bAboutYourFinances.questionL
                ,true)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5bAboutYourFinances, "questionL", true)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-l-yes"}},[_vm._v("Yes")])])])])]),_c('hr',{staticClass:"urla-break"}),_c('div',[_c('div',{staticClass:"row"},[_vm._m(14),_c('fieldset',{staticClass:"urla-radio radio-inline col-5 col-12-xsmall"},[_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                    _vm.section5Declarations.part5bAboutYourFinances.questionM
                      .answer1
                  ),expression:"\n                    section5Declarations.part5bAboutYourFinances.questionM\n                      .answer1\n                  "}],attrs:{"name":"question-m","type":"radio","id":"question-m-no","required":""},domProps:{"value":false,"checked":_vm._q(
                    _vm.section5Declarations.part5bAboutYourFinances.questionM
                      .answer1
                  ,false)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5bAboutYourFinances.questionM
                      , "answer1", false)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-m-no"}},[_vm._v("No")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                    _vm.section5Declarations.part5bAboutYourFinances.questionM
                      .answer1
                  ),expression:"\n                    section5Declarations.part5bAboutYourFinances.questionM\n                      .answer1\n                  "}],attrs:{"name":"question-m","type":"radio","id":"question-m-yes"},domProps:{"value":true,"checked":_vm._q(
                    _vm.section5Declarations.part5bAboutYourFinances.questionM
                      .answer1
                  ,true)},on:{"change":function($event){return _vm.$set(_vm.section5Declarations.part5bAboutYourFinances.questionM
                      , "answer1", true)}}}),_c('label',{staticClass:"font-weight-normal small-label",attrs:{"for":"question-m-yes"}},[_vm._v("Yes")])])])])]),_c('transition',{attrs:{"name":"fade"}},[(
              _vm.section5Declarations.part5bAboutYourFinances.questionM.answer1
            )?_c('div',{staticClass:"left-indent"},[_c('fieldset',{staticClass:"checkbox-inline"},[_c('legend',[_c('span',[_vm._v("Identify the type(s) of bankruptcy:")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])]),_c('validation-icon',{attrs:{"isValid":_vm.validations.part5bAboutYourFinances.questionM.answer2}})],1),_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    ),expression:"\n                      section5Declarations.part5bAboutYourFinances.questionM\n                        .answer2\n                    "}],attrs:{"type":"checkbox","id":"chapter-7","value":"Chapter 7"},domProps:{"checked":Array.isArray(
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    )?_vm._i(
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    ,"Chapter 7")>-1:(
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    )},on:{"change":[function($event){var $$a=
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="Chapter 7",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.section5Declarations.part5bAboutYourFinances.questionM
                        , "answer2", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.section5Declarations.part5bAboutYourFinances.questionM
                        , "answer2", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.section5Declarations.part5bAboutYourFinances.questionM
                        , "answer2", $$c)}},function($event){return _vm.validateQuestionM2()}]}}),_c('label',{staticClass:"medium-label",attrs:{"for":"chapter-7"}},[_vm._v("Chapter 7")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    ),expression:"\n                      section5Declarations.part5bAboutYourFinances.questionM\n                        .answer2\n                    "}],attrs:{"type":"checkbox","id":"chapter-11","value":"Chapter 11"},domProps:{"checked":Array.isArray(
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    )?_vm._i(
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    ,"Chapter 11")>-1:(
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    )},on:{"change":[function($event){var $$a=
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="Chapter 11",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.section5Declarations.part5bAboutYourFinances.questionM
                        , "answer2", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.section5Declarations.part5bAboutYourFinances.questionM
                        , "answer2", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.section5Declarations.part5bAboutYourFinances.questionM
                        , "answer2", $$c)}},function($event){return _vm.validateQuestionM2()}]}}),_c('label',{staticClass:"medium-label",attrs:{"for":"chapter-11"}},[_vm._v("Chapter 11")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    ),expression:"\n                      section5Declarations.part5bAboutYourFinances.questionM\n                        .answer2\n                    "}],attrs:{"type":"checkbox","id":"chapter-12","value":"Chapter 12"},domProps:{"checked":Array.isArray(
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    )?_vm._i(
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    ,"Chapter 12")>-1:(
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    )},on:{"change":[function($event){var $$a=
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="Chapter 12",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.section5Declarations.part5bAboutYourFinances.questionM
                        , "answer2", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.section5Declarations.part5bAboutYourFinances.questionM
                        , "answer2", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.section5Declarations.part5bAboutYourFinances.questionM
                        , "answer2", $$c)}},function($event){return _vm.validateQuestionM2()}]}}),_c('label',{staticClass:"medium-label",attrs:{"for":"chapter-12"}},[_vm._v("Chapter 12")])]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    ),expression:"\n                      section5Declarations.part5bAboutYourFinances.questionM\n                        .answer2\n                    "}],attrs:{"type":"checkbox","id":"chapter-13","value":"Chapter 13"},domProps:{"checked":Array.isArray(
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    )?_vm._i(
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    ,"Chapter 13")>-1:(
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    )},on:{"change":[function($event){var $$a=
                      _vm.section5Declarations.part5bAboutYourFinances.questionM
                        .answer2
                    ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="Chapter 13",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.section5Declarations.part5bAboutYourFinances.questionM
                        , "answer2", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.section5Declarations.part5bAboutYourFinances.questionM
                        , "answer2", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.section5Declarations.part5bAboutYourFinances.questionM
                        , "answer2", $$c)}},function($event){return _vm.validateQuestionM2()}]}}),_c('label',{staticClass:"medium-label",attrs:{"for":"chapter-13"}},[_vm._v("Chapter 13")])])])])]):_vm._e()])],1)]),_c('div',{staticClass:"clearfix"},[_c('button',{staticClass:"primary wizard__back pull-left",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.goBack()}}},[_vm._m(15)]),_vm._m(16)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"instruction"},[_vm._v(" Required fields are followed by "),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticStyle:{"display":"inline"},attrs:{"for":"question-a-1"}},[_vm._v(" A. "),_c('span',{staticClass:"font-weight-normal"},[_vm._v("Will you occupy the property as your primary residence?")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticStyle:{"display":"inline"},attrs:{"for":"question-b"}},[_vm._v(" B. "),_c('span',{staticClass:"font-weight-normal"},[_vm._v("If this is a Purchase Transaction: Do you have a family relationship or business affiliation with the seller of the property?")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticStyle:{"display":"inline"},attrs:{"for":"question-c-1"}},[_vm._v(" C. "),_c('span',{staticClass:"font-weight-normal"},[_vm._v(" Are you borrowing any money for this real estate transaction "),_c('span',{staticClass:"font-style-italic"},[_vm._v("(eg., money for your closing costs or down payment)")]),_vm._v(" or obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this loan application? ")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticStyle:{"display":"inline"},attrs:{"for":"question-d-1"}},[_vm._v(" D. "),_c('span',{staticClass:"font-weight-normal"},[_vm._v("1. Have you or will you be applying for a mortgage loan on another property (not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application?")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticStyle:{"display":"inline"},attrs:{"for":"question-d-2"}},[_c('span',{staticClass:"font-weight-normal"},[_vm._v(" 2. Have you or will you be applying for any new credit "),_c('span',{staticClass:"font-style-italic"},[_vm._v("(e.g., installment loan, credit card, etc.)")]),_vm._v("on or befor closing this loan that is not disclosed on this application? ")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticStyle:{"display":"inline"},attrs:{"for":"question-e"}},[_vm._v(" E. "),_c('span',{staticClass:"font-weight-normal"},[_vm._v(" Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid through your property taxes "),_c('span',{staticClass:"font-style-italic"},[_vm._v("(e.g., the Property Assessed Clean Energy Program)")]),_vm._v("? ")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticStyle:{"display":"inline"},attrs:{"for":"question-f"}},[_vm._v(" F. "),_c('span',{staticClass:"font-weight-normal"},[_vm._v("Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application?")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticStyle:{"display":"inline"},attrs:{"for":"question-g"}},[_vm._v(" G. "),_c('span',{staticClass:"font-weight-normal"},[_vm._v("Are there any outstanding judgements against you?")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticStyle:{"display":"inline"},attrs:{"for":"question-h"}},[_vm._v(" H. "),_c('span',{staticClass:"font-weight-normal"},[_vm._v("Are you currently delinquent or in default on a federal debt?")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticStyle:{"display":"inline"},attrs:{"for":"question-i"}},[_vm._v(" I. "),_c('span',{staticClass:"font-weight-normal"},[_vm._v("Are you a party to a lawsuit in which you potentially have any personal financial liability?")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticStyle:{"display":"inline"},attrs:{"for":"question-j"}},[_vm._v(" J. "),_c('span',{staticClass:"font-weight-normal"},[_vm._v("Have you conveyed title to any property in lieu of foreclosure in the past 7 years?")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticStyle:{"display":"inline"},attrs:{"for":"question-k"}},[_vm._v(" K. "),_c('span',{staticClass:"font-weight-normal"},[_vm._v("Within the past 7 years, have you completed a pre-foreclosure sale or short sale, whereby the property was sold to a third party and the Lender agreed to accept less than the outstanding mortgage balance due?")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticStyle:{"display":"inline"},attrs:{"for":"question-l"}},[_vm._v(" L. "),_c('span',{staticClass:"font-weight-normal"},[_vm._v("Have you had property foreclosed upon in the last 7 years?")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"col-7 col-12-xsmall"},[_c('label',{staticStyle:{"display":"inline"},attrs:{"for":"question-m"}},[_vm._v(" M. "),_c('span',{staticClass:"font-weight-normal"},[_vm._v("Have you declared bankruptcy within the past 7 years?")]),_c('strong',[_c('abbr',{staticClass:"required",attrs:{"title":"required"}},[_vm._v("*")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"fa fa-arrow-left fa-lg"}),_vm._v(" back ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"primary wizard__next pull-right",attrs:{"type":"submit"}},[_c('span',[_c('i',{staticClass:"fa fa-floppy-o",attrs:{"aria-hidden":"true"}}),_vm._v(" save "),_c('i',{staticClass:"fa fa-arrow-right fa-lg",attrs:{"aria-hidden":"true"}})])])
}]

export { render, staticRenderFns }