export default {
  data() {
    return {
      // sample
      // test: null
    };
  },
  methods: {
    // method to deep clone object
    cloneObject: function(obj) {
      var clone = {};
      for (var i in obj) {
        if (obj[i] != null && typeof obj[i] == "object")
          clone[i] = this.cloneObject(obj[i]);
        else clone[i] = obj[i];
      }
      return clone;
    },

    cloneArray: function(target, source) {
      // for empty array
      if (source.length === 0) {
        target = [];
        return;
      }

      // clone each element in array
      for (var i = 0; i < source.length; i++) {
        if (source[i] === null) continue;
        else if (Array.isArray(source[i])) {
          if (target[i] === undefined) target[i] = [];
          this.cloneArray(target[i], source[i]);
        } else if (typeof source[i] === "object") {
          if (target[i] === undefined) target[i] = {};
          target[i] = this.parseSection(target[i], source[i]);
        } else target[i] = source[i];
      }
    },

    // This method is used to copy the values of all enumerable own properties from source to target
    parseSection: function(target, source) {
      // return target
      // return Object.assign(target, source);

      for (var i in source) {
        if (source[i] === null) {
          continue;
        } else if (Array.isArray(source[i])) {
          // clone array
          if (target[i] === undefined) target[i] = [];
          this.cloneArray(target[i], source[i]);
        } else if (typeof source[i] === "object") {
          if (target[i] === undefined) target[i] = {};
          target[i] = this.parseSection(target[i], source[i]);
        } else target[i] = source[i];
      }
      return target;
    },

    getNullArray: function(source) {
      // variable to initialize
      var duplicateArray = [];

      source.forEach((element) => {
        if (typeof element !== "object") duplicateArray.push(null);
        else duplicateArray.push(this.initializeToNull(element));
      });

      return duplicateArray;
    },

    getNullObject: function(source) {
      if (source === null) return null;

      // variable to initialize
      var duplicateObject = {};

      Object.keys(source).forEach((key) => {
        if (typeof source !== "object") duplicateObject[key] = null;
        else duplicateObject[key] = this.initializeToNull(source[key]);
      });

      return duplicateObject;
    },

    initializeToNull: function(source) {
      // return null if input is not object
      if (typeof source !== "object") return null;

      // parse if input is array
      if (Array.isArray(source)) return this.getNullArray(source);

      if (!Array.isArray(source) && typeof source === "object")
        return this.getNullObject(source);
    },

    getEmptyArray: function(source) {
      // variable to initialize
      var duplicateArray = [];

      source.forEach((element) => {
        if (typeof element !== "object") duplicateArray.push([]);
        else duplicateArray.push(this.initializeToNull(element));
      });

      return duplicateArray;
    },

    getEmptyArrayObject: function(source) {
      if (source === null) return [];

      // variable to initialize
      var duplicateObject = {};

      Object.keys(source).forEach((key) => {
        if (typeof source !== "object") duplicateObject[key] = [];
        else duplicateObject[key] = this.initializeToNull(source[key]);
      });

      return duplicateObject;
    },

    initializeToEmptyArray: function(source) {
      // return null if input is not object
      if (typeof source !== "object") return [];

      // parse if input is array
      if (Array.isArray(source)) return this.getNullArray(source);

      if (!Array.isArray(source) && typeof source === "object")
        return this.getNullObject(source);
    },

    isoToLocalDateTime: function(isoDate) {
      var dateTemp = new Date(isoDate);
      const MONTHS = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return (
        MONTHS[dateTemp.getMonth()] +
        " " +
        dateTemp.getDate() +
        ", " +
        dateTemp.getFullYear() +
        " " +
        dateTemp.toLocaleTimeString().toUpperCase()
      );
    },
  },
};
