<template>
  <div ref="sectionSeven" class="urla-section">
    <h2>Demographic Information</h2>
    <!-- Section description -->
    <p class="urla-section-description">
      This section asks about your ethnicity, sex, and race.
    </p>

    <!-- Required fields instruction -->
    <p class="instruction">
      Required fields are followed by
      <strong> <abbr class="required" title="required">*</abbr> </strong>.
    </p>

    <!-- Form for section 7 of URL Application -->
    <form
      id="section-7-form"
      v-on:submit.prevent="submitSectionSeven"
      method="post"
    >
      <div class="row">
        <!-- Ethnicity -->
        <div class="col-4 col-12-xsmall">
          <div>
            <p>
              <label for="ethnicity">
                Ethnicity:
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
            </p>
            <fieldset class="urla-radio">
              <ul>
                <li>
                  <input
                    name="ethnicity"
                    type="radio"
                    id="hispanic-or-latino"
                    value="Hispanic or Latino"
                    v-model="
                      section7DemographicInformation.customerEthnicity.ethnicity
                    "
                    required
                  />
                  <label class="font-weight-normal" for="hispanic-or-latino"
                    >Hispanic or Latino</label
                  >
                </li>
                <li>
                  <input
                    name="ethnicity"
                    type="radio"
                    id="not-hispanic-or-latino"
                    value="Not Hispanic or Latino"
                    v-model="
                      section7DemographicInformation.customerEthnicity.ethnicity
                    "
                  />
                  <label class="font-weight-normal" for="not-hispanic-or-latino"
                    >Not Hispanic or Latino</label
                  >
                </li>
                <li>
                  <input
                    name="ethnicity"
                    type="radio"
                    id="ethnicity-do-not-wish-to-provide"
                    value="I do not wish to provide this information"
                    v-model="
                      section7DemographicInformation.customerEthnicity.ethnicity
                    "
                  />
                  <label
                    class="font-weight-normal"
                    for="ethnicity-do-not-wish-to-provide"
                    style="width: auto"
                    >I do not wish to provide this information</label
                  >
                </li>
              </ul>
            </fieldset>
          </div>

          <!-- Sub Ethnicity -->
          <div class="left-indent">
            <transition name="fade">
              <div
                v-if="
                  section7DemographicInformation.customerEthnicity.ethnicity ===
                    'Hispanic or Latino'
                "
              >
                <hr class="urla-break" />
                <p>
                  <label class="hidden-label" for="sub-ethnicity">
                    Sub Ethnicity
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                </p>
                <fieldset class="urla-radio">
                  <ul>
                    <li>
                      <input
                        name="sub-ethnicity"
                        type="radio"
                        id="mexican"
                        value="Mexican"
                        v-model="
                          section7DemographicInformation.customerEthnicity
                            .subEthnicity
                        "
                        required
                      />
                      <label class="font-weight-normal" for="mexican"
                        >Mexican</label
                      >
                    </li>
                    <li>
                      <input
                        name="sub-ethnicity"
                        type="radio"
                        id="puerto-rican"
                        value="Puerto Rican"
                        v-model="
                          section7DemographicInformation.customerEthnicity
                            .subEthnicity
                        "
                      />
                      <label class="font-weight-normal" for="puerto-rican"
                        >Puerto Rican</label
                      >
                    </li>
                    <li>
                      <input
                        name="sub-ethnicity"
                        type="radio"
                        id="cuban"
                        value="Cuban"
                        v-model="
                          section7DemographicInformation.customerEthnicity
                            .subEthnicity
                        "
                      />
                      <label class="font-weight-normal" for="cuban"
                        >Cuban</label
                      >
                    </li>
                    <li>
                      <input
                        name="sub-ethnicity"
                        type="radio"
                        id="other-hispanic-or-latino"
                        value="Other Hispanic or Latino"
                        v-model="
                          section7DemographicInformation.customerEthnicity
                            .subEthnicity
                        "
                      />
                      <label
                        class="font-weight-normal"
                        for="other-hispanic-or-latino"
                        >Other Hispanic or Latino</label
                      >
                    </li>
                  </ul>
                </fieldset>
              </div>
            </transition>

            <!-- Other Sub Ethnicity -->
            <transition name="fade">
              <div
                class="left-indent"
                v-if="
                  section7DemographicInformation.customerEthnicity.ethnicity ===
                    'Hispanic or Latino' &&
                    section7DemographicInformation.customerEthnicity
                      .subEthnicity === 'Other Hispanic or Latino'
                "
              >
                <p class="col-6 col-12-xsmall left-indent">
                  <label for="other-hispanic-or-latino" class="hidden-label">
                    <span>Please specify:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                  <input
                    id="other-hispanic-or-latino"
                    class="medium-input"
                    type="text"
                    placeholder="e.g. Argentinean"
                    v-model="otherHispanicOrLatino"
                    required
                  />
                </p>
              </div>
            </transition>
          </div>
        </div>

        <!-- Sex -->
        <div class="col-4 col-12-xsmall">
          <div>
            <p>
              <label for="sex">
                Sex:
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
            </p>
            <fieldset class="urla-radio">
              <ul>
                <li>
                  <input
                    name="sex"
                    type="radio"
                    id="female"
                    value="Female"
                    v-model="section7DemographicInformation.customerSex"
                    required
                  />
                  <label
                    class="font-weight-normal"
                    for="female"
                    style="width: auto"
                    >Female</label
                  >
                </li>
                <li>
                  <input
                    name="sex"
                    type="radio"
                    id="male"
                    value="Male"
                    v-model="section7DemographicInformation.customerSex"
                    required
                  />
                  <label
                    class="font-weight-normal"
                    for="male"
                    style="width: auto"
                    >Male</label
                  >
                </li>
                <li>
                  <input
                    name="sex"
                    type="radio"
                    id="sex-do-not-wish-to-provide"
                    value="I do not wish to provide this information"
                    v-model="section7DemographicInformation.customerSex"
                    required
                  />
                  <label
                    class="font-weight-normal"
                    for="sex-do-not-wish-to-provide"
                    style="width: auto"
                    >I do not wish to provide this information</label
                  >
                </li>
              </ul>
            </fieldset>
          </div>
        </div>

        <!-- Race -->
        <div class="col-4 col-12-xsmall">
          <div>
            <p>
              <label for="race">
                Race:
                <strong>
                  <abbr class="required" title="required">*</abbr>
                </strong>
              </label>
            </p>
            <fieldset class="urla-radio">
              <ul>
                <li>
                  <input
                    name="race"
                    type="radio"
                    id="american-indian-or-alaska-native"
                    value="American Indian or Alaska Native"
                    v-model="section7DemographicInformation.customerRace.race"
                    required
                  />
                  <label
                    class="font-weight-normal"
                    for="american-indian-or-alaska-native"
                    style="width: auto"
                    >American Indian or Alaska Native</label
                  >
                </li>
                <li>
                  <input
                    name="race"
                    type="radio"
                    id="asian"
                    value="Asian"
                    v-model="section7DemographicInformation.customerRace.race"
                  />
                  <label class="font-weight-normal" for="asian">Asian</label>
                </li>
                <li>
                  <input
                    name="race"
                    type="radio"
                    id="black-or-african-american"
                    value="Black or African American"
                    v-model="section7DemographicInformation.customerRace.race"
                  />
                  <label
                    class="font-weight-normal"
                    for="black-or-african-american"
                    >Black or African American</label
                  >
                </li>
                <li>
                  <input
                    name="race"
                    type="radio"
                    id="native-hawaiian-or-other-pacific-islander"
                    value="Native Hawaiian or Other Pacific Islander"
                    v-model="section7DemographicInformation.customerRace.race"
                  />
                  <label
                    class="font-weight-normal"
                    for="native-hawaiian-or-other-pacific-islander"
                    style="width: auto"
                    >Native Hawaiian or Other Pacific Islander</label
                  >
                </li>
                <li>
                  <input
                    name="race"
                    type="radio"
                    id="white"
                    value="White"
                    v-model="section7DemographicInformation.customerRace.race"
                  />
                  <label class="font-weight-normal" for="white">White</label>
                </li>
                <li>
                  <input
                    name="race"
                    type="radio"
                    id="race-do-not-wish-to-provide"
                    value="I do not wish to provide this information"
                    v-model="section7DemographicInformation.customerRace.race"
                  />
                  <label
                    class="font-weight-normal"
                    for="race-do-not-wish-to-provide"
                    style="width: auto"
                    >I do not wish to provide this information</label
                  >
                </li>
              </ul>
            </fieldset>
          </div>

          <!-- Sub Race -->
          <div class="left-indent">
            <!-- Asian -->
            <transition name="fade">
              <div
                v-if="
                  section7DemographicInformation.customerRace.race === 'Asian'
                "
              >
                <hr class="urla-break" />
                <p>
                  <label class="hidden-label" for="asian-sub-race">
                    Asian Sub Race
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                </p>
                <fieldset class="urla-radio">
                  <ul>
                    <li>
                      <input
                        name="asian-sub-race"
                        type="radio"
                        id="asian-indian"
                        value="Asian Indian"
                        v-model="
                          section7DemographicInformation.customerRace.subRace
                        "
                        required
                      />
                      <label class="font-weight-normal" for="asian-indian"
                        >Asian Indian</label
                      >
                    </li>
                    <li>
                      <input
                        name="asian-sub-race"
                        type="radio"
                        id="chinese"
                        value="Chinese"
                        v-model="
                          section7DemographicInformation.customerRace.subRace
                        "
                      />
                      <label class="font-weight-normal" for="chinese"
                        >Chinese</label
                      >
                    </li>
                    <li>
                      <input
                        name="asian-sub-race"
                        type="radio"
                        id="filipino"
                        value="Filipino"
                        v-model="
                          section7DemographicInformation.customerRace.subRace
                        "
                      />
                      <label class="font-weight-normal" for="filipino"
                        >Filipino</label
                      >
                    </li>
                    <li>
                      <input
                        name="asian-sub-race"
                        type="radio"
                        id="japanese"
                        value="Japanese"
                        v-model="
                          section7DemographicInformation.customerRace.subRace
                        "
                      />
                      <label class="font-weight-normal" for="japanese"
                        >Japanese</label
                      >
                    </li>
                    <li>
                      <input
                        name="asian-sub-race"
                        type="radio"
                        id="korean"
                        value="Korean"
                        v-model="
                          section7DemographicInformation.customerRace.subRace
                        "
                      />
                      <label class="font-weight-normal" for="korean"
                        >Korean</label
                      >
                    </li>
                    <li>
                      <input
                        name="asian-sub-race"
                        type="radio"
                        id="vietnamese"
                        value="Vietnamese"
                        v-model="
                          section7DemographicInformation.customerRace.subRace
                        "
                      />
                      <label class="font-weight-normal" for="vietnamese"
                        >Vietnamese</label
                      >
                    </li>
                    <li>
                      <input
                        name="asian-sub-race"
                        type="radio"
                        id="other-asian"
                        value="Other Asian"
                        v-model="
                          section7DemographicInformation.customerRace.subRace
                        "
                      />
                      <label class="font-weight-normal" for="other-asian"
                        >Other Asian</label
                      >
                    </li>
                  </ul>
                </fieldset>
              </div>
            </transition>

            <!-- Other Asian groups -->
            <transition name="fade">
              <div
                class="left-indent"
                v-if="
                  section7DemographicInformation.customerRace.race ===
                    'Asian' &&
                    section7DemographicInformation.customerRace.subRace ===
                      'Other Asian'
                "
              >
                <p class="col-6 col-12-xsmall left-indent">
                  <label for="other-asian-group" class="hidden-label">
                    <span>Please specify:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                  <input
                    id="other-asian-group"
                    class="medium-input"
                    type="text"
                    placeholder="e.g. Thai"
                    v-model="otherAsian"
                    required
                  />
                </p>
              </div>
            </transition>

            <!-- Native Hawaiian or Other Pacific Islander -->
            <transition name="fade">
              <div
                v-if="
                  section7DemographicInformation.customerRace.race ===
                    'Native Hawaiian or Other Pacific Islander'
                "
              >
                <hr class="urla-break" />
                <p>
                  <label class="hidden-label" for="native-hawaiian-sub-race">
                    Native Hawaiian Sub Race
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                </p>
                <fieldset class="urla-radio">
                  <ul>
                    <li>
                      <input
                        name="native-hawaiian-sub-race"
                        type="radio"
                        id="native-hawaiian"
                        value="Native Hawaiian"
                        v-model="
                          section7DemographicInformation.customerRace.subRace
                        "
                        required
                      />
                      <label class="font-weight-normal" for="native-hawaiian"
                        >Native Hawaiian</label
                      >
                    </li>
                    <li>
                      <input
                        name="native-hawaiian-sub-race"
                        type="radio"
                        id="guamanian-or-chamorro"
                        value="Guamanian or Chamorro"
                        v-model="
                          section7DemographicInformation.customerRace.subRace
                        "
                      />
                      <label
                        class="font-weight-normal"
                        for="guamanian-or-chamorro"
                        >Guamanian or Chamorro</label
                      >
                    </li>
                    <li>
                      <input
                        name="native-hawaiian-sub-race"
                        type="radio"
                        id="samoan"
                        value="Samoan"
                        v-model="
                          section7DemographicInformation.customerRace.subRace
                        "
                      />
                      <label class="font-weight-normal" for="samoan"
                        >Samoan</label
                      >
                    </li>
                    <li>
                      <input
                        name="native-hawaiian-sub-race"
                        type="radio"
                        id="other-pacific-islander"
                        value="Other Pacific Islander"
                        v-model="
                          section7DemographicInformation.customerRace.subRace
                        "
                      />
                      <label
                        class="font-weight-normal"
                        for="other-pacific-islander"
                        >Other Pacific Islander</label
                      >
                    </li>
                  </ul>
                </fieldset>
              </div>
            </transition>

            <!-- Other Pasific Islander groups -->
            <transition name="fade">
              <div
                class="left-indent"
                v-if="
                  section7DemographicInformation.customerRace.race ===
                    'Native Hawaiian or Other Pacific Islander' &&
                    section7DemographicInformation.customerRace.subRace ===
                      'Other Pacific Islander'
                "
              >
                <p class="col-6 col-12-xsmall left-indent">
                  <label for="other-pacific-islander-race" class="hidden-label">
                    <span>Please specify:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                  <input
                    id="other-pacific-islander-race"
                    class="medium-input"
                    type="text"
                    placeholder="e.g. Fijian"
                    v-model="otherPacificIslander"
                    required
                  />
                </p>
              </div>
            </transition>
          </div>
        </div>
      </div>

      <!-- clearfix for back and save -->
      <div class="clearfix">
        <!-- back button -->
        <button
          type="button"
          class="primary wizard__back pull-left"
          @click.prevent="goBack()"
        >
          <span>
            <i class="fa fa-arrow-left fa-lg"></i>
            back
          </span>
        </button>
        <!-- save & continue button -->
        <button type="submit" class="primary wizard__next pull-right">
          <span>
            <i class="fa fa-floppy-o" aria-hidden="true"></i>
            save
            <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { bus } from "@/main";
import validationsMixin from "@/mixins/validationsMixin.js";
import inputMasksMixin from "@/mixins/inputMasksMixin.js";
import jsonUtilMixin from "@/mixins/jsonUtilMixin.js";

export default {
  name: "Section7",
  // date received from parent component
  props: ["sourceSection7"],
  // mixins used
  mixins: [validationsMixin, inputMasksMixin, jsonUtilMixin],
  data() {
    return {
      otherHispanicOrLatino: null,
      otherAsian: null,
      otherPacificIslander: null,
      section7DemographicInformation: {
        currentPage: 5,
        loanApplicationProgress: 700,
        sectionName: "section7DemographicInformation",
        customerEthnicity: {
          ethnicity: null,
          subEthnicity: null,
        },
        customerSex: null,
        customerRace: {
          race: null,
          subRace: null,
        },
        demographicInfoProvidedMeans: null,
      },
      validations: null,
      errors: null,
    };
  },

  methods: {
    goBack: function() {
      // emit event to go back with current page number
      bus.$emit("goBack", this.section7DemographicInformation.currentPage);
    },
    submitSectionSeven: function() {
      // check for other Hispanic or Latino sub ethnicity
      if (
        this.section7DemographicInformation.customerEthnicity.ethnicity ===
          "Hispanic or Latino" &&
        this.section7DemographicInformation.customerEthnicity.subEthnicity ===
          "Other Hispanic or Latino"
      )
        this.section7DemographicInformation.customerEthnicity.subEthnicity = this.otherHispanicOrLatino;

      // check for other Asian in sub race
      if (
        this.section7DemographicInformation.customerRace.race === "Asian" &&
        this.section7DemographicInformation.customerRace.subRace ===
          "Other Asian"
      )
        this.section7DemographicInformation.customerRace.subRace ===
          this.otherAsian;

      // check for other Pacific Islander in sub race
      if (
        this.section7DemographicInformation.customerRace.race ===
          "Native Hawaiian or Other Pacific Islander" &&
        this.section7DemographicInformation.customerRace.subRace ===
          "Other Pacific Islander"
      )
        this.section7DemographicInformation.customerRace.subRace = this.otherPacificIslander;

      // validate form
      bus.$emit("postUrla", this.section7DemographicInformation);
    },
  },

  mounted: function() {
    // set default values
    this.section7DemographicInformation.demographicInfoProvidedMeans =
      "Email or Internet";

    // scroll to top of page
    this.$scrollTo(this.$refs.sectionSeven, {
      duration: 500,
      easing: "ease",
      offset: -200,
    });
  },

  created: function() {
    // copy data received from urla into current section
    this.section7DemographicInformation = this.parseSection(
      this.section7DemographicInformation,
      this.sourceSection7
    );

    // initialize validations object to null
    this.validations = this.initializeToNull(
      this.section7DemographicInformation
    );

    // initialize errors object to empty array
    this.errors = this.initializeToEmptyArray(
      this.section7DemographicInformation
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
