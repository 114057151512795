<template>
  <div>
    <div class="wrapper">
      <!-- Main -->
      <section class="main">
        <!-- Banner -->
        <!-- Blurb -->
        <section>
          <h1>Resources</h1>

          <ul class="resources-list">
            <li>
              <a
                href="https://files.consumerfinance.gov/f/documents/cfpb_your-home-loan-toolkit.pdf"
                target="_blank"
              >
                Home loan tool kit
                <i class="icon fa-external-link"></i>
              </a>
            </li>
          </ul>
        </section>

        <!-- Features -->
        <!-- Spotlights -->
        <section class="spotlights alt"></section>
      </section>

      <!-- Sidebar -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Resources"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li * {
  color: black;
  border-bottom: none;
  font-size: 18px;
}
</style>