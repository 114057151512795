var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{attrs:{"id":"header"}},[_c('span',{staticClass:"logo"},[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_vm._v(" American Smart Lending ")])],1),_c('nav',{attrs:{"id":"nav"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_vm._v("Home")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'urla' }}},[_vm._v("Apply Now")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'upload' }}},[_vm._v("Upload")])],1),_c('li',{ref:"dropdown",staticClass:"link depth-0",on:{"mouseover":_vm.expandDropdown,"mouseleave":_vm.collapseDropdown}},[_vm._v(" Services "),_c('span',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('li',[_c('router-link',{attrs:{"to":{ name: 'faq' }}},[_vm._v("FAQ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'contact' }}},[_vm._v("Contact")])],1),_c('li',{staticClass:"link depth-0",on:{"click":function($event){return _vm.$router.push({ name: 'view-reviews' })}}},[_vm._v(" Reviews ")]),_c('li',[_c('router-link',{attrs:{"to":{ name: 'success-stories' }}},[_vm._v("Success Stories")])],1)])]),_c('a',{staticClass:"navPanelToggle",attrs:{"href":"#navPanel"},on:{"click":function($event){_vm.isVisible = true}}},[_vm._v("Menu")])]),_c('div',{class:{ visible: _vm.isVisible },attrs:{"id":"navPanel"}},[_c('a',{staticClass:"close",attrs:{"href":"#navPanel"},on:{"click":function($event){_vm.isVisible = false}}}),_c('nav',{attrs:{"id":"nav"}},[_c('a',{staticClass:"link depth-0 nav-link",on:{"click":function($event){_vm.isVisible = false;
          _vm.$router.push({ name: 'home' });}}},[_vm._v("Home")]),_c('a',{staticClass:"link depth-0 nav-link"},[_vm._v("Services")]),_c('a',{staticClass:"link depth-1 nav-link",on:{"click":function($event){_vm.isVisible = false;
          _vm.$router.push({ name: 'amortization-calculator' });}}},[_c('span',{staticClass:"indent-1"}),_vm._v(" Amortization Calculator ")]),_c('a',{staticClass:"link depth-1 nav-link",on:{"click":function($event){_vm.isVisible = false;
          _vm.$router.push({ name: 'upload' });}}},[_c('span',{staticClass:"indent-1"}),_vm._v(" Upload Files ")]),_c('a',{staticClass:"link depth-1 nav-link",on:{"click":function($event){_vm.isVisible = false;
          _vm.$router.push({ name: 'new-or-continue' });}}},[_c('span',{staticClass:"indent-1"}),_vm._v(" Apply for Mortgage/Refinance ")]),_c('a',{staticClass:"link depth-0 nav-link",on:{"click":function($event){_vm.isVisible = false;
          _vm.$router.push({ name: 'faq' });}}},[_vm._v("FAQ")]),_c('a',{staticClass:"link depth-0 nav-link",on:{"click":function($event){_vm.isVisible = false;
          _vm.$router.push({ name: 'contact' });}}},[_vm._v("Contact")]),_c('a',{staticClass:"link depth-0 nav-link",on:{"click":function($event){_vm.isVisible = false;
          _vm.$router.push({ name: 'view-reviews' });}}},[_vm._v("Reviews")]),_c('a',{staticClass:"link depth-0 nav-link",on:{"click":function($event){_vm.isVisible = false;
          _vm.$router.push({ name: 'success-stories' });}}},[_vm._v("Success Stories")])])]),_c('transition',{attrs:{"name":"fade","tag":"ul"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.dropdownVisible),expression:"dropdownVisible"}],ref:"dropdownList",staticClass:"dropotron level-0 right",staticStyle:{"-moz-user-select":"none","position":"fixed","z-index":"1000","top":"76.8667px","opacity":"1","height":"13.75em"},on:{"mouseover":_vm.expandDropdown,"mouseleave":_vm.collapseDropdown}},[_c('li',{staticStyle:{"white-space":"nowrap"}},[_c('a',{staticClass:"link",staticStyle:{"display":"block"},on:{"click":function($event){return _vm.$router.push({ name: 'amortization-calculator' })}}},[_vm._v("Amortization Calculator")])]),_c('li',{staticStyle:{"white-space":"nowrap"}},[_c('a',{staticClass:"link",staticStyle:{"display":"block"},on:{"click":function($event){return _vm.$router.push({ name: 'upload' })}}},[_vm._v("Upload Files")])]),_c('li',{staticStyle:{"white-space":"nowrap"}},[_c('a',{staticClass:"link",staticStyle:{"display":"block"},on:{"click":function($event){return _vm.$router.push({ name: 'new-or-continue' })}}},[_vm._v("Apply for Mortgage/Refinance")])]),_c('li',{staticStyle:{"white-space":"nowrap"}},[_c('a',{staticClass:"link",staticStyle:{"display":"block"},on:{"click":function($event){return _vm.$router.push({ name: 'contact' })}}},[_vm._v("Licensing information")])]),_c('li',{staticStyle:{"white-space":"nowrap"}},[_c('a',{staticClass:"link",staticStyle:{"display":"block"},on:{"click":function($event){return _vm.$router.push({ name: 'disclosure' })}}},[_vm._v("Important Disclosure")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }