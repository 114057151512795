<template>
  <div ref="sectionEight" class="urla-section">
    <h2>Loan Originator Information</h2>

    <!-- Section description -->
    <p class="urla-section-description">
      This section asks about Loan Originator and Reference.
    </p>

    <!-- Required fields instruction -->
    <p class="instruction">
      Required fields are followed by
      <strong> <abbr class="required" title="required">*</abbr> </strong>.
    </p>

    <!-- Form for section 7 of URL Application -->
    <form
      id="section-8-form"
      v-on:submit.prevent="nextPage"
      method="post"
    >
      <div class="loan-originator row">
        <div class="col-4 col-12-xsmall">
          <p>
            <label for="loan-officer">
              Loan Officer:
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
          </p>
          <fieldset class="urla-radio">
            <ul>
              <li>
                <input
                  name="loan-officer"
                  type="radio"
                  id="kc"
                  value="KC"
                  v-model="section8LoanOriginatorInformation.loanOriginatorName"
                />
                <label class="font-weight-normal" for="kc" style="width: auto"
                  >KC Kadaru</label
                >
              </li>
              <li>
                <input
                  name="loan-officer"
                  type="radio"
                  id="doug"
                  value="Doug"
                  v-model="section8LoanOriginatorInformation.loanOriginatorName"
                />
                <label class="font-weight-normal" for="doug" style="width: auto"
                  >Doug Tuckett</label
                >
              </li>
              <li>
                <input
                  name="loan-officer"
                  type="radio"
                  id="harry"
                  value="Harry"
                  v-model="section8LoanOriginatorInformation.loanOriginatorName"
                />
                <label
                  class="font-weight-normal"
                  for="harry"
                  style="width: auto"
                  >Harry Dunmire</label
                >
              </li>
              <li>
                <input
                  name="loan-officer"
                  type="radio"
                  id="pradip"
                  value="Pradip"
                  v-model="section8LoanOriginatorInformation.loanOriginatorName"
                />
                <label
                  class="font-weight-normal"
                  for="pradip"
                  style="width: auto"
                  >Pradip Thakkar</label
                >
              </li>
              <li>
                <input
                  name="loan-officer"
                  type="radio"
                  id="ramesh"
                  value="Ramesh"
                  v-model="section8LoanOriginatorInformation.loanOriginatorName"
                />
                <label
                  class="font-weight-normal"
                  for="ramesh"
                  style="width: auto"
                  >Ramesh Menon</label
                >
              </li>
              <li>
                <input
                  name="loan-officer"
                  type="radio"
                  id="anjani"
                  value="Anjani"
                  v-model="section8LoanOriginatorInformation.loanOriginatorName"
                />
                <label
                  class="font-weight-normal"
                  for="anjani"
                  style="width: auto"
                  >Anjani Sattiraju</label
                >
              </li>
              <li>
                <input
                  name="loan-officer"
                  type="radio"
                  id="venu"
                  value="Venu"
                  v-model="section8LoanOriginatorInformation.loanOriginatorName"
                />
                <label
                  class="font-weight-normal"
                  for="venu"
                  style="width: auto"
                  >Venu Arety</label
                >
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="col-4 col-12-xsmall">
          <p class="reffered-by-heading">Referred By:</p>
          <input
            type="text"
            v-model="section8LoanOriginatorInformation.referredBy"
            placeholder="E.g. Elon Musk, Google"
          />
        </div>
      </div>

      <!-- clearfix for back and save -->
      <div class="clearfix">
        <!-- back button -->
        <button
          type="button"
          class="primary wizard__back pull-left"
          @click.prevent="goBack()"
        >
          <span>
            <i class="fa fa-arrow-left fa-lg"></i>
            back
          </span>
        </button>

        <!-- save & continue button -->
        <button type="submit" class="primary wizard__next pull-right">
          <span>
            next
            <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { bus } from "@/main";
import validationsMixin from "@/mixins/validationsMixin.js";
import inputMasksMixin from "@/mixins/inputMasksMixin.js";
import jsonUtilMixin from "@/mixins/jsonUtilMixin.js";

export default {
  name: "Section8",
  // date received from parent component
  props: ["sourceSection8"],
  // mixins used
  mixins: [validationsMixin, inputMasksMixin, jsonUtilMixin],
  data() {
    return {
      section8LoanOriginatorInformation: {
        currentPage: 6,
        loanApplicationProgress: 800,
        sectionName: "section8LoanOriginatorInformation",
        loanOriginatorName: "KC",
        referredBy: null,
      },
      validations: null,
      errors: null,
    };
  },

  methods: {
    goBack: function() {
      // emit event to go back with current page number
      bus.$emit("goBack", this.section8LoanOriginatorInformation.currentPage);
    },
    submitSectionEight: function() {
      // validate form
      bus.$emit("postUrla", this.section8LoanOriginatorInformation);
    },
    nextPage: function() {
      const nextStep = 8;
      this.section8LoanOriginatorInformation.currentPage = nextStep;
      // navigate to next page of URLA
      bus.$emit("nextPage", this.section8LoanOriginatorInformation);
    }
  },

  mounted: function() {
    // set default values

    // scroll to top of page
    this.$scrollTo(this.$refs.sectionEight, {
      duration: 500,
      easing: "ease",
      offset: -200,
    });
  },

  created: function() {
    // copy data received from urla into current section
    this.section8LoanOriginatorInformation = this.parseSection(
      this.section8LoanOriginatorInformation,
      this.sourceSection8
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
