<template>
  <div class="rounded">
    <div class="loader"></div>
    <p class="loading-message">{{loaderMessage}}...</p>
  </div>
</template>

<script>
export default {
  // component name
  name: "Loader",
  // props can be set from parent component
  props: ["loaderMessage"]
  // default loader-message
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.rounded {
  position: fixed;
  padding: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  width: 200px;
  height: 200px;
  background: rgba(128, 128, 128, 0.5);
  z-index: 1;
}

.loading-message {
  font-size: 16px;
  text-align: center;
  color: black;
  font-weight: bold;
}

.loader {
  position: relative;
  padding: 0;
  margin: 25px auto;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #2ebfac;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  z-index: 2;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>