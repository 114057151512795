<template>
  <div>
    <div class="wrapper">
      <!-- Main -->
      <section class="main">
        <!-- Banner -->
        <!-- Blurb -->
        <section>
          <h1>Popular Loan Programs</h1>
          <p></p>
          <ul>
            <li>30 year fixed</li>
            <li>15 year fixed</li>
            <li>7 year ARM</li>
            <li>5 year ARM</li>
            <li>nationwide high balance program with higher loan amount than a conventional loan and lower loan amount compared to a Jumbo loan. The qualification criteria in these kinds of loans are typically less stringent</li>
            <li>Jumbo loans</li>
          </ul>
        </section>

        <!-- Features -->
        <!-- Spotlights -->
        <section class="spotlights alt"></section>
      </section>

      <!-- Sidebar -->
    </div>
  </div>
</template>

<script>
export default {
  name: "PopularLoanPrograms"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>