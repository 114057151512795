<template>
  <div>
    <!-- Wrapper -->
    <div class="wrapper">
      <!-- Main -->
      <section class="main">
        <!-- Banner -->
        <!-- Blurb -->
        <section>
          <h1>Frequently Asked Questions</h1>
          <p>1) what is a rate lock commitment?</p>
          <p>A lock commitment is an agreement between you and American Smart Lending, which guarantees a specific interest rate at a particular price based on the loan characteristics selected if the loan closes within rate lock period of time.</p>
          <p>2) How can you lock a rate?</p>
          <p>You lock a rate with us by doing the following</p>
          <ul style="list-style-type: square;">
            <li>- by signing the intent to proceed forward with the rate and the loan program</li>
            <li>- by providing your credit card details so we can order the appraisal for your house</li>
          </ul>
          <p>&nbsp;3) when can i lock my rate?</p>
          <p>we typically lock your rate once we have done the following</p>
          <ul style="list-style-type: square;">
            <li>Pulled your credit report and have an idea of your liabilities</li>
            <li>Run your application through the fannie mae underwriting systems and making sure you are qualified for the loan that you are applying for</li>
          </ul>
          <p>4) can i change my loan's interest rate once the loan has been locked?</p>
          <p>We can absolutely change the interest rate for the same program but it can result in delays depending on when we are doing this. when we initially lock you into a particular rate we lock into all the rates on that particular days rate sheet. Any change in the interest rate will cause to increase or decrease the credits or charge that you are getting from us.&nbsp;</p>
          <p>&nbsp;5) can i change the loan program(for example from 30 year fixed to 7Year ARM) once the loan has been locked?</p>
          <p>Yes you can. However the original lock is void and we will do a fresh lock depending on the market rates on the day we change the loan program</p>
          <p>6) what are the third party fees?</p>
          <p>Any fees that is disclosed in the initial loan estimate that is typically not paid to us is considered a third party fees. Here is a breakdown of the typical third party fees</p>
          <ul style="list-style-type: square;">
            <li>Lenders title policy - Determined by and Paid to the title company</li>
            <li>Owners title policy -&nbsp;Determined by and Paid to the title company</li>
            <li>Survey cost -&nbsp;Determined by builder or survey company and paid to the surveyor</li>
            <li>Appraisal - Determined by the Appraisal management company and paid to the AMC</li>
            <li>Recording fees - Determined by the title company and paid to the title company for recording your title ownership in the county</li>
          </ul>
          <p>7) What is a closing cost?</p>
          <p>Closing cost can be anything that needs to be paid to close the loan. Some of these costs can be non-recurring one time costs that can be an expense to obtain the loan.&nbsp;</p>
          <ul style="list-style-type: square;">
            <li>Administrative fee or underwriting fee</li>
            <li>Appraisal fee</li>
            <li>Attorney fee</li>
            <li>Credit report fee</li>
            <li>Lender's title insurance</li>
            <li>Owner's title insurance</li>
            <li>Title Escrow fee</li>
          </ul>
          <p>Some of the costs for closing the loan can be recurring and will be the same irrespective of which lender you choose.&nbsp;</p>
          <ul style="list-style-type: square;">
            <li>Hazard/ home owners insurance payment - 12 months</li>
            <li>Property tax escrow deposit</li>
            <li>Hazard insurance escrow deposit</li>
            <li>prepaid interest payment for partial number of days</li>
          </ul>
          <p>&nbsp;</p>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>