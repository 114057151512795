<template>
  <photo-row :rowHeight="height">
    <photo-grid @click="$emit('itemClick')" v-for="(data, i) in photos" :key="i">
      <template v-if="showNumOfRemainingPhotos && remainingNum > 0 && data.id === (layoutNum - 1)">
        <photo-mask></photo-mask>
        <view-more>
          <num-of-remaining>{{remainingNum}}</num-of-remaining>
        </view-more>
      </template>
      <photo-thumb :thumb="data.source"></photo-thumb>
    </photo-grid>
  </photo-row>
</template>

<script>
import PhotoGrid from "@/components/elements/collagemaker/PhotoGrid.vue"
import PhotoRow from "@/components/elements/collagemaker/PhotoRow.vue"
import PhotoMask from "@/components/elements/collagemaker/PhotoMask.vue"
import ViewMore from "@/components/elements/collagemaker/ViewMore.vue"
import NumOfRemaining from "@/components/elements/collagemaker/NumOfRemaining.vue"
import PhotoThumb from "@/components/elements/collagemaker/PhotoThumb.vue"

export default {
  components: {
    PhotoGrid,
    PhotoRow,
    ViewMore,
    NumOfRemaining,
    PhotoMask,
    PhotoThumb,

  },
  props: {
    height: String,
    photos: Array,
    layoutNum: Number,
    remainingNum: Number,
    showNumOfRemainingPhotos: Boolean,
  }
}
</script>
