<template>
  <div>
    <div class="wrapper">
      <!-- Main -->
      <section class="main">
        <!-- Banner -->
        <!-- Blurb -->
        <section>
          <h1>Documents needed for mortgage processing</h1>

          <h4>Note: We require all the documents to be submitted in PDF format for quicker processing. If you cannot read something clearly, we cannot and the underwriter cannot</h4>

          <h3>Mortgage for a purchase</h3>
          <ul style="list-style-type:square">
            <li>Fully executed purchase contract with all amendments</li>
            <li>Two recent bank statements spanning at least 2 months of transaction history</li>
            <li>Bank statements containing the withdrawal transaction of the earnest money and any option money checks along with check proofs</li>
            <li>Two recent W2s</li>
            <li>Two recent tax returns</li>
            <li>Two recent pay stubs</li>
            <li>Any other stock brokerage or 401k investments statements that can serve as reserve to show the strength of the application</li>
            <li>Need properly scanned copy of front and back of the drivers license</li>
            <li>If on a work visa then we need most recent H1B approval - I-797</li>
            <li>If on a Green card copy then we need properly scanned copy of front and back sides</li>
            <li>If on a work visa then we need the valid visa on the Passport</li>
          </ul>
          <h3>Mortgage for a rate term refinance or cashout refinance</h3>

          <ul style="list-style-type:square">
            <li>Signed HUD-1</li>
            <li>Previous Loan Certificate form - We require a Deed document that you must have received at the time of closing containing your wet signature along with a seal by a notary or a similar authority</li>
            <li>Two recent bank statements spanning at least 2 months of transaction history</li>
            <li>Bank statements containing the withdrawal transaction of the earnest money and any option money checks along with check proofs</li>
            <li>Two recent W2s</li>
            <li>Two recent tax returns</li>
            <li>Two recent pay stubs</li>
            <li>Any other stock brokerage or 401k investments statements that can serve as reserve to show the strength of the application</li>
            <li>Need properly scanned copy of front and back of the drivers license</li>
            <li>If on a work visa then we need most recent H1B approval - I-797</li>
            <li>If on a Green card copy then we need properly scanned copy of front and back sides</li>
            <li>If on a work visa then we need the valid visa on the Passport</li>
          </ul>
        </section>

        <!-- Features -->
        <!-- Spotlights -->
        <section class="spotlights alt"></section>
      </section>

      <!-- Sidebar -->
    </div>
  </div>
</template>

<script>
export default {
  name: "DocsForProcessing"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>