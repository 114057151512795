<template>
  <div class="rounded" v-show="loading">
    <div class="v-spinner">
      <div class="v-moon v-moon1" v-bind:style="spinnerStyle">
        <div class="v-moon v-moon2" v-bind:style="[spinnerMoonStyle,animationStyle2]"></div>
        <div class="v-moon v-moon3" v-bind:style="[spinnerStyle,animationStyle3]"></div>
      </div>
    </div>
    <p class="loading-message">{{loaderMessage}}...</p>
  </div>
</template>

<script>
export default {
  name: "MoonLoader",
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: "#5dc596"
    },
    size: {
      type: String,
      default: "60px"
    },
    margin: {
      type: String,
      default: "2px"
    },
    radius: {
      type: String,
      default: "100%"
    },
    loaderMessage: {
      type: String,
      default: "loading"
    }
  },
  data() {
    return {
      spinnerStyle: {
        height: this.size,
        width: this.size,
        borderRadius: this.radius
      }
    };
  },
  computed: {
    moonSize() {
      return parseFloat(this.size) / 7;
    },
    spinnerMoonStyle() {
      return {
        height: this.moonSize + "px",
        width: this.moonSize + "px",
        borderRadius: this.radius
      };
    },
    animationStyle2() {
      return {
        top: parseFloat(this.size) / 2 - this.moonSize / 2 + "px",
        backgroundColor: this.color
      };
    },
    animationStyle3() {
      return {
        border: this.moonSize + "px solid " + this.color
      };
    }
  }
};
</script>

<style>
/* styles for rounded rectangle */
.rounded {
  position: fixed;
  padding: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  width: 200px;
  height: 200px;
  /* background: rgba(128, 128, 128, 0.2);*/
  z-index: 1;
}

/* styles for spinner */
.v-spinner {
  position: relative;
  padding: 0;
  margin: 25px auto;
  margin-bottom: 0;
  width: 100px;
  height: 100px;
  z-index: 2;
}

.v-spinner .v-moon1 {
  /* changed delay from 0.6s to 0.8s */
  -webkit-animation: v-moonStretchDelay 0.8s 0s infinite linear;
  animation: v-moonStretchDelay 0.8s 0s infinite linear;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  position: relative;
}
.v-spinner .v-moon2 {
  /* changed delay from 0.6s to 0.8s */
  -webkit-animation: v-moonStretchDelay 0.8s 0s infinite linear;
  animation: v-moonStretchDelay 0.8s 0s infinite linear;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  opacity: 1;
  position: absolute;
}
.v-spinner .v-moon3 {
  opacity: 0.1;
}
@-webkit-keyframes v-moonStretchDelay {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes v-moonStretchDelay {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-message {
  font-size: 24px;
  text-align: center;
  color: black;
  font-weight: bold;
}
</style>