<template>
  <!-- Main -->
  <section class="main">
    <section>
      <!--h1>{{message.status}}</h1-->
      <div class="error-message">
        <h1>500</h1>
        <h2>Unexpected Error :(</h2>
        <p>An error occurred and your request couldn't be completed. Please try again or come back later.</p>
        <p>
          Please do not hesitate to reach us at
          <a href="tel:469-525-6725">+1(469)525-6725</a> or
          <a href="mailto:info@AmericanSmartLending.com">info@AmericanSmartLending.com.</a>
        </p>
        <p>
          <router-link to="home">Return to home page</router-link>
        </p>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  // Component Name
  name: "Error",
  // props can be set from parent component
  props: ["message"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* styles for error message */
.error-message h1 {
  font-size: 72px;
  color: black !important;
}

.error-message h2 {
  font-size: 36px;
  color: black !important;
}

.error-message p {
  font-size: 16px;
}
</style>
