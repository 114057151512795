<template>
  <!-- Main -->
  <section class="main">
    <section class="quote-summary">
      <h1>Quote Requested</h1>
      <!-- Quote requested message -->
      <p>
        We appreciate you for considering American Smart Lending for your mortgage needs.
        <br>We will get back to you by the end of the business day.
        <br>Please do not hesitate to reach us at
        <a href="tel:469-525-6725">+1(469)525-6725</a> or
        <a href="mailto:kc@texsmartlending.com">kc@texsmartlending.com</a>
        in case you need to reach us before the end of the day.
      </p>
      <!-- Quote Request summary -->
      <h2>Summary of your Request</h2>
      <div v-if="quoteRequest !== null" class="row">
        <!-- Quote details -->
        <div class="col-4 col-12-medium">
          <h3>Mortgage Information</h3>
          <p>
            <!-- Purchase price -->
            <span class="field">Purchase price:</span>
            <span class="field-value">{{quoteRequest.purchasePrice}}</span>
            <br>

            <!-- Down payment -->
            <span class="field">Down payment:</span>
            <span class="field-value">{{quoteRequest.downPayment}}</span>
            <br>

            <!-- Loan Programs selected -->
            <span class="field">Loan programs:</span>
            <span class="field-value">
              <span
                v-for="(term, index) in quoteRequest.loanProgram"
                v-bind:schedule="term"
                v-bind:key="index"
              >
                {{term}}
                <!-- display comma and space between mortgage terms -->
                <span v-if="index !== quoteRequest.loanProgram.length - 1">,&nbsp;</span>
              </span>
            </span>
            <br>

            <!-- Closing Date -->
            <span class="field">Closing On/Before:</span>
            <span class="field-value">{{quoteRequest.closingDate}}</span>
            <br>

            <!-- House Type -->
            <span class="field">House Type:</span>
            <span class="field-value">{{quoteRequest.houseType}}</span>
            <br>

            <!-- Residence Type -->
            <span class="field">Residence Type:</span>
            <span class="field-value">{{quoteRequest.residenceType}}</span>
            <br>
          </p>
        </div>
        <!-- Contact Details -->
        <div class="col-4 col-12-medium">
          <h3>Contact Information</h3>
          <p>
            <!-- First Name -->
            <span class="field">First Name:</span>
            <span class="field-value">{{mortgageCustomer.firstName}}</span>
            <br>
            <!-- Last Name -->
            <span class="field">Last Name:</span>
            <span class="field-value">{{mortgageCustomer.lastName}}</span>
            <br>
            <!-- Email Address -->
            <span class="field">Email Address:</span>
            <span class="field-value">{{mortgageCustomer.primaryEmail}}</span>
            <br>
            <!-- Phone Number -->
            <span class="field">Phone Number:</span>
            <span class="field-value">{{mortgageCustomer.phoneNumber}}</span>
            <br>
            <!-- WhatsApp Number -->
            <span class="field">WhatsApp Number:</span>
            <span class="field-value">{{mortgageCustomer.whatsAppNumber}}</span>
            <br>
          </p>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  // Component Name
  name: "QuoteSuccess",
  // props can be set from parent component
  props: ["message", "mortgageCustomer", "quoteRequest"]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.quote-summary h2 {
  color: black !important;
}

/* styles for field names and values */
.field,
.field-value {
  display: inline-block;
  width: 200px;
  box-sizing: border-box;
}

.field {
  font-weight: bold;
}
</style>