<template>
<div class="home-footer">
  <div class="row">
    <div class="quick-links">
      <h4>Quick Links</h4>
      <ul class="quickLinks-icons">
        <li>
          <a href="http://www.consumerfinance.gov/f/201503_cfpb_your-home-loan-toolkit-web.pdf" target="_blank">
            Home Loan Toolkit
          </a>
        </li>
        <li>
          <a href="https://www.nmlsconsumeraccess.org/" target="_blank">
            NMLS Consumer Access
          </a>
        </li>
        <li>
          <a v-on:click="$router.push({ name: 'amortization-calculator' })">
            Amortization Calculator
          </a>
        </li>
      </ul>
    </div>
    <div class="follow-us">
      <h4>Follow Us</h4>
      <ul class="followUs-icons">
        <li class="icon fa-facebook">
          <a href="#">facebook.com/tslg</a>
        </li>
        <li class="icon fa-twitter">
          <a href="#">twitter.com/tslg</a>
        </li>
      </ul>
    </div>
    <div class="contact">
      <h4>Contact Us</h4>
      <ul class="contact-icons">
        <li class="icon fa-home">
          <a href="https://goo.gl/maps/wXxVgidiQs3N17RPA" target="_blank">
            Kadlogic Inc DBA American Smart Lending<br />
            ISAOA ATIMA <br />
            1560 E.Southlake Blvd Ste.100<br />
            Southlake , TX, 76092<br />
          </a>
        </li>
        <li class="icon fa-phone">
          <a href="tel:469-525-6725">Office: +1(469)525-6725</a>
        </li>
        <li class="icon fa-fax">
          <a href="#">Fax: (888)506-9603</a>
        </li>
        <li class="icon fa-envelope">
          <a href="mailto:info@AmericanSmartLending.com"
            >info@AmericanSmartLending.com</a
          >
        </li>

      </ul>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "QuickLinks",
}
</script>
<style scoped>
.fheo-container {
  display: flex;
  padding: 8%;
}

.fheo-left {
  width: 80px;
}
.fheo-right {
  flex: 1;
  text-align: center;
}

.fheo-right > p {
  padding: 5%;
}

.home-footer div {
  justify-content: space-evenly;
  padding-top: 20px;
}
</style>
