import axios from "axios";
/* eslint-disable */
export default {
  data() {
    return {
      // base url for the REST API
      apiBaseUrl: "https://z7lt9ai194.execute-api.us-east-1.amazonaws.com/Prod",
      config: null,
    };
  },
  methods: {
    // POST request to Simple Quote API, path '/customer'
    createCustomer: function(mortgageCustomer) {
      return new Promise((resolve, reject) => {
        // POST request with mortgageCustomer as body
        axios
          .post("/customers", mortgageCustomer, this.config)
          .then((response) => {
            if (response.status === 200) {
              // handle successful API request
              return resolve(response.data.customerId);
            } else {
              return reject("Unable to create user!");
            }
          })
          .catch((err) => {
            return reject("Unable to contact API!");
          });
      });
    },

    // POST request to Simple Quote API, path '/customer'
    postQuoteRequest: function(customerId, quoteRequest) {
      return new Promise((resolve, reject) => {
        // POST request with mortgageCustomer as body
        axios
          .post("/quotes/" + customerId, quoteRequest, this.config)
          .then((response) => {
            if (response.status === 200) {
              // handle successful API request
              return resolve(response.data.message);
            } else {
              // handle error returned by API
              return reject("Unable to request Quote");
            }
          })
          .catch((err) => {
            return reject("unable to contact API!");
          });
      });
    },
  },
  mounted() {
    // configure axios on mount
    this.config = {
      // configure headers
      headers: {
        // default content type
        "Content-Type": "application/json",
        // can setup to prevent all caching
        "Cache-Control": "no-cache",
      },
      // configure base URL
      baseURL: this.apiBaseUrl,
    };
  },
};
