<template>
  <div>
    <div class="wrapper">
      <!-- Main -->
      <section class="main">
        <!-- Banner -->
        <!-- Blurb -->
        <section>
          <h1 class="disclosure-heading">TEXAS RECOVERY FUND</h1>
          <br />
          <p class="disclosure-text">
            “CONSUMERS WISHING TO FILE A COMPLAINT AGAINST A COMPANY OR A
            RESIDENTIAL MORTGAGE LOAN ORIGINATOR SHOULD COMPLETE AND SEND A
            COMPLAINT FORM TO THE TEXAS DEPARTMENT OF SAVINGS AND MORTGAGE
            LENDING, 2601 NORTH LAMAR, SUITE 201, AUSTIN, TEXAS 78705. COMPLAINT
            FORMS AND INSTRUCTIONS MAY BE OBTAINED FROM THE DEPARTMENT’S WEBSITE
            AT
            <a href="https://www.sml.texas.gov" target="_blank"
              >WWW.SML.TEXAS.GOV</a
            >. A TOLL-FREE CONSUMER HOTLINE IS AVAILABLE AT
            <a href="tel:469-525-6725">1-877-276-5550</a>.
          </p>
          <p class="disclosure-text">
            THE DEPARTMENT MAINTAINS A RECOVERY FUND TO MAKE PAYMENTS OF CERTAIN
            ACTUAL OUT OF POCKET DAMAGES SUSTAINED BY BORROWERS CAUSED BY ACTS
            OF LICENSED RESIDENTIALMORTGAGE LOAN ORIGINATORS. A WRITTEN
            APPLICATION FOR REIMBURSEMENT FROM THE RECOVERY FUND MUST BE FILED
            WITH AND INVESTIGATED BY THE DEPARTMENT PRIOR TO THE PAYMENT OF A
            CLAIM. FOR MORE INFORMATION ABOUT THE RECOVERY FUND, PLEASE CONSULT
            THE DEPARTMENT’S WEBSITE AT
            <a href="https://www.sml.texas.gov" target="_blank"
              >WWW.SML.TEXAS.GOV</a
            >."
          </p>
        </section>

        <!-- Features -->
        <!-- Spotlights -->
        <section class="spotlights alt"></section>
      </section>

      <!-- Sidebar -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Disclosure",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.disclosure-heading {
  text-align: center;
  color: black;
}

.disclosure-text {
  text-justify: inter-word;
  text-align: center;
  font-size: 130%;
}

.disclaimer-text a {
  color: black;
}
</style>