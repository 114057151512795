<template>
  <div>
    <div class="wrapper">
      <!-- Main -->
      <section class="main">
        <!-- Banner -->
        <!-- Blurb -->
        <section>
          <h1>Do's and Dont's in the mortgage process</h1>

          <h3>Please Do</h3>
          <p>1) Please send all the documents requested promptly</p>
          <p>2) Please sign all the documents for digital signatures promptly</p>
          <p>3) Always be truthful, accurate and precise to your mortgage broker so he can help you in the most appropiate way. Clarity and knowledge of anything relevant in the credit report or financial activity will help the mortgage broker address them early and make sure the loan closes on time</p>
          <p>4) we request a lot of documents from you to satisfy the fanniemae guildelines and regulations and also since the underwriter asks us for that. some of those requests might some silly or unreasonable but we push back on a lot of them ourselves. Please raise any concerns that you may have upfront so we can address them and move forward</p>
          <h3>Please Do Not</h3>
          <p>1) Do not take any new credit card or any other debt without informing us. We will look into your credit profile as to how this new debt will change your debt to income ratio and how it will effect your eligibility for the home loan</p>
          <p>2) Do not apply for extension of credit limits on any of your credit cards during this time unless absolutely needed</p>
          <p>&nbsp;</p>
        </section>

        <!-- Features -->
        <!-- Spotlights -->
        <section class="spotlights alt"></section>
      </section>

      <!-- Sidebar -->
    </div>
  </div>
</template>

<script>
export default {
  name: "DosAndDonts"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>