<template>
  <div>
    <section class="main row">
      <div class="left-section col-5 col-12-medium">
        <!-- Moon Loader -->
        <moon-loader
          class="moon-loader"
          v-bind:loading="isLoading"
          :color="loaderColor"
          :size="loaderSize"
          :loaderMessage="loaderMessage"
        ></moon-loader>

        <div v-show="shouldShowSearchForm">
          <span class="login100-form-title">Let's find your profile.</span>
          <div class="container-login100">
            <form
              id="search-customer-form"
              class="login100-form validate-form"
              v-on:submit.prevent="findCustomer"
              method="post"
            >
              <!-- Error message customer not found-->
              <div class="wrap-error" v-show="!isCustomerFound">
                <transition name="shake">
                  <p class="error">
                    Profile not found. Please check your details and try again.
                  </p>
                </transition>
              </div>

              <!-- Last Name-->
              <div
                class="wrap-input100 validate-input"
                v-bind:class="
                  validations.customer.lastName === 1 ? '' : 'alert-validate'
                "
                data-validate="Enter valid name"
              >
                <input
                  id="last-name"
                  class="input100"
                  v-bind:class="customer.lastName !== null ? 'has-val' : ''"
                  type="text"
                  name="lastName"
                  v-model="customer.lastName"
                  v-on:change="validateLastName($event)"
                  required
                />
                <span
                  class="focus-input100"
                  data-placeholder="Last Name"
                ></span>
              </div>

              <!-- Email Address -->
              <div
                class="wrap-input100 validate-input"
                v-bind:class="
                  validations.customer.primaryEmail === 1
                    ? ''
                    : 'alert-validate'
                "
                data-validate="Enter valid email"
              >
                <input
                  id="login-email"
                  class="input100"
                  v-bind:class="customer.primaryEmail !== null ? 'has-val' : ''"
                  type="email"
                  name="email"
                  v-model="customer.primaryEmail"
                  v-on:change="validateEmail($event)"
                  required
                />
                <span class="focus-input100" data-placeholder="Email"></span>
              </div>

              <div
                class="wrap-input100 validate-input"
                v-bind:class="
                  validations.customer.dateOfBirth === 1 ? '' : 'alert-validate'
                "
                data-validate="Enter valid date"
              >
                <input
                  id="date-of-birth"
                  class="input100"
                  v-bind:class="customer.dateOfBirth !== null ? 'has-val' : ''"
                  type="text"
                  maxlength="10"
                  name="dateOfBirth"
                  v-mask="'##/##/####'"
                  v-model="dateOfBirthModel"
                  v-on:change="validateDateOfBirth()"
                  required
                />
                <span
                  class="focus-input100"
                  data-placeholder="Date of Birth (MM/DD/YYYY)"
                ></span>
              </div>

              <div
                class="wrap-input100 validate-input"
                v-bind:class="
                  validations.customer.lastFourDigitsOfSsn === 1
                    ? ''
                    : 'alert-validate'
                "
                data-validate="Enter valid social"
              >
                <input
                  id="social-security-number"
                  class="input100"
                  v-bind:class="
                    customer.lastFourDigitsOfSsn !== null ? 'has-val' : ''
                  "
                  type="text"
                  maxlength="4"
                  minlength="4"
                  v-model="customer.lastFourDigitsOfSsn"
                  v-on:change="validateLastFourDigitsOfSsn($event)"
                  required
                />
                <span
                  class="focus-input100"
                  data-placeholder="SSN (last 4 digits)"
                ></span>
              </div>

              <!-- Signin button -->
              <div class="container-login100-form-btn">
                <button class="login100-form-btn">
                  <!-- i class="fa fa-sign-in fa-lg" aria-hidden="true"></i-->
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
        <div v-show="!shouldShowSearchForm">
          <!-- existing files summary -->
          <div class="existing-customer-summary">
            <span class="customer-info-title"
              >Hi {{ mortgageCustomerFound.firstName }},</span
            >
            <div class="customer-info-container">
              <div class="exisiting-files-summary">
                <h3 v-if="mortgageCustomerFound.existingFiles">
                  here are your files.
                </h3>
                <h3 v-else>you haven't uploaded any files yet.</h3>
                <h3 v-if="isFailedToLoadCustomerFiles">
                  Sorry, we are unable to load your documents. You can still
                  proceed to upload your documents.
                </h3>
                <div
                  class="existing-file-wrapper"
                  v-for="(fileInfo,
                  index) in mortgageCustomerFound.existingFiles"
                  :key="index"
                >
                  <i class="fa fa-file"></i>
                  <div class="existing-file">
                    <div class="existing-file-info">
                      <p class="existing-file-name">{{ fileInfo.name }}</p>
                      <p class="existing-file-date">
                        {{ isoToLocalDateTime(fileInfo.lastUpdatedOn) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Sign out button -->
          <div class="container-upload100-form-btn">
            <button
              class="upload100-form-btn"
              v-on:click="$router.push({ name: 'home' })"
            >
              Sign out
            </button>
          </div>
        </div>
      </div>
      <div class="right-section col-7 col-12-medium">
        <fieldset v-bind:disabled="!isFileUploadFormEnabled">
          <form
            id="file-upload"
            method="post"
            v-on:submit.prevent="createLinkAndUploadFiles"
            v-cloak
            @drop.prevent="addDroppedFiles"
            @dragover.prevent
          >
            <div class="drop-zone-wrapper">
              <div class="upload">
                <label for="drop-zone">
                  Drag files here or
                  <span class="browse__button">Browse</span>
                </label>

                <div>
                  <input
                    hidden
                    id="drop-zone"
                    multiple
                    type="file"
                    class="input-file"
                    @change.prevent="addSelectedFiles"
                  />
                </div>
              </div>
              <div class="max-size-instruction">
                <p>Max. file size: 10MB</p>
              </div>
              <!-- Error message invalid file-->
              <div class="wrap-error">
                <transition name="shake">
                  <p class="error" v-show="isUnsupportedFileAdded">
                    Please remove unsupported file(s)
                  </p>
                </transition>
              </div>

              <!-- summary -->
              <div class="summary-wrapper">
                <div
                  class="selected-file-wrapper"
                  v-for="(fileWrapper, index) in filesToUpload"
                  :key="index"
                >
                  <i class="fa fa-file"></i>
                  <div
                    class="file"
                    v-bind:class="
                      fileWrapper.isValid ? 'valid-file' : 'invalid-file'
                    "
                  >
                    <div class="selected-file-info">
                      <p class="file-name-str">{{ fileWrapper.name }}</p>
                      <p class="file-name-size">
                        {{ bytesToSize(fileWrapper.size) }}
                      </p>
                      <i
                        v-if="!fileWrapper.isUploaded"
                        @click.prevent="removeFile(index)"
                        class="fa fa-times"
                      ></i>
                    </div>
                    <div class="progress file-upload-progress">
                      <div
                        class="progress-bar progress-bar-animated"
                        style="width: 100%"
                        v-bind:style="{ width: fileWrapper.uploadStatus }"
                        v-bind:class="[
                          fileWrapper.isUploadFailing
                            ? 'bg-failure'
                            : 'bg-success',
                          fileWrapper.isUploaded ? '' : 'progress-bar-striped',
                        ]"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-upload100-form-btn">
                <button
                  type="submit"
                  class="upload100-form-btn"
                  v-bind:disabled="
                    isUnsupportedFileAdded || isDisableUploadButton
                  "
                >
                  Upload
                  <i class="fa fa-cloud-upload fa-lg" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </form>
        </fieldset>
      </div>
    </section>
  </div>
</template>

<script>
import MoonLoader from "@/components/elements/MoonLoader.vue";
import inputMasksMixin from "@/mixins/inputMasksMixin.js";
import validationsMixin from "@/mixins/validationsMixin.js";
import fileUploadMixin from "@/mixins/fileUploadMixin.js";

export default {
  name: "Upload",
  mixins: [validationsMixin, fileUploadMixin, inputMasksMixin],
  components: {
    "moon-loader": MoonLoader,
  },
  data() {
    return {
      loaderSize: "100px",
      loaderColor: "#2ebfac",
      isLoading: false,
      loaderMessage: "Searching",
      isUnsupportedFileAdded: false,
      shouldShowSearchForm: true,
      minDateOfBirth: null,
      maxDateOfBirth: null,
      isFailedToLoadCustomerFiles: false,
      isCustomerFound: true,
      isFileUploadFormEnabled: false,
      isDisableDeleteFileButton: false,
      isDisableUploadButton: false,
      customer: {
        lastName: null,
        primaryEmail: null,
        dateOfBirth: null,
        lastFourDigitsOfSsn: null,
        shouldBeValidated: true,
      },
      mortgageCustomerFound: {},
      filesToUpload: [],
      validations: {
        customer: {
          lastName: 1,
          primaryEmail: 1,
          dateOfBirth: 1,
          lastFourDigitsOfSsn: 1,
        },
      },
      errors: {
        customer: {
          lastName: null,
          primaryEmail: null,
          dateOfBirth: null,
          lastFourDigitsOfSsn: null,
        },
      },
    };
  },
  computed: {
    dateOfBirthModel: {
      get() {
        if (undefined == this.customer.dateOfBirth) {
          return null;
        }
        const dateArray = this.customer.dateOfBirth.split("-");
        var formattedDate = "";
        if (dateArray[1]) {
          // mm
          formattedDate += dateArray[1];
        }
        if (dateArray[2]) {
          // dd
          formattedDate += "/" + dateArray[2];
        }
        if (dateArray[0]) {
          // yyyy
          formattedDate += "/" + dateArray[0];
        }
        return formattedDate;
      },
      set(value) {
        const dateArray = value.split("/");
        var formattedDate = "";
        if (dateArray[2]) {
          // yyyy
          formattedDate += dateArray[2];
        }
        if (dateArray[0]) {
          // mm
          formattedDate += "-" + dateArray[0];
        }
        if (dateArray[1]) {
          // dd
          formattedDate += "-" + dateArray[1];
        }
        this.customer.dateOfBirth = formattedDate;
      },
    },
  },
  methods: {
    /* find customer methods */
    init: function() {
      // set default values
      this.isCustomerFound = true;
      this.shouldShowSearchForm = true;
      this.isFileUploadFormEnabled = false;
      this.setDateOfBirthRange();
    },

    isoToLocalDateTime: function(isoDate) {
      var dateTemp = new Date(isoDate);
      const MONTHS = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return (
        MONTHS[dateTemp.getMonth()] +
        " " +
        dateTemp.getDate() +
        ", " +
        dateTemp.getFullYear() +
        " " +
        dateTemp.toLocaleTimeString()
      );
    },

    getFormattedDate: function(date) {
      // extract day, month and year from date
      const dd = String(date.getDate()).padStart(2, "0");
      const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = date.getFullYear();
      // concat string to yyyy-mm-dd format
      const formattedDate = yyyy + "-" + mm + "-" + dd;
      return formattedDate;
    },

    setDateOfBirthRange: function() {
      var minDoB = new Date();
      minDoB.setFullYear(minDoB.getFullYear() - 100);
      this.minDateOfBirth = minDoB;
      var maxDoB = new Date();
      maxDoB.setFullYear(maxDoB.getFullYear() - 18);
      this.maxDateOfBirth = maxDoB;
    },

    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },

    bytesToSize(bytes) {
      const SIZES = ["B", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0B";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      if (i == 0) return bytes + " " + SIZES[i];
      return (bytes / Math.pow(1024, i)).toFixed(1) + " " + SIZES[i];
    },

    validateLastName: function(event) {
      this.errors.customer.lastName = this.checkLastName(event);
      this.validations.customer.lastName =
        this.errors.customer.lastName.length === 0 ? 1 : 0;
    },

    validatePhoneNumber: function(event) {
      this.errors.customer.phoneNumber = this.checkPhoneNumber(event);
      this.validations.customer.phoneNumber =
        this.errors.customer.phoneNumber.length === 0 ? 1 : 0;
    },

    validateEmail: function(event) {
      this.errors.customer.primaryEmail = this.checkEmail(event);
      this.validations.customer.primaryEmail =
        this.errors.customer.primaryEmail.length === 0 ? 1 : 0;
    },

    validateDateOfBirth: function() {
      this.errors.customer.dateOfBirth = this.checkDateRangeFromValue(
        this.customer.dateOfBirth,
        this.getFormattedDate(this.minDateOfBirth),
        this.getFormattedDate(this.maxDateOfBirth)
      );
      this.validations.customer.dateOfBirth =
        this.errors.customer.dateOfBirth.length === 0 ? 1 : 0;
    },

    validateLastFourDigitsOfSsn: function(event) {
      this.errors.customer.lastFourDigitsOfSsn = this.checkLastFourDigitsOfSsn(
        event
      );
      this.validations.customer.lastFourDigitsOfSsn =
        this.errors.customer.lastFourDigitsOfSsn.length === 0 ? 1 : 0;
    },

    validateSearchCustomerForm: function() {
      for (var field in this.errors.customer) {
        if (this.errors.customer[field].length !== 0) {
          this.hasErrors.customer = true;
          return false;
        }
      }
      return true;
    },

    validateFile(file) {
      const MAX_SIZE_BYTES = 10 * 1024 * 1024;
      const ALLOWEDTYPES = [
        "text/plain",
        "application/pdf",
        "application/msword",
        "application/x-zip-compressed",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/png",
        "image/jpeg",
      ];
      if (file.size > MAX_SIZE_BYTES) {
        return false;
      }
      if (!ALLOWEDTYPES.includes(file.type)) {
        return false;
      }
      return true;
    },

    async getExistingCustomerFiles(CUSTOMER_FILES_URL) {
      this.loaderMessage = "Loading";
      this.isLoading = true;
      let response = await this.getCustomerFiles(CUSTOMER_FILES_URL).catch(
        (err) => {
          this.isLoading = false;
          this.$router.push({ name: "error", params: { message: err } });
        }
      );
      this.isLoading = false;
      if (response.status !== 200) {
        this.isFailedToLoadCustomerFiles = true;
      }
      if (
        response.data &&
        response.data.files &&
        response.data.files.length != 0
      ) {
        this.mortgageCustomerFound.existingFiles = response.data.files;
      }
    },

    async findCustomer() {
      const ERROR_MESSAGE = "Unexpected Error";
      if (this.validateSearchCustomerForm()) {
        this.isLoading = true;
        this.loaderMessage = "Searching";
        this.isCustomerFound = true;
        let response = await this.searchCustomer(this.customer).catch(() => {
          this.isLoading = false;
          // route to error page
          this.$router.push({
            name: "error",
            params: { message: ERROR_MESSAGE },
          });
          return;
        });
        this.isLoading = false;
        if (response.status === 404) {
          this.isCustomerFound = false;
          return;
        }
        if (
          response.status !== 200 ||
          response.data.results === undefined ||
          response.data.results.length != 1
        ) {
          // route to error page
          this.$router.push({
            name: "error",
            params: { message: ERROR_MESSAGE },
          });
          return;
        }
        if (response.data.results[0].isValidated) {
          this.isFileUploadFormEnabled = true;
          this.shouldShowSearchForm = false;
          this.mortgageCustomerFound =
            response.data.results[0].mortgageCustomer;
          this.getExistingCustomerFiles(
            this.mortgageCustomerFound.link.href + "/files"
          );
        } else {
          this.isCustomerFound = false;
        }
      }
    },

    validateAndAddFilesToList(filesToAdd) {
      if (!filesToAdd) return;
      [...filesToAdd].forEach((f) => {
        var fileWrapper = {
          name: f.name,
          file: f,
          isValid: false,
          uploadLink: null,
          isUploadFailing: false,
          isUploaded: false,
          size: f.size,
          uploadStatus: "0%",
        };
        if (this.validateFile(f)) {
          fileWrapper.isValid = true;
        } else {
          this.isUnsupportedFileAdded = true;
        }
        this.filesToUpload.push(fileWrapper);
      });
    },

    /* file-upload methods */
    addSelectedFiles(e) {
      this.validateAndAddFilesToList(e.target.files);
    },

    addDroppedFiles(e) {
      this.validateAndAddFilesToList(e.dataTransfer.files);
    },

    removeFile(index) {
      this.filesToUpload.splice(index, 1);
      var hasUnsupportedFilesAfterRemoving = false;
      this.filesToUpload.forEach((fileWrapper) => {
        if (!this.validateFile(fileWrapper.file)) {
          this.isUnsupportedFileAdded = true;
          hasUnsupportedFilesAfterRemoving = true;
        }
      });
      this.isUnsupportedFileAdded = hasUnsupportedFilesAfterRemoving;
    },

    async createUploadLinkForFile(fileWrapper) {
      fileWrapper.uploadStatus = "25%";
      if (fileWrapper.isUploaded) {
        return;
      }
      if (
        null != fileWrapper.uploadLink &&
        undefined !== fileWrapper.uploadLink &&
        "" != fileWrapper.uploadLink
      ) {
        // skip if link is already created (retrying)
        return;
      }
      var fileInfo = {
        name: null,
        description: null,
      };
      fileInfo.name = fileWrapper.name;
      const CUSTOMER_FILES_PATH =
        this.mortgageCustomerFound.link.href + "/files";
      let response = await this.createFileUploadLink(
        CUSTOMER_FILES_PATH,
        fileInfo
      ).catch(() => {
        fileWrapper.isUploadFailing = true;
      });
      if (response.status !== 201) {
        fileWrapper.isUploadFailing = true;
      } else {
        fileWrapper.uploadLink = response.data.file.link.href;
        fileWrapper.uploadStatus = "50%";
      }
    },

    async uploadEachFile(fileWrapper) {
      if (null === fileWrapper.uploadLink || "" === fileWrapper.uploadLink) {
        fileWrapper.isUploadFailing = true;
        return;
      }
      fileWrapper.uploadStatus = "75%";
      let response = await this.uploadFile(
        fileWrapper.uploadLink,
        fileWrapper.file
      ).catch(() => {
        fileWrapper.isUploadFailing = true;
      });
      if (response.status !== 200) {
        fileWrapper.isUploadFailing = true;
      } else {
        fileWrapper.uploadStatus = "100%";
        fileWrapper.isUploaded = true;
      }
    },

    async createLinkAndUploadFile(fileWrapper) {
      if (fileWrapper.isUploaded) {
        return;
      }
      fileWrapper.isUploadFailing = false;
      await this.createUploadLinkForFile(fileWrapper);
      await this.uploadEachFile(fileWrapper);
      if (fileWrapper.isUploaded) {
        this.getExistingCustomerFiles(
          this.mortgageCustomerFound.link.href + "/files"
        );
      }
    },

    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },

    async createLinkAndUploadFiles() {
      this.isDisableDeleteFileButton = true;
      // TODO: validate files
      // this.isDisableUploadButton = true;
      if (this.isUnsupportedFileAdded) {
        return;
      }
      this.asyncForEach(this.filesToUpload, this.createLinkAndUploadFile);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.main {
  min-height: 80vh;
}

/* find customer form styles */

/* main */
/*//////////////////////////////////////////////////////////////////
    [ RESTYLE TAG ]*/
.left-section {
  border-right: dashed #2ebfac 1px;
}

.wrap-error {
  min-height: 25px;
  text-align: center;
  padding-bottom: 15px;
}

.error {
  text-decoration: none;
  /*color: #bf4d2e;*/
  color: red;
  margin: 0;
  padding: 0;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  /*font-family: Poppins-Regular, sans-serif;*/
}

/*---------------------------------------------*/
a,
.link {
  /*font-family: Poppins-Regular;*/
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.link {
  cursor: pointer;
}

a:focus,
.link:focus {
  outline: none !important;
  box-shadow: none;
  -webkit-box-shadow: none;
}

a:hover,
.link:hover {
  text-decoration: none;
  color: #1ccab2 !important;
  /*color: #333333;*/
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  /*font-family: Poppins-Regular;*/
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
  box-shadow: none;
  -webkit-box-shadow: none;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}

input:-moz-placeholder {
  color: #adadad;
}

input::-moz-placeholder {
  color: #adadad;
}

input:-ms-input-placeholder {
  color: #adadad;
}

textarea::-webkit-input-placeholder {
  color: #adadad;
}

textarea:-moz-placeholder {
  color: #adadad;
}

textarea::-moz-placeholder {
  color: #adadad;
}

textarea:-ms-input-placeholder {
  color: #adadad;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
    [ Utility ]*/
.txt1 {
  /*font-family: Poppins-Regular;*/
  font-size: 15px;
  color: #999999;
  line-height: 1.5;
}

.txt2 {
  /*font-family: Poppins-Regular;*/
  font-size: 15px;
  color: #2ebfac;
  line-height: 1.5;
}

/*//////////////////////////////////////////////////////////////////
    [ login ]*/

.container-login100 {
  margin: 0 auto;
  width: 80%;
  min-height: 60vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f8f8f8;
}

@media screen and (min-width: 1280px) {
  .container-login100 {
    width: 60%;
  }
}

/* .wrap-login100 {
      width: 390px;
      background: #f8f8f8;
    } */

/*------------------------------------------------------------------
    [ Form ]*/

.login100-form {
  width: 100%;
}

.login100-form-title {
  display: block;
  font-weight: bold;
  /*font-family: Poppins-Bold;*/
  font-size: 28px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  padding-top: 20px;
}

@media screen and (min-width: 1280px) {
  .login100-form-title {
    font-size: 36px;
    padding-top: 35px;
  }
}

.customer-info-title {
  display: block;
  font-weight: bold;
  /*font-family: Poppins-Bold;*/
  font-size: 36px;
  color: #333333;
  line-height: 1.2;
  text-align: start;
  padding-top: 35px;
}

.login100-form-avatar {
  display: block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.login100-form-avatar img {
  width: 100%;
}

/*------------------------------------------------------------------
    [ Input ]*/

.wrap-input100 {
  margin-top: 15px;
  margin-bottom: 30px;
  width: 100%;
  position: relative;
  border-bottom: 2px solid #d9d9d9;
}

@media screen and (min-width: 1280px) {
  .wrap-input100 {
    margin-top: 35px;
    margin-bottom: 35px;
  }
}

.input100 {
  /*font-family: Poppins-SemiBold;*/
  font-size: 14px;
  color: #555555;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 40px;
  background: transparent;
  padding: 0 5px;
}

@media screen and (min-width: 1280px) {
  .input100{
    font-size: 18px;
    height: 52px;
  }
}

/*---------------------------------------------*/
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #2ebfac;
}

.focus-input100::after {
  /*font-family: Poppins-Medium;*/
  font-size: 15px;
  color: #999999;
  line-height: 1.2;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 20px;
  left: 0px;
  padding-left: 5px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

@media screen and (min-width: 1280px){
  .focus-input100::after {
    top: 15px;
    font-size: 18px;
  }
}

.input100:focus + .focus-input100::after {
  top: -10px;
  font-size: 13px;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::after {
  top: -10px;
  font-size: 13px;
}

@media screen and (min-width: 1280px){
  .has-val.input100 + .focus-input100::after {
    top: -20px;
    font-size: 15px;
  }
  .input100:focus + .focus-input100::after {
    top: -20px;
    font-size: 15px;
  }
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

/*------------------------------------------------------------------
    [ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  /*font-family: Poppins-Medium;*/
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #fff !important;
  line-height: 1.2;
  /*text-transform: uppercase;*/

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  /* width: 30%; */
  height: 40px;
  background-color: #2ebfac;
  border-radius: 25px;

  border: none !important;

  box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -moz-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -webkit-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -o-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -ms-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn .fa-sign-in {
  font-size: 18px;
  padding-left: 2px;
}

.login100-form-btn:hover {
  background-color: #333333;
  box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
}

/*------------------------------------------------------------------
    [ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  /*background-color: #fff;*/
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0px;
  pointer-events: none;

  /*font-family: Poppins-Regular;*/
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  font-size: 16px;
  color: #c80000;

  display: block;
  position: absolute;
  /*background-color: #fff;*/
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

/*//////////////////////////////////////////////////////////////////
    [ Login more ]*/
.login-more li {
  position: relative;
  padding-left: 16px;
}

/*.login-more li::before {
      content: "";
      display: block;
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #cccccc;
      top: 45%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 0;
    }*/

/* util */

.p-t-25 {
  padding-top: 25px;
}

.p-t-85 {
  padding-top: 85px;
}

.p-t-190 {
  padding-top: 190px;
}

.p-b-70 {
  padding-bottom: 70px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-l-15 {
  padding-left: 15px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-35 {
  margin-bottom: 35px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.vdpComponent.vdpWithInput > input {
  border: none;
  background: none;
}

/* File Upload form styles */
.right-section {
  /* background-color: whitesmoke; */
  /* background-image: url("https://www.transparenttextures.com/patterns/lyonnette.png"); */
  border-bottom: 0px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

/* === Wrapper Styles === */
#file-upload {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.right-section fieldset {
  height: 100%;
  width: 100%;
}

.drop-zone-wrapper {
  margin: 30px;
  padding: 10px;
  min-height: 60vh;
  position: relative;
  /* box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); */
  /* border-radius: 10px; */
  /* background-color: white; */
  /* width: 415px; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.max-size-instruction {
  font-size: 16px;
  text-align: center;
  padding: 1%;
}

.max-size-instruction p {
  color: 555555;
}

/* === Drop Files Box === */
.upload {
  margin: auto;
  width: 85%;
  height: 175px;
  border: 8px dashed #7de2d4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.upload label {
  color: dimgray;
  margin: 0;
  font-size: 1.3rem;
  text-align: center;
  margin-left: 2.5%;
  margin-left: 2.5%;
}

@media screen and (min-width:1280px){
  .upload label {
    font-size: 2rem;
  }
}

.upload p {
  margin-top: 12px;
  line-height: 0;
  font-size: 22px;
  color: #0c3214;
  letter-spacing: 1.5px;
}

.browse__button {
  background-color: #7de2d4;
  border-radius: 10px;
  padding: 0px 8px 0px 10px;
  /* width: 100%; */
}

.browse__button:hover {
  cursor: pointer;
  background-color: #2ebfac;
  color: white;
  /* opacity: 0.9; */
}

.container-upload100-form-btn {
  /* position: absolute; */
  bottom: 0;
  margin-top: auto;
  margin-left: auto;
  margin-right: 5%;
  margin-bottom: 15px;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.upload100-form-btn {
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #fff !important;
  line-height: 1.2;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  background-color: #2ebfac;
  border-radius: 25px;

  border: none !important;

  box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -moz-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -webkit-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -o-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -ms-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.upload100-form-btn .fa-search {
  font-size: 18px;
  padding-left: 2px;
}

.upload100-form-btn:hover {
  background-color: #333333;
  box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
}

/* === Selected Files === */
.summary-wrapper {
  min-height: 35vh;
  margin: auto;
  width: 85%;
  padding: 0 5%;
  /* flex-direction: row; */
}

.selected-file-wrapper {
  /* background-color: #caf4ee;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 0;
        margin-right: 0; */

  /* display: inline-block; */
  background-color: #fefefe;
  border-left: 5px solid #2ebfb0;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}

.selected-file-wrapper .invalid-file .file-name-str,
.selected-file-wrapper .invalid-file .file-name-size {
  color: red;
}

.file {
  /* display: inline-block;
        display: flex;
        flex-direction: column;
        align-items: flex-start; */
  padding: 15px 15px 15px 0;
  margin: auto;
  vertical-align: sub;
  justify-content: center;
  width: auto;
}

.selected-file-info {
  /* flex-direction: row; */
  /* align-content: stretch; */
  /* align-items: baseline; */
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  line-height: 0;
  color: #0c3214;
  height: 25px;
  letter-spacing: 1.5px;
  line-height: 1rem;
}

.file-name-str {
  /* height: 20px; */
  /* padding-top: 10px; */
  /* margin-bottom: 1rem; */
  font-size: 18px;
  color: black;
  font-weight: bold;
  margin: 0;
  min-width: 0;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5rem;
}

.file-name-size {
  padding: 0 5px;
  margin: 0 5px;
  min-width: 0;
  width: 100px;
  display: block;
  /* font-style: italic;
        white-space: nowrap;
        white-space: unset;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; */
}

.selected-file-wrapper .fa-times {
  color: black;
  font-size: 18px;
}

.selected-file-wrapper .fa-times:hover {
  cursor: pointer;
  color: red;
}

.selected-file-wrapper .fa-file {
  /* width: 62px; */
  color: black;
  padding: 15px;
  font-size: 32px;
  float: left;
  text-align: center;
  vertical-align: super;
}

.file-upload-progress {
  /* width: 100%; */
}

/* From https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css */

progress {
  vertical-align: baseline;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  /* background-color: #f2fefd; */
  background-color: #f8f8f8;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.bg-success {
  background-color: #2ebfac !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-failure {
  background-color: #e34848 !important;
}

a.bg-failure:focus,
a.bg-failure:hover,
button.bg-failure:focus,
button.bg-failure:hover {
  background-color: #910000 !important;
}

/* customer info styles */
.existing-customer-summary {
  margin: 10px;
  width: 80%;
  margin: auto;
}

.customer-info-title {
  padding: 15px 0 15px 0;
}

.customer-info-container {
  margin-bottom: 35px;
}

.exisiting-files-summary h3 {
  color: black;
  font-size: 24px;
  margin: 15px 0 15px 0;
}

.existing-file-wrapper {
  background-color: #fefefe;
  border-left: 5px solid #2ebfb0;

  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}

.existing-file-wrapper .fa-file {
  color: black;
  font-size: 32px;
  display: inline-block;
  vertical-align: super;
  margin: 10px;
}

.existing-file-wrapper .existing-file {
  display: inline-block;
}

.existing-file-info {
  padding: 10px 10px 10px 0;
}

.existing-file-name {
  color: black;
  font-weight: bold;
}

.existing-file-date {
  /* font-style: italic; */
}

/* .card-title {
      color: black;
      text-transform: capitalize;
      margin: 0;
    }

    .card-summary {
      color: black;
      margin: 0;
    }

    .card-left {
      border-right: dotted 1px #2ebfb0;
    } */

.left-section {
  position: relative;
}

.left-section .moon-loader {
  position: absolute;
}
</style>
