export default {
    methods: {
        // returns unmasked phone number in integer format
        unmaskPhoneNumber: function (maskedPhoneNumber) {
            // return null if input is null
            if (maskedPhoneNumber === null)
                return null

            // 1. remove +1 before phone number
            var unmaskedPhoneNumber = maskedPhoneNumber.substring(2);
            // 2. remove '(', ')', '-' from phone number
            // eslint-disable-next-line
            var regex = /[\(\)-]/gi;
            return Number(unmaskedPhoneNumber.replace(regex, ""));
        },

        // returns integer in currency format
        maskCurrency: function (unmaskedCurrency) {

            // add comma seperators and $ sign
            var maskedCurrency = '$' + Number(unmaskedCurrency).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            // return 
            return maskedCurrency;
        },

        // returns unmasked currency in integer format
        unmaskCurrency: function (maskedCurrency) {
            // return null if input is null
            if (maskedCurrency === null)
                return null;

            // 1. remove '$' before currency
            // 2. remove white spaces
            // 3. remove ',' from currency
            var unmaskedCurrency = maskedCurrency.replace(/[$, ]/g, "");
            return Number(unmaskedCurrency);
        },

        // returns unmasked percentage in integer format
        unmaskPercentage: function (maskedPercentage) {
            // return null if input is null
            if (maskedPercentage === null)
                return null;

            // 1. remove '%' sign
            // 2. remove white spaces
            // 3. remove ',' from currency
            var unmaskedPercentage = maskedPercentage.replace(/[%, ]/g, "");
            return Number(unmaskedPercentage);
        },
    }
}