import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import VueScrollTo from 'vue-scrollto';
import routes from './routes';
import StarRating from 'vue-star-rating';
import Tabs from './components/elements/Tabs.vue';
import Tab from './components/elements/Tab.vue';
import ValidationIcon from './components/elements/ValidationIcon.vue';
import VueTheMask from 'vue-the-mask';
import money from 'v-money';
import { store } from './store/store'
import axios from 'axios';

Vue.config.productionTip = false;
// Vue.config.devtools = false;

Vue.use(VueRouter);
Vue.use(VueScrollTo);
Vue.use(VueTheMask);
Vue.use(money);

Vue.component('star-rating', StarRating);
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);
Vue.component('validation-icon', ValidationIcon);

// event bus for the application
export const bus = new Vue();

//axios for external api calls
export const externalInstance = axios.create();

//adding headers for all axios requests
axios.defaults.headers.common['X-Gateway-Validation-Id'] = process.env.VUE_APP_X_GATEWAY_VALIDATION_ID;
axios.defaults.headers.common['clientApp'] = process.env.VUE_APP_CLIENT_APP;

// Create the router instance and pass the `routes` option
const router = new VueRouter({
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
  routes: routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  // redirect from 'urla' page to 'new-or-continue' if reloading page 
  if (to.name === "urla" && Object.keys(to.params).length === 0)
    return next({ name: 'new-or-continue' });
  else
    return next();
})

new Vue({
  store: store,
  render: h => h(App),
  router: router
}).$mount('#app');