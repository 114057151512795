<template>
    <!-- Wrapper -->
    <div class="wrapper" style="background-color:#f8f8f8; overflow:hidden;">
      <!-- Main -->
        <!-- Blurb -->
      <div id="lice">
          <h2 style="text-align: left;">Licensing And Compliance</h2>
          <div id="content-1">
          <div class="my-col">
          <img class="responsive" src="@/assets/images/usa_map.jpg" nopin="nopin" alt />
          </div>
          </div>
          <section>
    <table style="width:100%">
      <tr>
          <td colspan="3">American Smart Lending Company is a licensed mortgage lender, Nationwide Multistate Licensing System(NMLS) # 1456732</td>
      </tr>
      <tr height="12px">
      </tr>
      <tr>
          <td><strong>State</strong></td>
          <td><strong>License Name</strong></td>
          <td><strong>Status</strong></td>
      </tr>
      <tr>
          <td>Washington</td>
          <td>Washington Consumer Loan Company License</td>
          <td>Approved</td>
      </tr>
      <tr>
          <td>Colorado</td>
          <td>Colorado Mortgage Company Registration</td>
          <td>Approved</td>
      </tr>
      <tr>
          <td>Texas</td>
          <td>Texas - SML Mortgage Company License</td>
          <td>Approved</td>
      </tr>
      <tr>
          <td>Florida</td>
          <td>Florida Mortgage Broker License</td>
          <td>Approved</td>
      </tr>
      <tr>
          <td>California</td>
          <td>California - DFPI Financing Law License</td>
          <td>(Pending)</td>
      </tr>
      <tr>
          <td>Georgia</td>
          <td>Georgia Mortgage Lender License/Registration</td>
          <td>Approved</td>
      </tr>
  </table>
  </section>
        </div>
    </div>
</template>

<script>
export default {
  name: "LicensingInfo",
};
</script>

<style scoped>
table, tr {
  border: 1px solid;
  border-collapse: collapse;
  border-color: #2ebfac;
  padding-top:2px;
}

#lice{
  padding-top: 25px;
}
#content-1 {
height: 400px;
width: auto;
border-radius: 1px;
border-style: solid;
}

.my-col {
float: left;
width: 100%;
}

.responsive {
width: 82%;
height: auto;
display: block;
}
</style>
