import axios from "axios";

export default {
  data() {
    return {
      searchCustomerPath:
        process.env.VUE_APP_ROOT_URL+"/searches/customer",
      config: null,
    };
  },

  methods: {
    searchCustomer: async function(customer) {
      let response = await axios
        .post(this.searchCustomerPath, customer, this.config)
        .catch((err) => {
          return err.response;
        });
      return response;
    },

    getCustomerFiles: async function(url) {
      let response = await axios.get(url, this.config).catch((err) => {
        return err.response;
      });
      return response;
    },

    createFileUploadLink: async function(url, fileInfo) {
      let response = await axios
        .post(url, fileInfo, this.config)
        .catch((err) => {
          return err.response;
        });
      return response;
    },

    uploadFile: async function(url, file) {
      var formData = new FormData();
      formData.append("fileToUpload", file);
      // PUT request with file as body
      let response = await axios
        .put(url, formData, this.uploadFileConfig)
        .catch((err) => {
          // handle failure to contact API
          return err.response;
        });
      return response;
    },
  },

  mounted() {
    // configure axios on mount
    this.config = {
      timeout: 30000,
      // configure headers
      headers: {
        // default content type
        "Content-Type": "application/json",
        // can setup to prevent all caching
        "Cache-Control": "no-cache",
      },
    };

    this.uploadFileConfig = {
      // timeout: 30000,
      headers: {
        // default content type
        "Content-Type": "multipart/form-data",
        // can setup to prevent all caching
        "Cache-Control": "no-cache",
      },
    };
  },
};
