<template>
  <div>
    <div class="wrapper">
      <section class="main">
        <section>
          <h1>Most Recent Customer Reviews</h1>
          <p>KC Kadaru is a licensed mortgage Broker and a licensed real estate broker in the state of texas for many many years. He has helped tens of hundreds of people buy a home, get a loan and achieve their peice of american dream. A few of the testimonials from our customers are listed below and we are in the proces of updating this page with more recent information</p>
          <!--div>
            <a
              v-on:click="$router.push({ name: 'create-review'})"
              class="button primary"
            >Write a Review</a>
          </div-->
          <!--a
            href="https://search.google.com/local/writereview?placeid=ChIJewT9OAydP4YRiPstdSXXCCs"
            target="_blank"
          >Write a Review</a-->
          <br>
          <div
            class="box"
            v-for="review in reviews"
            v-bind:review="review"
            v-bind:key="review.reviewid"
          >
            <h2>{{review.headline}}</h2>
            <div class="row">
              <h4 class="col-3 col-12-medium">Local knowledge:</h4>
              <star-rating
                class="col-3 col-12-medium"
                v-bind:rating="review.knowledge"
                v-bind:show-rating="true"
                v-bind:inline="true"
                v-bind:border-width="2"
                v-bind:rounded-corners="true"
                border-color="#2ebfac"
                v-bind:star-size="20"
                inactive-color="#ffffff"
                active-color="#2ebfac"
                v-bind:read-only="true"
              ></star-rating>
            </div>
            <div class="row">
              <h4 class="col-3 col-12-medium">Process expertise:</h4>
              <star-rating
                class="col-3 col-12-medium"
                v-bind:rating="review.expertise"
                v-bind:show-rating="true"
                v-bind:inline="true"
                v-bind:border-width="2"
                v-bind:rounded-corners="true"
                border-color="#2ebfac"
                v-bind:star-size="20"
                inactive-color="#ffffff"
                active-color="#2ebfac"
                v-bind:read-only="true"
              ></star-rating>
            </div>
            <div class="row">
              <h4 class="col-3 col-12-medium">Responsiveness:</h4>
              <star-rating
                class="col-3 col-12-medium"
                v-bind:rating="review.responsiveness"
                v-bind:show-rating="true"
                v-bind:inline="true"
                v-bind:border-width="2"
                v-bind:rounded-corners="true"
                border-color="#2ebfac"
                v-bind:star-size="20"
                inactive-color="#ffffff"
                active-color="#2ebfac"
                v-bind:read-only="true"
              ></star-rating>
            </div>
            <div class="row">
              <h4 class="col-3 col-12-medium">Negotiation:</h4>
              <star-rating
                class="col-3 col-12-medium"
                v-bind:rating="review.negotiation"
                v-bind:show-rating="true"
                v-bind:inline="true"
                v-bind:border-width="2"
                v-bind:rounded-corners="true"
                border-color="#2ebfac"
                v-bind:star-size="20"
                inactive-color="#ffffff"
                active-color="#2ebfac"
                v-bind:read-only="true"
              ></star-rating>
            </div>
            <div class="note">
              {{review.note}}
              <h4 class="row">
                <span class="col-9 col-12-medium"></span>
                - {{review.userName}}
              </h4>
            </div>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewReviews",
  data() {
    return {
      reviews: [
        {
          userName: "user6288460",
          responsiveness: 5,
          headline: "Highly likely to recommend",
          note:
            "KC gives personal attention to his clients and is very knowledgeable of the home selection process. His local knowledge of the Frisco area helped us tremendously. I would recommend him to another friend or family member.",
          negotiation: 4,
          expertise: 5,
          knowledge: 5
        },
        {
          userName: "zuser20140614213257283",
          responsiveness: 5,
          headline: "Highly likely to recommend",
          note:
            "KC helped us in buying our dream home! He is very professional in his approach, finding the right home according to our specifications. We found him pretty responsive to our emails and concerns. We never felt he was pushy, he helped us in making quick decisions by explaining both pros and cons and letting us choose the direction. We are pretty happy with the end result and fully recommend his services.",
          negotiation: 5,
          expertise: 5,
          knowledge: 5
        },
        {
          userName: "sujata gopu",
          responsiveness: 5,
          headline: "Highly likely to recommend",
          note:
            "KC was my realtor for my first home purchase in Austin. He clearly understood my needs, my budget constraints and got me a very good deal. KC walked me through the entire home buying process which helped me overcome some apprehensions as a first time home buyer. He has great market knowledge. He is easily approachable and responsive. I would definitely recommend KC.",
          negotiation: 5,
          expertise: 5,
          knowledge: 5
        },
        {
          userName: "zuser20140616075307173",
          responsiveness: 5,
          headline: "Highly likely to recommend",
          note:
            "KC helped us find our dream house few months back. We are very impressed by his knowledge of the area. He is a very friendly person whom we were very lucky to find as our guide to find our house. He answers all your questions and concerns. He makes sure he does his research and homework and he makes sure that you have all the information available which would help you take a decision.\n\nMost importantly , he understands your likes and dislikes when you look at a house. He is the best Realtor/Agent you can hope for around the Dallas area. I have recommended him to all my friends and my friends are also very impressed by him. He has helped couple of friends to close the deal as well.\n\nI would highly recommend him !!",
          negotiation: 5,
          expertise: 5,
          knowledge: 5
        },
        {
          userName: "user8914608",
          responsiveness: 5,
          headline: "Highly likely to recommend",
          note:
            "We have very good experience with KC. He explains all possible things about buying a house, as well has helps in understanding of various payment options. Very patient in answering our questions. I would highly recommend him.",
          negotiation: 5,
          expertise: 5,
          knowledge: 5
        },

        {
          userName: "Madhu Guthikonda",
          responsiveness: 5,
          headline: "Highly likely to recommend",
          note:
            "KC helped us buy a new house in Frisco, right from the beginning, KC helped us with market research and his negotiation skills definitely saved us some big dollars with placing an offer. KC is very knowledgable in Home Buying Process and gave us expert opinion till the end. I highly recommend KC in any realty transaction and look forward to engage him in my future transactions.",
          negotiation: 5,
          expertise: 5,
          knowledge: 5
        },
        {
          userName: "paruchuink",
          responsiveness: 5,
          headline: "Highly likely to recommend",
          note:
            "KC is good agent. His responsiveness is very good. To me which is very important when you are going through a stressful process of home hunting. if never pushed me and he was very resourceful while making the decision.",
          negotiation: 5,
          expertise: 5,
          knowledge: 5
        },
        {
          userName: "user765778",
          responsiveness: 5,
          headline: "Highly likely to recommend",
          note:
            "KC was easy to work with. He understood our specific needs, was not pushy, kept to his word and moved very quickly with submitting our offer and closing the contract for us.\n\nHighly recommended!",
          negotiation: 5,
          expertise: 5,
          knowledge: 5
        },
        {
          userName: "Vijayakumar Penumudi",
          responsiveness: 5,
          headline: "Highly likely to recommend",
          note:
            "KC has provided excellent service and was very patient in finding the right property. He provided the answers to my queries and provided sources from where I can get other related services.\n\nI highly recommend",
          negotiation: 5,
          expertise: 5,
          knowledge: 5
        }
      ]
    };
  },
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.note {
  font-style: italic;
  margin-left: 1%;
  white-space: pre-line;
}

.main {
  margin-left: 5%;
  margin-right: 5%;
}
</style>