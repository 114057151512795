import axios from "axios";
/* eslint-disable */
export default {
  data() {
    return {
      // base url for the REST API
      API_BASE_PATH: process.env.VUE_APP_ROOT_URL,
      config: null,
    };
  },
  methods: {
    // POST request to urla API, path '/customers'
    callCustomersAPIAndCreateCustomer: async function(mortgageCustomerInfo) {
      var createCustomerRequest = {};
      createCustomerRequest = mortgageCustomerInfo;
      let response = await axios
        .post(
          this.API_BASE_PATH.concat("/customers"),
          createCustomerRequest,
          this.config
        )
        .catch((err) => {
          return err.response;
        });
      return response;
    },

    callCustomersAPIAndSearchCustomer: async function(searchCustomerRequest) {
      searchCustomerRequest.shouldBeValidated = true;
      let response = await axios
        .post(
          this.API_BASE_PATH.concat("/searches/customer"),
          searchCustomerRequest,
          this.config
        )
        .catch((err) => {
          return err.response;
        });
      return response;
    },

    callUrlasAPIAndGetUrlaById: async function(customerId, loanId) {
      let response = await axios
        .get(
          this.API_BASE_PATH.concat("/customers/")
            .concat(customerId)
            .concat("/urlas/")
            .concat(loanId),
          this.config
        )
        .catch((err) => {
          return err.response;
        });
      return response;
    },

    // put urla to urla API, path '/customers/urlas/{customerId}/{loanId}'
    callUrlasAPIAndUpdateUrla: async function(customerId, loanId, urla) {
      let response = await axios
        .put(
          this.API_BASE_PATH.concat(
            "/customers/"
              .concat(customerId)
              .concat("/urlas/")
              .concat(loanId)
          ),
          urla,
          this.config
        )
        .catch((err) => {
          return err.response;
        });
      return response;
    },

    callUrlasAPIAndGetAllCustomerUrlas:async function(customerId){
      let response = await axios
        .get(
          this.API_BASE_PATH.concat(
            "/customers/"
              .concat(customerId)
              .concat("/urlas")
          ),
          this.config
        )
        .catch((err) => {
          return err.response;
        });
      return response;
    },

    callUrlasAPIAndCreateUrla:async function(customerId){
      let response = await axios
        .post(
          this.API_BASE_PATH.concat("/customers/"+customerId+"/urlas"),
          this.config
        )
        .catch((err) => {
          return err.response;
        });
      return response;
    },

    redirectToUrla(customerId, loanId, sourceUrla) {
      this.$store.commit("setAuthentication", true);
      setTimeout(() => {
        this.$store.commit("setAuthentication", false);
      }, 1800000);
      this.$router.push({
        name: "urla",
        params: {
          customerId: customerId,
          loanId: loanId,
          sourceUrla: sourceUrla,
        },
      });
    },

    deleteUrlaLoanApplication: async function(customerId,loanId){
      let response = await axios
        .delete(
          this.API_BASE_PATH.concat("/customers/"+customerId+"/urlas/"+loanId),
          this.config
        )
        .catch((err) => {
          return err.response;
        });
      return response;
    },

    redirectToErrorPage: function() {
      const ERROR_MESSAGE = "Unexpected Error";
      this.$router.push({
        name: "error",
        params: { message: ERROR_MESSAGE },
      });
    },
  },

  mounted() {
    // configure axios on mount
    this.config = {
      // configure headers
      headers: {
        // default content type
        "Content-Type": "application/json",
        // can setup to prevent all caching
        "Cache-Control": "no-cache",
      },
    };
  },
  
};
