import { externalInstance } from "../main";

export default {
  data() {
    return {
      // base url for the REST API
      // apiBaseUrl: 'https://www.zipcodeapi.com/rest/',
      apiBaseUrl:
        "https://api.zip-codes.com/ZipCodesAPI.svc/1.0/QuickGetZipCodeDetails/",
      // www.zipcodeapi.com api key registered to vamsi@texsmartlending.com
      //apiKey: 'eUvAuACJge2omDbw0f7DfrH1xmuOQGjwz69NsZuvloW8og4slHZKSCdc3ESseBfU',
      // apiKey: 'js-H3Xfj9FTIt8ozx7tksBXVxbXkvZnxVb5AqWiqQQEIQMT3sfuoj71fwFLuFK2IE88',
      apiKey: "AEHC6D0Q50GF8OV1Y0HS",
      config: null,
    };
  },

  methods: {
    // GET request to ZipCode API
    getCityAndState: function(zipcode) {
      // create path for GET method
      // var path = zipcode + '/radians';
      var path = zipcode + "?key=" + this.apiKey;

      return new Promise((resolve, reject) => {
        // POST request with mortgageCustomer as body
        externalInstance
          .get(path, this.config)
          .then((response) => {
            if (response.status === 200) {
              // handle successful API request
              return resolve(response.data);
            } else {
              // handle error returned by API
              return reject("Unable to get City and State!");
            }
          })
          .catch(() => {
            // handle failure to contact API
            return reject("Unable to contact Zip Code API!");
          });
      });
    },
  },

  mounted() {
    // configure axios on mount
    this.config = {
      // configure headers
      headers: {
        // default content type
        "Content-Type": "application/json",
        // can setup to prevent all caching
        // 'Cache-Control': 'no-cache'
      },
      // configure base URL
      //baseURL: this.apiBaseUrl + this.apiKey + '/info.json/'
      baseURL: this.apiBaseUrl,
    };
  },
};
