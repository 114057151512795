<template>
  <transition-group name="fade" tag="div">
    <div
      v-for="number in [currentNumber]"
      :key="number"
      class="slide"
      v-bind:style="{ backgroundImage: 'url(' + currentImage + ')' }"
      v-on:mouseover="stopRotation"
      v-on:mouseout="startRotation"
    ></div>
  </transition-group>
</template>

<script>
export default {
  name: "Slider",
  props: ["images"],
  data() {
    return {
      currentNumber: 0,
      timer: null
    };
  },
  methods: {
    startRotation: function() {
      this.timer = setInterval(this.next, 4500);
    },

    stopRotation: function() {
      clearTimeout(this.timer);
      this.timer = null;
    },

    next: function() {
      this.currentNumber += 1;
    },
    prev: function() {
      this.currentNumber -= 1;
    }
  },
  mounted: function() {
    this.startRotation();
  },
  computed: {
    currentImage: function() {
      return this.images[Math.abs(this.currentNumber) % this.images.length];
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-color: #3c4b51;
  overflow: hidden;
  position: absolute;
  opacity: 0.8;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 1.5s ease;
  overflow: hidden;
  visibility: visible;
  opacity: 0.8;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  visibility: hidden;
}
</style>