var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"main"},[_c('section',{staticClass:"urla-pending-applications"},[(_vm.resultsLoanArray)?_c('h2',[_vm._v(" Hi "+_vm._s(_vm.resultsLoanArray[0].primaryBorrowerBriefInfo.firstName != null ? _vm.resultsLoanArray[0].primaryBorrowerBriefInfo.firstName : null)+", ")]):_vm._e(),_c('p',[_vm._v(" We have found the below pending loan applications: ")]),_c('div',[_c('ul',_vm._l((_vm.resultsLoanArray),function(result){return _c('li',{key:result.loanId},[_c('div',{staticClass:"loan-property"},[_c('p',[_vm._v(" "+_vm._s(result.primaryBorrowerBriefInfo.firstName != null ? result.primaryBorrowerBriefInfo.firstName : "N/A")+" "+_vm._s(result.primaryBorrowerBriefInfo.lastName != null ? result.primaryBorrowerBriefInfo.lastName : "N/A")+" ")]),_c('p',[_vm._v(_vm._s(_vm.isoToLocalDateTime(result.createdDateTime)))])]),_c('div',{staticClass:"loan-property"},[_c('p',[_vm._v(" "+_vm._s(result.loanPurpose != null ? result.loanPurpose : "N/A")+" ")]),_c('p',[_vm._v(" "+_vm._s(result.loanProgram != null ? result.loanProgram : "N/A")+" ")])]),(result.propertyAddress)?_c('div',{staticClass:"loan-property"},[_c('p',[_vm._v(" "+_vm._s(result.propertyAddress.unit != null ? result.propertyAddress.unit : "N/A")+" "+_vm._s(result.propertyAddress.street != null ? result.propertyAddress.street : "N/A")+", ")]),_c('p',[_vm._v(" "+_vm._s(result.propertyAddress.city != null ? result.propertyAddress.city : "N/A")+" "+_vm._s(result.propertyAddress.state != null ? result.propertyAddress.state : "N/A")+" "+_vm._s(result.propertyAddress.zip != null ? result.propertyAddress.zip : "N/A")+" ")])]):_vm._e(),_c('div',{staticClass:"loan-property"},[_c('p',[_vm._v(_vm._s(result.loanAmount != null ? result.loanAmount : "N/A"))]),_c('p',_vm._l((8),function(index){return _c('span',{key:index},[(index != 6)?_c('span',{staticClass:"progress-bar",class:{
                    progressActive:
                      index <= result.loanApplicationProgress / 100,
                  }}):_vm._e()])}),0)]),_c('div',{staticClass:"loan-property"},[_c('div',{class:result.loanStatus == 'PARTIAL' ||
                result.loanStatus == 'STARTED'
                  ? null
                  : 'notActive'},[_c('p',{staticClass:"edit-application edit-hover",on:{"click":function($event){return _vm.getCompleteUrlaAndRedirectToUrlaPage(
                    result.customerId,
                    result.loanId
                  )}}},[_c('i',{staticClass:"fa fa-edit"}),_c('span',{staticClass:"edit-hover-text"},[_vm._v(" Edit")])])]),_c('div',{class:result.loanStatus == 'PARTIAL' ||
                result.loanStatus == 'STARTED'
                  ? null
                  : 'notActive'},[_c('p',{staticClass:"delete-application edit-hover",on:{"click":function($event){return _vm.callDeleteMethodFromUrlaMixin(
                    result.customerId,
                    result.loanId
                  )}}},[_vm._v(" ×"),_c('span',{staticClass:"edit-hover-text"},[_vm._v(" Delete")])])])])])}),0),_c('div',{staticClass:"button-container"},[_c('button',{staticClass:"primary",on:{"click":_vm.createNewUrlaAndRedirectToUrlaPage}},[_vm._v(" Create New ")])])])]),_vm._m(0),_c('pop-up',{attrs:{"status":_vm.statusResponse,"statusMessage":_vm.statusMessage}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"contact-info"},[_vm._v(" Please do not hesitate to reach us at "),_c('a',{attrs:{"href":"tel:469-525-6725"}},[_vm._v("+1(469)525-6725")]),_vm._v(" or "),_c('a',{attrs:{"href":"mailto:kc@texsmartlending.com"}},[_vm._v("kc@texsmartlending.com")]),_vm._v(" in case you need to reach us before the end of the day. ")])
}]

export { render, staticRenderFns }